import React, { useContext } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { HubContext } from '../context';
import HubLoader from '../hub-loader';
import ChannelItem from './channel';
import ChannelHeader from './channel-header';

const ChannelList = () => {
  const {
    channels, loadingChannels, setFilterChannels, filterChannels,
  } = useContext( HubContext );

  return (
    <div className="hub-channel-list">
      <ChannelHeader />
      <div className="hub-channel-list-body">
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          loadMore={( page ) => {
            setFilterChannels( { page } );
          }}
          hasMore={channels.totalPages
            > filterChannels.page}
          loader={<HubLoader loading={loadingChannels} key="loading" />}
          useWindow={false}
        >
          {channels.data.map( ( channelData ) => (
            <ChannelItem
              key={channelData?.id}
              channelData={channelData}
            />
          ) )}
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ChannelList;
