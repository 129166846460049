export default [
  {
    name: 'pets',
    ruId: '29',
  },
  {
    name: 'parking',
    ruId: '31',
  },
  {
    name: 'wifi',
    ruId: '30',
  },
  {
    name: 'toiletries',
    ruId: '6',
  },
  {
    name: 'pool',
    ruId: '34',
  },
  {
    name: 'gym',
    ruId: '0',
  },
  {
    name: 'spa',
    ruId: '34',
  },
  {
    name: 'breakfast',
    ruId: 'o',
  },
  {
    name: 'cleaning',
    ruId: '41',
  },
  {
    name: 'safe',
    ruId: '0',
  },
  {
    name: 'others',
    ruId: '0',
  },
];
