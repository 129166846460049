import api from 'config/Api/Api';

const baseEndpoint = ( propertyId, roomTypeId ) => `/properties/${propertyId}/room-types/${roomTypeId}/accommodations`;

export default {

  saveRUFees( propertyId, roomTypeId, id, data ) {
    return api.post( `${baseEndpoint( propertyId, roomTypeId )}/${id}/fees`, data );
  },
  updateRUFees( propertyId, roomTypeId, id, data ) {
    return api.put( `${baseEndpoint( propertyId, roomTypeId )}/${id}/fees`, data );
  },

  deleteRUFees( propertyid, roomTypeId, id ) {
    return api.delete( `${baseEndpoint( propertyid, roomTypeId )}/${id}/fees` );
  },

};
