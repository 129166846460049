import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { connect } from 'react-redux';
// import async from 'async';
// import { each } from 'async';
import _ from 'lodash';

import TaskEditView from 'views/Task/EditDrawer';
import TaskNewView from 'views/Task/New';
import SettingsActions from 'store/reducers/Settings';
import TaskActions from 'store/reducers/Task';
import AccommodationService from 'api/Accommodation';
import PlacesService from 'api/Places';
import CheckListService from 'api/Checklist';
import TaskTypeService from 'api/TaskType';
import TaskService from 'api/Task';
import TaskImageService from 'api/TaskImage';
import { each } from 'utils/async';
// import CustomFieldService from 'api/Settings/CustomField';
import {
  // formatCustomValues,
  // formatCustomValuesToSend,
  formatDataToSend,
  getResourceName,
  handlePromise,
} from 'utils';
import useFetch from 'hooks/useFetch';

const TaskEdit = ( {
  taskId,
  taskContact,
  preloadedData = {},
  // propertyManager,
  modalOpened,
  toggleTaskEditModal,
  taskReloadedAt,
  taskReloadedId,
  onReload,
  onCloseModal,
  toggleLoading,
  toggleInfoAlert,
  toggleErrorAlert,
  zIndex,
  onUpdateFieldTask,
  inEditor,
} ) => {
  const [data, setData] = useState( {} );
  // const [customFields, setCustomFields] = useState( { entity: {} } );
  const [dataLoading, setDataLoading] = useState( false );
  const isInitialMount = useRef( true );
  const [backgroundReloaded, setBackgroundReloaded] = useState( false );
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );

  const openEditModal = useCallback(
    ( { id } = {} ) => {
      setTimeout( () => {
        toggleTaskEditModal( id );
      }, 500 );
    },
    [toggleTaskEditModal],
  );

  useEffect( () => {
    if ( isInitialMount.current ) {
      isInitialMount.current = false;
    } else if (
      modalOpened
      && taskId
      && taskReloadedId
      && taskReloadedAt
      && taskReloadedId === taskId
    ) {
      setBackgroundReloaded( true );
    }
  }, [taskReloadedAt, taskReloadedId, taskId, modalOpened] );

  const formatData = useCallback( ( dataToFormat ) => {
    const formattedData = { ...dataToFormat };

    if ( dataToFormat.taskType ) {
      formattedData.taskType = {
        value: dataToFormat.taskType.id,
        label: dataToFormat.taskType.name,
        estimatedTime: dataToFormat.taskType.estimatedTime,
      };
    }
    if ( dataToFormat.assigned ) {
      formattedData.assignedTo = {
        value:
          dataToFormat.assigned[dataToFormat.assigned.length - 1].employee.id,
        label: getResourceName(
          dataToFormat.assigned[dataToFormat.assigned.length - 1].employee,
        ),
      };
    }
    return formattedData;
  }, [] );

  const getEntityData = useCallback(
    async ( cb ) => {
      if ( !taskId ) return cb( null, {} );
      const [errors, response, responseData] = await handlePromise(
        TaskService.getTask( taskId ),
      );

      if ( !response.ok ) return cb( errors );
      return cb( null, { entity: responseData } );
    },
    [taskId],
  );
  // const getCustomFields = useCallback(
  //   async ( entityData, cb ) => {
  //     if ( !propertyManager || !propertyManager.id ) return cb( 'apiError' );

  //     const [errors, response, responseData] = await handlePromise(
  //       CustomFieldService.getCustomFields( {
  //         type: 'task',
  //         propertyManager: propertyManager.id,
  //         elementsPerPage: 1000,
  //       } ),
  //     );
  //     if ( !response.ok ) return cb( errors );

  //     return cb( null, {
  //       entity: formatCustomValues( entityData, responseData.data ),
  //       customFields: responseData.data,
  //     } );
  //   },
  //   [propertyManager],
  // );

  const getAccommodation = useCallback( async ( accommodationId, cb ) => {
    if ( !accommodationId ) {
      return cb( null, null );
    }
    const [errors, response, responseData] = await handlePromise(
      AccommodationService.getGenericAccommodation( accommodationId ),
    );
    if ( !response.ok ) return cb( errors );
    return cb( null, responseData );
  }, [] );

  const getCheckListTask = useCallback( async ( task, cb ) => {
    const [errors, response, responseData] = await handlePromise(
      CheckListService.getChecklistsTaks( { task, page: 1, elementsPerPage: 20 } ),
    );
    if ( !response.ok ) return cb( errors );
    return cb( null, responseData );
  }, [] );

  useEffect( () => {
    const getTaskData = async () => {
      if ( !modalOpened ) return;
      setDataLoading( true );

      if ( !taskId ) return null;
      let results = {};
      const [errors, response, entityData] = await handlePromise(
        TaskService.getTask( taskId ),
      );

      if ( !response.ok ) return toggleErrorAlert( errors );

      if ( !_.get( entityData, 'entity.accommodation' ) ) {
        results.entity = { ...entityData };
      }
      await getAccommodation(
        entityData.accommodation,
        ( error, accommodationResponse ) => {
          if ( error ) return toggleErrorAlert( error );
          results = {
            entity: { ...entityData },
            accommodation: accommodationResponse,
          };
        },
      );
      await getCheckListTask(
        taskId,
        ( error, checkListResponse ) => {
          if ( error ) return toggleErrorAlert( error );
          results = {
            ...results,
            checkListsToTask: checkListResponse,
          };
        },
      );

      const formattedData = { ...results.entity };
      if ( results.entity && results.entity.id ) {
        if ( results.entity.taskType ) {
          formattedData.taskType = {
            value: results.entity.taskType.id,
            label: results.entity.taskType.name,
            estimatedTime: results.entity.taskType.estimatedTime,
          };
        }
        if ( results.entity.assigned && results.entity.assigned.length ) {
          const { assigned } = results.entity;
          formattedData.assignedTo = {
            value: assigned[assigned.length - 1].employee.id,
            label: getResourceName( assigned[assigned.length - 1].employee ),
          };
        }
        if ( results.accommodation ) {
          formattedData.accommodation = {
            value: results.accommodation.id,
            label: `${results.accommodation.name} (${results.accommodation.roomType.property.propertyManagerCityZone.name} - ${results.accommodation.roomType.property.propertyManagerCityZone.propertyManagerCity.city.name})`,
            zone: results.accommodation.roomType.property
              .propertyManagerCityZone.id,
          };
        }
        if ( results.entity.extraCost ) {
          formattedData.extraExpenses = _.map(
            _.filter(
              results.entity.extraCost,
              ( item ) => item.isExpense === true,
            ), ( item ) => ( {
              value: item.id,
              label: item.name,
              original: item,
            } ),
          );
          formattedData.extraCost = _.filter(
            results.entity.extraCost,
            ( item ) => !item.isExpense,
          );
        }
        if ( results.checkListsToTask ) {
          formattedData.checkListsToTask = results.checkListsToTask.data;
        }
      }
      setData( formattedData );
      // setCustomFields( results.customFields );
      setDataLoading( false );
      setBackgroundReloaded( false );

      // waterfall(
      //   [
      //     getEntityData,
      //     // getCustomFields,
      //     ( entityData, cb ) => {
      //       if ( !_.get( entityData, 'entity.accommodation' ) ) {
      //  return cb( null, entityData ); }
      //       getAccommodation(
      //         entityData.entity.accommodation,
      //         ( error, accommodationResponse ) => {
      //           if ( error ) return cb( error );
      //           return cb( null, {
      //             ...entityData,
      //             accommodation: accommodationResponse,
      //           } );
      //         },
      //       );
      //     },
      //   ],
      //   ( errors, results ) => {
      //     setDataLoading( false );
      //     if ( errors ) {
      //       return toggleErrorAlert( errors );
      //     }
      //     const formattedData = { ...results.entity };
      //     if ( results.entity && results.entity.id ) {
      //       if ( results.entity.taskType ) {
      //         formattedData.taskType = {
      //           value: results.entity.taskType.id,
      //           label: results.entity.taskType.name,
      //           estimatedTime: results.entity.taskType.estimatedTime,
      //         };
      //       }
      //       if ( results.entity.assigned && results.entity.assigned.length ) {
      //         const { assigned } = results.entity;
      //         formattedData.assignedTo = {
      //           value: assigned[assigned.length - 1].employee.id,
      //           label: getResourceName( assigned[assigned.length - 1].employee ),
      //         };
      //       }
      //       if ( results.accommodation ) {
      //         formattedData.accommodation = {
      //           value: results.accommodation.id,
      //           label: `${
      //           zone: results.accommodation.roomType.property
      //             .propertyManagerCityZone.id,
      //         };
      //       }
      //       if ( results.entity.extraCost ) {
      //         formattedData.extraExpenses = _.map(
      //           _.filter(
      //             results.entity.extraCost,
      //             ( item ) => item.isExpense,
      //           ), ( item ) => ( {
      //             value: item.id,
      //             label: item.name,
      //           } ),
      //         );
      //       }
      //     }
      //     setData( formattedData );
      //     // setCustomFields( results.customFields );
      //     setBackgroundReloaded( false );
      //   },
      // );
    };
    getTaskData();
  }, [
    taskId,
    modalOpened,
    dataSubmitted,
    getEntityData,
    // getCustomFields,
    getAccommodation,
    toggleErrorAlert,
    getCheckListTask,
  ] );

  // Accommodation Or Place *************************

  const [
    accommodationSelected,
    setAccommodationSelected,
  ] = useState( preloadedData.accommodation || null );
  const [
    placeSelected,
    setPlaceSelected,
  ] = useState( preloadedData.place || null );

  const fetchAccommodations = useCallback(
    ( query ) => AccommodationService.getAllAccommodations( {
      elementsPerPage: 99,
      keyword: query,
    } ),
    [],
  );
  const fetchPlaces = useCallback(
    ( query ) => PlacesService.getList( {
      elementsPerPage: 99,
      keyword: query,
    } ),
    [],
  );

  const isDisabledAccommodation = useMemo( () => {
    if ( preloadedData.accommodation || preloadedData.place || placeSelected ) {
      return true;
    }
    return false;
  }, [preloadedData, placeSelected] );

  const isDisabledPlace = useMemo( () => {
    if ( preloadedData.accommodation || preloadedData.place || accommodationSelected ) {
      return true;
    }
    return false;
  }, [preloadedData, accommodationSelected] );

  // END Accommodation Or Place *************************

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form );
      let formattedData = {};

      if ( taskContact ) {
        formattedData.contact = taskContact;
      }

      if ( formData.bookedAccommodation ) {
        formattedData.bookedAccommodation = formData.bookedAccommodation;
      }
      // if ( customFields && customFields.length ) {
      //   formattedData = formatCustomValuesToSend( formData, customFields );
      // }
      formattedData = {
        ...formattedData,
        accommodation: dataToSend.accommodation,
        place: dataToSend.place,
        name: dataToSend.description,
        description: dataToSend.description,
        priority: dataToSend.priority,
        taskType: dataToSend.taskType,
        type: dataToSend.type,
        date: dataToSend.date
          ? dataToSend.date.format( 'YYYY-MM-DD HH:mm:ss' )
          : dataToSend.date,
      };

      if ( !formattedData.place ) {
        delete formattedData.place;
      }
      if ( !formattedData.accommodation ) {
        delete formattedData.accommodation;
      }

      const [errors, response, taskData] = await handlePromise(
        TaskService.saveTask( formattedData ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      // if ( dataToSend.assignedTo ) {
      //   const [errorAssing, responseAssing] = await handlePromise(
      //     TaskService.assignTask( taskData.id, _.get( formData, 'assignedTo' ) ),
      //   );

      //   if ( !responseAssing.ok ) {
      //     toggleLoading( false );
      //     return errorAssing;
      //   }
      // }

      if ( !dataToSend.images ) {
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        openEditModal( { id: taskData.id } );
        return onReload();
      }

      return new Promise( ( resolve, reject ) => {
        each(
          dataToSend.images,
          async ( image, eCb ) => {
            const [imageErrors, imageResponse] = await handlePromise(
              TaskImageService.saveImage( taskData.id, { image: image.file } ),
            );
            if ( !imageResponse.ok ) return eCb( imageErrors );
            eCb();
          },
          ( error ) => {
            if ( error ) {
              toggleLoading( false );
              return reject( error );
            }
            toggleLoading( false );
            toggleInfoAlert( 'dataSaved' );
            openEditModal( { id: taskData.id } );
            onReload();
            resolve();
          },
        );
      } ).catch( ( error ) => Promise.resolve( error ) );
      // return new Promise( ( resolve, reject ) => {
      //   const imageTasks = dataToSend.images.map( ( image ) => async () => {
      //     try {
      //       const [imageErrors, imageResponse] = await handlePromise(
      //         TaskImageService.saveImage( taskData.id, { image: image.file } ),
      //       );
      //       if ( !imageResponse.ok ) {
      //         throw new Error( imageErrors );
      //       }
      //     } catch ( error ) {
      //       reject( error );
      //     }
      //   } );

      //   // eslint-disable-next-line no-unused-vars
      //   parallel( imageTasks, ( err, results ) => {
      //     if ( err ) {
      //       toggleLoading( false );
      //       reject( err ); // Propaga el error al exterior de la promesa
      //     } else {
      //       toggleLoading( false );
      //       toggleInfoAlert( 'dataSaved' );
      //       openEditModal( { id: taskData.id } );
      //       onReload();
      //       resolve();
      //     }
      //   } );
      // } );
    },
    [openEditModal, onReload, toggleInfoAlert, toggleLoading, taskContact],
  );

  const reloadData = useCallback( () => {
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  return !taskId ? (
    <TaskNewView
      open={modalOpened}
      // customFields={customFields}
      onSubmit={submitForm}
      onClose={onCloseModal}
      onFetchAccommodations={fetchAccommodations}
      onFetchPlaces={fetchPlaces}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      preloadedData={preloadedData}
      zIndex={zIndex}
      accommodationSelected={accommodationSelected}
      setAccommodationSelected={setAccommodationSelected}
      placeSelected={placeSelected}
      setPlaceSelected={setPlaceSelected}
      isDisabledAccommodation={isDisabledAccommodation}
      isDisabledPlace={isDisabledPlace}
    />
  ) : (
    <TaskEditView
      inEditor={inEditor}
      taskId={taskId}
      data={data}
      // customFields={customFields}
      isLoading={dataLoading}
      open={modalOpened}
      backgroundReloaded={backgroundReloaded}
      onClose={onCloseModal}
      setData={setData}
      onFormatData={formatData}
      onReload={reloadData}
      onUpdateFieldTask={onUpdateFieldTask}
    />
  );
};

const mapStateToProps = ( { user, task } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
  taskReloadedAt: task.reloadedAt,
  taskReloadedId: task.taskId,
  taskContact: task.taskToEditContact,
} );

const mapDispatchToProps = {
  toggleTaskEditModal: TaskActions.toggleEditModal,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( TaskEdit );
