import ChangePassword from 'containers/ChangePassword';
import UserProfile from 'containers/UserProfile';
import Chat from 'containers/SupportOld/Chat';

export default {
  headerLeft: [],
  headerRight: [
    {
      path: '',
      layout: '/support',
      exact: true,
      icon: 'app-icon-support',
      component: Chat,
    },
  ],
  userMenu: [
    {
      name: 'myProfile',
      path: 'profile',
      icon: 'ni ni-single-02',
      component: UserProfile,
      layout: '/',
    },
    {
      name: 'changePassword',
      path: 'change-password',
      icon: 'fa fa-key',
      component: ChangePassword,
      layout: '/',
    },
  ],
};
