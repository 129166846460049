// import Customer from 'containers/Customer';
import Resource from 'containers/Resource';
import ChangePassword from 'containers/ChangePassword';
import UserProfile from 'containers/UserProfile';
// import Chat from 'containers/SupportOld/Chat';
import Hub from 'containers/Support';
import Property from 'containers/Property';
import AccomodationOwnerUser from 'containers/AccomodationOwnerUser';
import AccomodationOwner from 'containers/AccomodationOwner';
import Accommodation from 'containers/Accommodation';
import Places from 'containers/Places';
import Booking from 'containers/Booking';
import BookedService from 'containers/BookedService';
import Team from 'containers/Team';
import Task from 'containers/Task';
import TaskPeriodic from 'containers/TaskPeriodic';
import Shift from 'containers/Shift';
import TaskType from 'containers/TaskType';
// import Role from 'containers/Role';
import Amenity from 'containers/Amenity';
import Post from 'containers/Post';
import Event from 'containers/Event';
import Map from 'containers/Map';
import Category from 'containers/Category';
import CustomerApp from 'containers/CustomerApp';
import OwnerApp from 'containers/OwnerApp';
import ComingSoon from 'containers/ComingSoon';
import AutogenerateTask from 'containers/AutogenerateTask';
import AutoassignTask from 'containers/AutoassignTask';
import MasterKeys from 'containers/MasterKeys';
import Account from 'containers/PropertyManager/Edit/General';
import Finance from 'containers/PropertyManager/Edit/Finance';
import City from 'containers/PropertyManager/Edit/City/Dashboard';
import SmartLockers from 'containers/PropertyManager/Edit/SmartLockers/Dashboard';
import Deposits from 'containers/PropertyManager/Edit/Deposits/Dashboard';
import Rates from 'containers/PropertyManager/Edit/Rates/Dashboard';
import Discounts from 'containers/PropertyManager/Edit/Discounts/Dashboard';
import Occupancies from 'containers/PropertyManager/Edit/Occupancies/Dashboard';
import Contracts from 'containers/PropertyManager/Edit/Contract';
import Settings from 'containers/PropertyManager/Edit/Settings';
import Checklist from 'containers/Checklist';
import CommunityRule from 'containers/CommunityRule';
import Service from 'containers/Service';
import AccessControl from 'containers/AccessControl';
import TravelReport from 'containers/TravelReport';
import ChatSettings from 'containers/Communications/ChatSettings';
import TemplateSettings from 'containers/Communications/TemplateSettings';
import ClientCommunications from 'containers/Communications/Client';
import OperativeCommunications from 'containers/Communications/Operative';
import OwnerCommunications from 'containers/Communications/Owner';
import DashboardCalendar from 'containers/DashboardCalendar';
import AccessRequest from 'containers/AccessRequest';
import DashboardPMS from 'containers/DashboardPMS';
import DashboardShift from 'containers/DashboardShift';
import RegisterOfAuthorities from 'containers/RegisterOfAuthorities/Dashboard';
import RoomExpenses from 'containers/RoomExpenses';
import NotificationsList from 'containers/Notifications';
import ExtraExpense from 'containers/ExtraExpenses';
import EmployeerPermits from 'containers/EmployeePermissions';
import NotificationOwners from 'containers/NotificationOwners';
// import CustomFieldsRoutes from './sections/customFields';
import TagsPredefined from 'containers/TagsPredefined';
// CRM
import Contact from 'containers/CRM/Contact';
import Business from 'containers/CRM/Business';
import Pipelines from 'containers/CRM/Pipelines';
import Communications from 'containers/CRM/Communications';
import ChannelManager from 'containers/ChannelManager';

export default {
  headerLeft: [
    {
      name: 'operations',
      path: 'tasks',
      layout: '/operations/',
      icon: 'th-icon-triangle',
      state: 'operations',
      ACLs: [
        'task:manage',
        'task:standard',
        'task:read_only_acl',
        'access_control:standard',
        'geolocation:standard',
      ],
      views: [
        {
          name: 'tasks',
          state: 'operationsTasks',
          ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
          views: [
            {
              name: 'dashboardCalendar',
              path: 'dashboard',
              layout: '/operations/',
              component: DashboardCalendar,
              ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
            },
            {
              name: 'taskList',
              path: 'tasks',
              layout: '/operations/',
              component: Task,
              ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
            },
            {
              name: 'periodicTasks',
              path: 'periodic-tasks',
              layout: '/operations/',
              component: TaskPeriodic,
              ACLs: ['task:manage'],
            },
          ],
        },
        {
          name: 'accessControl',
          path: 'access-control',
          layout: '/operations/',
          component: AccessControl,
          ACLs: ['access_control:standard'],
        },
        {
          name: 'accessRequest',
          path: 'access-request',
          layout: '/operations/',
          component: AccessRequest,
          ACLs: ['task:standard', 'task:read_only_acl'],
        },
        {
          name: 'geolocation',
          path: 'geolocation',
          component: Map,
          layout: '/operations/',
          ACLs: ['geolocation:standard'],
        },
        {
          name: 'masterKeys',
          path: 'master-keys',
          component: MasterKeys,
          layout: '/operations/',
          ACLs: ['task:manage'],
        },
      ],
    },
    {
      name: 'bookings',
      path: 'list',
      layout: '/bookings/',
      icon: 'th-icon-rectangle',
      state: 'bookings',
      ACLs: ['booking:manage', 'booking:standard', 'booking:read_only_acl'],
      views: [
        {
          name: 'bookings',
          path: 'list',
          layout: '/bookings/',
          component: Booking,
          ACLs: ['booking:manage', 'booking:standard', 'booking:read_only_acl'],
        },
        {
          name: 'services',
          path: 'services',
          layout: '/bookings/',
          component: BookedService,
          ACLs: ['booking:standard', 'booking:read_only_acl'],
        },
        {
          name: 'registerOfAuthorities',
          path: 'register-of-authorities',
          layout: '/bookings/',
          component: RegisterOfAuthorities,
          ACLs: ['booking:standard', 'booking:read_only_acl'],
        },
        {
          name: 'reports',
          state: 'bookingsReports',
          views: [
            {
              name: 'occupancy',
              path: 'occupancy',
              layout: '/bookings/',
              component: ComingSoon,
            },
            {
              name: 'pending',
              path: 'pending',
              layout: '/bookings/',
              component: ComingSoon,
            },
          ],
        },
        // {
        //   name: 'customers',
        //   path: 'customers',
        //   layout: '/bookings/',
        //   icon: 'app-icon-resources',
        //   component: Customer,
        //   ACLs: ['booking:manage', 'booking:lists'],
        // },
      ],
    },
    {
      name: 'pms',
      path: 'properties',
      layout: '/pms/',
      icon: 'th-icon-rectangle',
      state: 'pms',
      ACLs: [
        'property:standard',
        'property:read_only_acl',
        'accommodation:standard',
        'accommodation:read_only_acl',
      ],
      views: [
        {
          name: 'propertyOwners',

          views: [
            {
              name: 'list',
              path: 'property-owners/list',
              layout: '/pms/',
              component: AccomodationOwner,
              ACLs: ['property_owner:standard', 'property_owner:read_only_acl'],
            },
            {
              name: 'users',
              path: 'property-owners/users',
              layout: '/pms/',
              component: AccomodationOwnerUser,
              ACLs: ['property_owner:standard', 'property_owner:read_only_acl'],
            },
            {
              name: 'notifications',
              path: 'property-owners/notifications',
              layout: '/pms/',
              component: NotificationOwners,
              ACLs: ['accommodation:standard'],
            },
          ],
        },
        {
          name: 'properties',
          path: 'properties',
          layout: '/pms/',
          component: Property,
          ACLs: ['property:standard', 'property:read_only_acl'],
        },
        {
          name: 'accommodations',
          path: 'accommodations',
          layout: '/pms/',
          component: Accommodation,
          ACLs: ['accommodation:standard', 'accommodation:read_only_acl'],
        },
        {
          name: 'places',
          path: 'places',
          layout: '/pms/',
          component: Places,
          ACLs: ['accommodation:standard', 'accommodation:read_only_acl'],
        },
        {
          name: 'roomExpenses',
          path: 'room-expenses',
          layout: '/pms/',
          component: RoomExpenses,
          ACLs: ['accommodation:standard', 'accommodation:read_only_acl'],
        },
        {
          name: 'dashboard',
          path: 'dashboard',
          layout: '/pms/',
          component: DashboardPMS,
          ACLs: ['accommodation:standard', 'accommodation:read_only_acl'],
        },
      ],
    },
    {
      name: 'content',
      path: 'services',
      layout: '/content/',
      icon: 'th-icon-ellipse',
      state: 'content',
      ACLs: [
        'services:manage',
        'services:read_only_acl',
        'cms:standard',
        'cms:read_only_acl',
        'event:standard',
        'event:read_only_acl',
      ],
      views: [
        {
          name: 'services',
          path: 'services',
          layout: '/content/',
          component: Service,
          ACLs: ['services:manage', 'services:read_only_acl'],
        },
        {
          name: 'posts',
          path: 'posts',
          layout: '/content/',
          component: Post,
          ACLs: ['cms:standard', 'cms:read_only_acl'],
        },
        {
          name: 'categories',
          path: 'categories',
          layout: '/content/',
          component: Category,
          ACLs: ['cms:standard', 'cms:read_only_acl'],
        },
        {
          name: 'community',
          path: 'community',
          layout: '/content/',
          component: Event,
          ACLs: ['event:standard', 'event:read_only_acl'],
        },
      ],
    },
    {
      name: 'resources',
      layout: '/resources/',
      path: 'resources',
      icon: 'th-icon-triangle',
      state: 'resources',
      ACLs: [
        'resources:standard',
        'resources:read_only_acl',
        'team:manage',
        'team:standard',
        'team:read_only_acl',
        'shift:manage',
        'shift:standard',
        'shift:read_only_acl',
      ],
      views: [
        {
          name: 'resources',
          path: 'resources',
          layout: '/resources/',
          component: Resource,
          ACLs: ['resources:standard', 'resources:read_only_acl'],
        },
        {
          name: 'teams',
          path: 'teams',
          layout: '/resources/',
          component: Team,
          ACLs: ['team:manage', 'team:standard', 'team:read_only_acl'],
        },
        {
          name: 'shifts',
          path: 'shifts',
          layout: '/resources/',
          component: Shift,
          ACLs: ['shift:manage', 'shift:standard', 'shift:read_only_acl'],
        },
        {
          name: 'dashboard',
          path: 'dashboard-shifts',
          layout: '/resources/',
          component: DashboardShift,
          ACLs: ['shift:manage', 'shift:lists'],
        },
      ],
    },
    {
      name: 'communications',
      path: 'chat-settings',
      layout: '/communications/',
      icon: 'th-icon-cross',
      state: 'communications',
      ACLs: ['communications:standard', 'communications:read_only_acl'],
      views: [
        {
          name: 'chatSettings',
          path: 'chat-settings',
          component: ChatSettings,
          layout: '/communications/',
          ACLs: ['communications:standard'],
        },
        {
          name: 'emailSettings',
          path: 'template-settings',
          layout: '/communications/',
          component: TemplateSettings,
          ACLs: ['communications:standard', 'communications:read_only_acl'],
        },
        {
          name: 'clients',
          path: 'clients',
          component: ClientCommunications,
          layout: '/communications/',
          ACLs: ['communications:standard', 'communications:read_only_acl'],
        },
        {
          name: 'operative',
          path: 'operative',
          component: OperativeCommunications,
          layout: '/communications/',
          ACLs: ['communications:standard', 'communications:read_only_acl'],
        },
        {
          name: 'owner',
          path: 'owner',
          component: OwnerCommunications,
          layout: '/communications/',
          ACLs: ['communications:standard', 'communications:read_only_acl'],
        },
      ],
    },
    {
      flag: 'crm',
      name: 'crm',
      path: 'contacts',
      layout: '/crm/',
      icon: 'th-icon-rectangle',
      state: 'crm',
      /*  ACLs: [
        'task:manage',
        'task:standard',
        'task:read_only_acl',
        'access_control:standard',
        'geolocation:standard',
      ], */
      views: [
        {
          name: 'crm.contacts',
          path: 'contacts',
          layout: '/crm/',
          component: Contact,
          // ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
        },
        {
          name: 'crm.pipelines',
          path: 'pipelines',
          layout: '/crm/',
          component: Pipelines,
          // ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
        },
        {
          name: 'crm.business',
          path: 'business',
          layout: '/crm/',
          component: Business,
          // ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
        },
        {
          name: 'crm.communications',
          path: 'communications',
          layout: '/crm/',
          component: Communications,
          // ACLs: ['task:manage', 'task:standard', 'task:read_only_acl'],
        },
      ],
    },

    /* {
      name: 'reports',
      path: 'expenses-internal',
      layout: '/reports/',
      icon: 'app-icon-analysis',
      state: 'reports',
      views: [
        {
          name: 'expenses',
          icon: 'app-icon-expenses',
          state: 'reportsExpenses',
          views: [
            {
              name: 'internal',
              path: 'expenses-internal',
              layout: '/reports/',
              component: Dashboard,
            },
            {
              name: 'forOwners',
              path: 'expenses-owners',
              layout: '/reports/',
              component: Dashboard,
            },
          ],
        },
        {
          name: 'profitsBreakdown',
          icon: 'app-icon-profit-breakdown',
          state: 'reportsProfits',
          views: [
            {
              name: 'internal',
              path: 'profits-internal',
              layout: '/reports/',
              component: Dashboard,
            },
            {
              name: 'forOwners',
              path: 'profits-owners',
              layout: '/reports/',
              component: Dashboard,
            },
          ],
        },
        {
          name: 'appStatistics',
          path: 'app-statistics',
          layout: '/reports/',
          icon: 'app-icon-phone-app',
          component: Dashboard,
        },
      ],
    }, */
  ],
  headerRight: [
    {
      path: 'notifications',
      layout: '/',
      component: NotificationsList,
      // icon: 'th-icon-comment',
      forNotifications: true,
      exact: true,
    },
    // {
    //   path: 'support-old',
    //   name: 'support-old',
    //   layout: '/',
    //   exact: true,
    //   icon: 'th-icon-comment',
    //   component: Chat,
    //   ACLs: ['chat:manage', 'chat:standard'],
    // },
    {
      path: 'support',
      name: 'support',
      layout: '/',
      exact: true,
      icon: 'th-icon-comment',
      component: Hub,
      ACLs: ['chat:manage', 'chat:standard'],
    },
    {
      path: 'cities',
      name: 'settings',
      layout: '/settings/',
      icon: 'th-icon-settings',
      state: 'settings',
      ACLs: [
        'settings:standard',
        // 'settings_autogenerated_task:standard',
        // 'settings_task_auto_assignation:standard',
        // 'settings_custom_fields:standard',
        // 'settings_plugins:standard',
        // 'settings_city:standard',
        // 'task_type:manage',
        // 'amenity:standard',
        // 'amenity:read_only',
      ],
      views: [
        // {
        //   name: 'roles',
        //   path: 'roles',
        //   layout: '/settings/',
        //   component: Role,
        //   ACLs: ['settings:standard'],
        // },
        {
          name: 'ChannelManager',
          path: 'channel-manager',
          layout: '/pms/',
          component: ChannelManager,
          // ACLs: ["channel:manage", "channel:lists"],
        },
        {
          name: 'customerApp',
          path: 'customer-app',
          layout: '/settings/',
          component: CustomerApp,
          ACLs: ['settings:standard'],
        },
        {
          name: 'ownerApp',
          path: 'owner-app',
          layout: '/settings/',
          component: OwnerApp,
          ACLs: ['settings:standard'],
        },
        {
          name: 'tasks',
          state: 'settingsTasks',
          ACLs: [
            'settings:standard',
            'settings_task_auto_assignation:standard',
            'task_type:manage',
          ],
          views: [
            {
              name: 'categories',
              path: 'categories',
              component: TaskType,
              layout: '/settings/task/',
              ACLs: ['task_type:manage'],
            },
            {
              name: 'autoasignationRules',
              path: 'autoasignation-rules',
              component: AutoassignTask,
              layout: '/settings/task/',
              ACLs: ['settings_task_auto_assignation:standard'],
            },
            {
              name: 'autogenerationRules',
              path: 'autogeneration-rules',
              component: AutogenerateTask,
              layout: '/settings/task/',
              ACLs: ['settings:standard'],
            },
            {
              name: 'checklists',
              path: 'checklists',
              component: Checklist,
              layout: '/settings/task/',
              ACLs: ['settings:standard', 'task_type:manage'],
            },
            {
              name: 'extraExpenses',
              path: 'extra-expense',
              component: ExtraExpense,
              layout: '/settings/task/',
              ACLs: ['settings:standard', 'task_type:manage'],
            },
          ],
        },
        // {
        //   name: 'customFields',
        //   state: 'settingCustomFields',
        //   ACLs: ['settings:standard', 'settings_custom_fields:standard'],
        //   views: CustomFieldsRoutes,
        // },
        {
          name: 'pms',
          state: 'pms',
          ACLs: [
            'settings:standard',
            'settings_task_auto_assignation:standard',
            'task_type:manage',
          ],
          views: [
            {
              name: 'communityRules',
              path: 'community-rules',
              layout: '/settings/',
              component: CommunityRule,
              ACLs: ['settings:standard'],
            },
            {
              name: 'amenitiesSetting',
              path: 'amenities',
              layout: '/settings/',
              component: Amenity,
              ACLs: ['amenity:standard', 'amenity:read_only_acl'],
            },
            {
              name: 'contracts',
              path: 'contracts',
              layout: '/settings/',
              component: Contracts,
              // TODO: UPDATE ACLs
              ACLs: ['settings:standard'],
            },
            {
              name: 'travelReport',
              path: '/travelreport',
              layout: '/settings/',
              component: TravelReport,
              ACLs: ['settings:standard'],
            },
            {
              name: 'smartlockers',
              path: 'smartlockers',
              layout: '/settings/',
              component: SmartLockers,
              // TODO: UPDATE ACLs
              ACLs: ['settings:standard'],
            },
            {
              name: 'tagsPredefined',
              path: 'tags-predefined',
              layout: '/settings/',
              component: TagsPredefined,
              ACLs: ['settings:standard'],
            },
          ],
        },
        {
          name: 'resources',
          state: 'resources',
          ACLs: ['settings:standard'],
          views: [
            {
              name: 'permits',
              path: 'permits',
              layout: '/settings/',
              component: EmployeerPermits,
              ACLs: ['settings:standard'],
            },
          ],
        },
        /*         {
          name: 'expensesConcepts',
          path: 'expenses-concepts',
          layout: '/settings/',
          component: ComingSoon,
        },
        {
          name: 'marketplace',
          path: 'marketplace',
          layout: '/settings/',
          component: Marketplace,
          ACLs: ['plugin:manage', 'plugin:lists'],
        }, */
        // TODO: PMS
        {
          name: 'rates',
          state: 'rates',
          ACLs: ['settings:standard'],
          views: [
            {
              name: 'list',
              path: 'rates',
              layout: '/settings/',
              component: Rates,
              ACLs: ['settings:standard'],
            },
            {
              name: 'discounts',
              path: 'discounts',
              layout: '/settings/',
              component: Discounts,
              ACLs: ['settings:standard'],
            },
            {
              name: 'occupancies',
              path: 'occupancies',
              layout: '/settings/',
              component: Occupancies,
              ACLs: ['settings:standard'],
            },
            {
              name: 'deposits',
              path: 'deposits',
              layout: '/settings/',
              component: Deposits,
              // TODO: UPDATE ACLs
              ACLs: ['settings:standard'],
            },
          ],
        },

        // {
        //   name: "communications",
        //   icon: "app-icon-email-template",
        //   state: "settingsCommunications",
        //   ACLs: ["settings:manage"],
        //   views: [
        //     {
        //       name: "templates",
        //       path: "templates",
        //       layout: "/settings/communications/",
        //       component: Templates,
        //       ACLs: ["settings:manage"],
        //     },
        //     {
        //       name: "predefinedNotifications",
        //       path: "predefined-notifications",
        //       component: PredefinedNotifications,
        //       layout: "/settings/communications/",
        //       ACLs: ["settings:manage"],
        //     },
        //     {
        //       name: "emailSettings",
        //       path: "email-settings",
        //       component: EmailSettings,
        //       layout: "/settings/communications/",
        //       ACLs: ["settings:manage"],
        //     },
        //     {
        //       name: "chatSettings",
        //       path: "chat-settings",
        //       component: ChatSettings,
        //       layout: "/settings/communications/",
        //       ACLs: ["settings:manage"],
        //     },
        //   ],
        // },
        {
          name: 'settings',
          status: 'settings',
          ACLs: ['settings:standard'],
          views: [
            {
              name: 'general',
              path: 'settings',
              layout: '/settings/',
              component: Settings,
              ACLs: ['settings:standard'],
            },
            {
              name: 'finance',
              path: 'finance',
              layout: '/settings/',
              component: Finance,
              ACLs: ['settings:standard'],
            },
            {
              name: 'cities',
              path: 'cities',
              layout: '/settings/',
              component: City,
              ACLs: ['settings:standard', 'settings_city:standard'],
            },
          ],
        },
      ],
    },
  ],
  userMenu: [
    {
      name: 'myProfile',
      path: 'profile',
      icon: 'ni ni-single-02',
      component: UserProfile,
      layout: '/',
    },
    {
      name: 'account',
      path: 'account',
      layout: '/settings/',
      icon: 'th-icon-settings',
      component: Account,
    },
    {
      name: 'changePassword',
      path: 'change-password',
      icon: 'fa fa-key',
      component: ChangePassword,
      layout: '/',
    },
  ],
};
