import React, { useContext, useMemo } from 'react';
import { HubContext } from 'components/Hub/context';
import moment from 'moment';
import TimeAgo from 'react-timeago';
import { timeagoFormatter } from 'utils';
import { getInitials } from 'components/Hub/utils';

const MessageHeader = ( ) => {
  const { channels, selectedChannelId } = useContext( HubContext );

  const {
    name, initials, photo, date,
  } = useMemo( () => {
    const selectedChannel = channels.data.find( ( item ) => item.id === selectedChannelId );

    if ( !selectedChannel ) {
      return {
        name: '', initials: '', photo: '', date: null,
      };
    }
    const {
      // eslint-disable-next-line no-shadow
      name, photo, updatedAt,
    } = selectedChannel;

    return {
      name,
      initials: getInitials( name ),
      photo,
      date: updatedAt ? moment.utc( updatedAt ).local() : null,
    };
  }, [channels, selectedChannelId] );

  return (
    <header className="hub-message-list-header">
      {name ? (
        <>
          <div>

            <div className="avatar rounded-circle">
              {photo ? (
                <img
                  alt={initials}
                  src={photo}
                  className="avatar rounded-circle"
                />
              ) : initials}
            </div>
          </div>
          <div className="text-darker text-sm font-weight-500 m-0 text-uppercase">

            {name}

          </div>
          {date
            ? (
              <div className="text-darker text-xsm ml-auto">
                <TimeAgo
                  date={date}
                  formatter={timeagoFormatter}
                />
              </div>
            ) : null}
        </>
      ) : null}
    </header>
  );
};

export default MessageHeader;
