import React from 'react';
import { canModify } from 'config/Ability';
import {
  EditCard,
  Intl,
} from 'components';
import PropertyInfo from 'containers/Property/RoomType/Accommodation/Edit/RUInfo/Form';
import LandlordInfo from 'containers/Property/RoomType/Accommodation/Edit/Landlord/Form';
import ArrivalInfo from 'containers/Property/RoomType/Accommodation/Edit/ArrivalInstructions/Form';
import CheckInfo from 'containers/Property/RoomType/Accommodation/Edit/CheckInOut/Form';
import FeesInfo from 'containers/Property/RoomType/Accommodation/Edit/RUFees/Form';

export default React.memo( ( {
  name, entityId, data, paths, parentIds, onReloadData,
} ) => (
  <EditCard
    headerContent={(
      <>
        <h3 className="mt-1">
          <Intl
            id={
              canModify( 'accommodation' ) ? 'editAccommodation' : 'accommodation'
            }
          />
          {` ${name}`}
        </h3>
      </>
    )}
    cardClass="card-sec-bottom"
  >
    <PropertyInfo
      entityId={entityId}
      data={data}
      paths={paths}
      parentIds={parentIds}
      name={name}
      onReloadData={onReloadData}
    />
    <LandlordInfo
      entityId={entityId}
      data={data}
      paths={paths}
      parentIds={parentIds}
      name={name}
      onReloadData={onReloadData}
    />
    <ArrivalInfo
      entityId={entityId}
      data={data}
      paths={paths}
      parentIds={parentIds}
      name={name}
      onReloadData={onReloadData}
    />
    <CheckInfo
      entityId={entityId}
      data={data}
      paths={paths}
      parentIds={parentIds}
      name={name}
      onReloadData={onReloadData}
    />
    <FeesInfo
      entityId={entityId}
      data={data}
      paths={paths}
      parentIds={parentIds}
      name={name}
      onReloadData={onReloadData}
    />
  </EditCard>
) );
