export default [
  {
    name: 'flatPerStay',
    value: '1',
    id: '1',
  },
  {
    name: 'fixedPerDay',
    value: '2',
    id: '2',
  },
  {
    name: 'independentPercentage',
    value: '3',
    id: '3',
  },
  {
    name: 'cumulativePercentage',
    value: '4',
    id: '4',
  },
  {
    name: 'fixedAmountPerPerson',
    value: '5',
    id: '5',
  },
  {
    name: 'fixedAmountPerPersonPerDay',
    value: '6',
    id: '6',
  },
  {
    name: 'fixedAmountPerPersonPerWeek',
    value: '7',
    id: '7',
  },
  {
    name: 'fixedAmountPerWeek',
    value: '8',
    id: '8',
  },
  {
    name: 'variableAmountByConsumption',
    value: '9',
    id: '9',
  },
  {
    name: 'variableAmountByUse',
    value: '10',
    id: '10',
  },
  {
    name: 'fixedAmountPerSet',
    value: '11',
    id: '11',
  },
  {
    name: 'fixedAmountPerSetPerWeek',
    value: '12',
    id: '12',
  },
  {
    name: 'independentPercentagePerDay',
    value: '13',
    id: '13',
  },
  {
    name: 'independentPercentagePerPerson',
    value: '14',
    id: '14',
  },
];
