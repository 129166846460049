import React from 'react';
import HubContextProvider from './context';
import ChannelList from './channel-list';
import MessageList from './message-list';
import HubProps from './hub-props';

const Hub = () => (
  <HubContextProvider>
    <div className="hub">
      <div className="hub-container">
        <ChannelList />
        <MessageList />
        <HubProps />
      </div>
    </div>
  </HubContextProvider>
);
export default Hub;
