/* eslint-disable implicit-arrow-linebreak */
import React, { useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import AmenityService from 'api/Amenity';
import TranslationService from 'api/Translation';
import AmenityEditView from 'views/Amenity/Edit';
import SettingsActions from 'store/reducers/Settings';
import { useFetch } from 'hooks';
import _ from 'lodash';
import { formatDataToSend, handlePromise } from 'utils';
import Amenities from 'config/Constants/Amenities';
import CompositionRooms from 'config/Constants/CompositionRooms';

const translations = true;

const AmenityEdit = ( {
  amenityId,
  modalOpened,
  propertyManager,
  onCloseModal,
  onReload,
  toggleErrorAlert,
  toggleLoading,
  toggleInfoAlert,
} ) => {
  const [availableRooms, setAvailableRooms] = useState( [] );

  const handleAmenityChange = ( selectedAmenityId ) => {
    const selectedAmenity = Amenities.filter(
      ( amenity ) => amenity.id === selectedAmenityId,
    );

    if ( selectedAmenity.length === 0 ) {
      setAvailableRooms( CompositionRooms );
      return;
    }

    if ( selectedAmenity[0].availableFor.includes( 0 ) ) {
      setAvailableRooms( CompositionRooms );
      return;
    }

    const filteredRooms = CompositionRooms.filter( ( room ) =>
      selectedAmenity[0].availableFor.includes( parseInt( room.id, 10 ) ) );
    setAvailableRooms( filteredRooms );
  };

  const [data, dataLoading] = useFetch( {
    initialState: {},
    promise: () => AmenityService.getAmenity( amenityId ),
    translationPromise: () =>
      TranslationService.getAmenityTranslations( amenityId ),
    toggleErrorAlert,
    conditional: !!amenityId && modalOpened,
    deps: [amenityId, modalOpened],
    reInit: true,
  } );

  const [dataImage] = useFetch( {
    initialState: {},
    promise: () => AmenityService.getImage( amenityId ),
    translationPromise: () =>
      TranslationService.getAmenityTranslations( amenityId ),
    toggleErrorAlert,
    conditional: !!amenityId && modalOpened,
    deps: [amenityId, modalOpened],
    reInit: true,
  } );

  useEffect( () => {
    if ( data ) {
      if ( data.ruAmenityId ) {
        const selectedAmenity = Amenities.filter(
          ( amenity ) => amenity.id === data.ruAmenityId,
        );
        const filteredRooms = CompositionRooms.filter( ( room ) =>
          selectedAmenity[0].availableFor.includes( parseInt( room.id, 10 ) ) );
        setAvailableRooms( filteredRooms );
      }
    }
  }, [data] );

  const submitForm = useCallback(
    async ( formData, form ) => {
      toggleLoading( true );

      const dataToSend = formatDataToSend( formData, form, translations );
      const formattedData = {
        enabled: 'true',
        ...dataToSend,
      };
      if ( !amenityId ) formattedData.propertyManager = propertyManager.id;
      delete formattedData.image;

      const [errors, response, amenityData] = await handlePromise(
        amenityId
          ? AmenityService.updateAmenity( amenityId, formattedData )
          : AmenityService.saveAmenity( formattedData ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      if ( !dataToSend.image || !dataToSend.image.file ) {
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        return onReload();
      }

      const [imageErrors, imageResponse] = await handlePromise(
        AmenityService.saveImage( amenityId || amenityData.id, {
          image: dataToSend.image.file,
        } ),
      );

      if ( !imageResponse.ok ) {
        toggleLoading( false );
        return imageErrors;
      }
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      return onReload();
    },
    [amenityId, onReload, toggleInfoAlert, toggleLoading, propertyManager],
  );

  return (
    <AmenityEditView
      amenityId={amenityId}
      data={data}
      dataImage={dataImage}
      availableRooms={availableRooms}
      onAmenityChange={handleAmenityChange}
      isLoading={dataLoading}
      open={modalOpened}
      onSubmit={submitForm}
      onClose={onCloseModal}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( AmenityEdit );
