import React, { useContext, useMemo } from 'react';
import { HubContext } from 'components/Hub/context';
import { getInitials } from 'components/Hub/utils';
import classnames from 'classnames';
import moment from 'moment';

const MessageItem = ( { messageData } ) => {
  const { selectedChannel, participantId } = useContext( HubContext );

  const participant = useMemo( () => {
    if ( !selectedChannel || !selectedChannel.participants || !selectedChannel.participants.length ) return { photo: null, name: 'Anonimous', initials: 'A' };

    // participant
    const p = selectedChannel.participants
      .find( ( item ) => item.id === messageData.participantId );

    return {
      photo: p?.photo || null,
      name: p?.name || 'Anonimous',
      initials: getInitials( p?.name || 'Anonimous' ),
    };
  }, [selectedChannel, messageData] );

  return (
    <div
      className="hub-message-list-item"
    >

      <div
        className={classnames( 'hub-message-body', {
          'to-right': messageData.participantId === participantId,
        } )}
      >
        <div className="hub-message-body-img">
          <div className="avatar rounded-circle aspect-ratio-1x1">
            {participant.photo
              ? (
                <img
                  alt={participant.initials}
                  src={participant.photo}
                  className="avatar rounded-circle"
                />
              ) : participant.initials}
          </div>
        </div>
        <div className="hub-message-body-content">
          <div className="hub-message-body-content-name">{participant.name}</div>
          <div className="hub-message-body-content-message">{messageData.content}</div>
          <div className="hub-message-body-content-date">{moment( messageData.createdAt ).format( 'h:mm A' )}</div>
        </div>
      </div>
    </div>
  );
};

export default MessageItem;
