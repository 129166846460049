import React from 'react';
import {
  Spinner,
} from 'reactstrap';

const HubLoader = ( { loading } ) => (
  loading ? <div className="text-center py-2"><Spinner color="primary" size="sm" /></div> : null
);

export default HubLoader;
