import api from 'config/Api/ApiHub';

const baseEndpoint = '/channels';

export default {
  getChannels( params ) {
    return api.get( baseEndpoint, params );
  },
  getChannel( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  createChannel( data ) {
    return api.post( baseEndpoint, data );
  },
  updateChannel( id, data ) {
    return api.put(
      `${baseEndpoint}/${id}`,
      data,
    );
  },
  deleteChannel( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  addParticipants( id, data ) {
    return api.post( `${baseEndpoint}/${id}/add-participants`, data );
  },
  removeParticipants( id, data ) {
    return api.post( `${baseEndpoint}/${id}/del-participants`, data );
  },
};
