import api from 'config/Api/ApiHub';

const baseEndpoint = '/sources';

export default {
  getSources( params ) {
    return api.get( baseEndpoint, params );
  },
  getSource( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
  createSource( data ) {
    return api.post( baseEndpoint, data );
  },
  updateSource( id, data ) {
    return api.put(
      `${baseEndpoint}/${id}`,
      data,
    );
  },
  deleteSource( id ) {
    return api.get( `${baseEndpoint}/${id}` );
  },
};
