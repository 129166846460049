import React, {
  useCallback, useRef, useState,
} from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import MarketplaceView from 'views/PropertyManager/Edit/Marketplace';
import SettingsActions from 'store/reducers/Settings';
import PluginService from 'api/Plugin';
import { useFetch } from 'hooks';
import { handlePromise } from 'utils';
import { roles } from 'config/Constants';
import PropertyManagerService from 'api/PropertyManager';

const Marketplace = ( {
  userRole, propertyManager, parentPath, pathLayout, parentId, location,
  toggleLoading, toggleErrorAlert,
} ) => {
  const [dataSubmitted, setDataSubmitted] = useState( new Date().valueOf() );
  const tableParams = useRef( {} );
  const pmId = userRole === roles.nomadsAdmin.id || userRole === roles.nomadsAdminReadOnly.id
    ? parentId : propertyManager.id;

  const [pmData, pmDataLoading] = useFetch( {
    initialState: {},
    promise: () => PropertyManagerService.getPropertyManager( pmId ),
    toggleErrorAlert,
    conditional: !!pmId,
    deps: [pmId],
  } );

  const [data, dataLoading] = useFetch( {
    initialState: [],
    promise: () => PluginService.getPlugins( {
      ...tableParams.current,
      page: 1,
      elementsPerPage: 30,
      private: false,
      propertyManager: pmData.id,
    } ),
    format: ( dataToFormat ) => dataToFormat.data,
    toggleErrorAlert,
    conditional: !!pmData.id,
    deps: [pmData.id, dataSubmitted],
  } );

  const filterData = useCallback( ( params ) => {
    tableParams.current.categories = params.categories;
    tableParams.current.installed = params.status;
    setDataSubmitted( new Date().valueOf() );
  }, [] );

  const installPlugin = useCallback( async ( id ) => {
    toggleLoading( true );
    const [errors, response] = await handlePromise( PluginService.installPlugin( id, pmData.id ) );
    toggleLoading( false );

    if ( !response.ok ) return toggleErrorAlert( errors );
    setDataSubmitted( new Date().valueOf() );
  }, [pmData, toggleLoading, toggleErrorAlert] );

  return (
    <MarketplaceView
      data={data}
      pmData={pmData}
      userRole={userRole}
      isLoading={dataLoading || pmDataLoading}
      parentPath={parentPath}
      pathLayout={pathLayout}
      pathname={location.pathname}
      onFilterData={filterData}
      onInstallPlugin={installPlugin}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  userRole: user.mainRole,
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( Marketplace );
