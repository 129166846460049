import React from 'react';
import { Row, Col } from 'reactstrap';

import {
  TextField,
  Select,
  ModalForm,
  TranslationLayout,
  Intl,
  Dropzone,
} from 'components';
import { required, validUrl } from 'config/InputErrors';
import { canModify } from 'config/Ability';
import { amenityTypes } from 'config/Constants';
import Amenities from 'config/Constants/Amenities';

export default React.memo(
  ( {
    amenityId, data, availableRooms, onAmenityChange, isLoading, open, onClose, onSubmit,
  } ) => (
    <ModalForm
      initialValues={data}
      title={amenityId ? 'editAmenitySetting' : 'newAmenitySetting'}
      showErrorsInFooter
      isLoading={isLoading}
      open={open}
      onSubmit={canModify( 'amenity' ) ? onSubmit : () => {}}
      onClose={onClose}
      formEnabled={canModify( 'amenity' )}
      body={() => (
        <TranslationLayout
          translationFields={( locale ) => (
            <>
              <Row>
                <Col md={6}>
                  <TextField
                    field={`translations.${locale}.name`}
                    label="name"
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TextField
                    field={`translations.${locale}.description`}
                    label="description"
                    type="textarea"
                    rows={2}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <TextField
                    field={`translations.${locale}.instructions`}
                    label="instructions"
                    type="textarea"
                    rows={4}
                  />
                </Col>
              </Row>
            </>
          )}
        >
          <Row>
            <Col md={6}>
              <TextField
                field="name"
                label="name"
                validate={required}
              />
            </Col>
            <Col md={6}>
              <TextField field="video" label="video" validate={validUrl} />
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              <Row>
                <Col md={6}>
                  <Select
                    field="type"
                    label="type"
                    options={amenityTypes}
                    validate={required}
                    translateOptions
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    field="cost"
                    label="estimatedValue"
                    type="number"
                    validate={required}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Select
                    field="ruAmenityId"
                    label="amenity:ruAmenity"
                    options={Amenities}
                    onChange={onAmenityChange}
                  />
                </Col>
                <Col md={6}>
                  <Select
                    field="ruCompositionRoomId"
                    label="amenity:ruCompositionRoom"
                    options={availableRooms}
                    noFilterByInput
                  />
                </Col>
              </Row>
              <TextField
                field="description"
                label="description"
                type="textarea"
                rows={2}
                validate={required}
              />
              <TextField
                field="instructions"
                label="instructions"
                type="textarea"
                rows={4}
              />
            </Col>
            <Col lg={4}>
              <Dropzone
                disableDelete
                field="image"
                formGroupClass="dz-profile-img"
                label="image"
                placeholder={(
                  <>
                    <i className="fa fa-file fa-4x mb-4" />
                    <p>
                      <Intl
                        id="upload.maxMesureSizeAndHeight"
                        values={{ size: '100Kb', height: '90px' }}
                      />
                    </p>
                  </>
                )}
              />
            </Col>
          </Row>
        </TranslationLayout>
      )}
    />
  ),
);
