import React, { useContext, useMemo, useState } from 'react';
import { HubContext } from 'components/Hub/context';
import classNames from 'classnames';
import WhatsappIcon from './icons/whatsapp';
import SmsIcon from './icons/sms';
import EmailIcon from './icons/email';
import AllIcon from './icons/all';

const icons = {
  whatsapp: {
    name: 'Whatsapp',
    icon: <WhatsappIcon />,
  },
  sms: {
    name: 'SMS',
    icon: <SmsIcon />,
  },
  email: {
    name: 'Email',
    icon: <EmailIcon />,
  },
  none: {
    name: 'All',
    icon: <AllIcon />,
  },
};

const SourceSelector = () => {
  const {
    sources, loadingSources, selectedSourceId,
    selectSource,
  } = useContext( HubContext );

  const [isOpen, setIsOpen] = useState( false );

  const typeSelected = useMemo( () => {
    const sourceSelected = sources.data.find( ( s ) => s.id === selectedSourceId );
    if ( !sourceSelected ) return 'none';

    return sourceSelected.type;
  }, [sources, selectedSourceId] );

  return (
    <div className={classNames( 'hub-source-selector', {
      'is-disabled': loadingSources,
    } )}
    >
      <button
        className="hub-source-selector-button"
        disabled={loadingSources}
        type="button"
        onFocus={() => setIsOpen( true )}
        onBlur={() => setTimeout( () => setIsOpen( false ), 140 )}
      >
        {icons[typeSelected].icon}
        <i className="fa fa-caret-up caret" />
      </button>
      {isOpen && (
      <div className="hub-source-selector-list">
        <div
          className="hub-source-selector-list-item"
          onClick={() => {
            selectSource( null );
          }}
        >
          {icons.none.icon}
          {icons.none.name}
        </div>
        {sources.data.map( ( { id, type } ) => (
          <div
            className="hub-source-selector-list-item"
            key={type}
            onClick={() => {
              selectSource( id );
            }}
          >
            {icons[type].icon}
            {icons[type].name}
          </div>
        ) )}
      </div>
      )}
    </div>
  );
};

export default SourceSelector;
