import {
  useState,
} from 'react';
import { useFetch } from 'hooks';
import SourceService from 'api/Hub/Source';

const useSources = ( participantId, toggleErrorAlert ) => {
  const [selectedSourceId, setSelectedSourceId] = useState( null );

  const [sources, loadingSources] = useFetch( {
    promise: () => SourceService.getSources( {
      page: 1,
      limit: 999,
      'filters[participantId]': participantId,
    } ),
    initialState: {
      data: [],
      total: 0,
    },
    toggleErrorAlert,
    deps: [participantId],
    reInit: true,
  } );

  return [sources, loadingSources, selectedSourceId, setSelectedSourceId];
};
export default useSources;
