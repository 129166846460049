import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import {
  Form,
  TextField,
  Intl,
  Button,
  EditCard,
  Switch,
  Select,
  CanAccess,
  FormActionsBtns,
} from 'components';
import RUFeesDiscriminator from 'config/Constants/RUFeesDiscriminator';

export default React.memo( ( { data, onSubmit, paths } ) => {
  const [show, setShow] = useState( false );

  const toggleShow = () => setShow( !show );
  return (
    <>
      <h3 className="m-0">
        <Row>
          <Col>
            <Intl id="ruFees" />
          </Col>
          <Col className="d-flex flex-row-reverse">
            <Button
              size="md"
              title={!show ? 'ru.settings.show' : 'ru.settings.hide'}
              onClick={toggleShow}
            />
          </Col>
        </Row>
      </h3>
      <hr />
      {show ? (
        <Form
          initialValues={data}
          onSubmit={onSubmit}
          showErrorsInFooter
          body={() => (
            <EditCard cardClass="card-sec-bottom">
              {data.fees.map( ( item, index ) => (
                <>
                  <Row className="mb-3 align-items-center justify-content-center">
                    <Col md={3}>
                      <label className="form-control-label">
                        <Intl id={item.name} />
                      </label>
                    </Col>
                    <Col md={3}>
                      <Switch field={`fees[${index}].enable`} label="enable" />
                    </Col>
                    <Col md={3}>
                      <Select
                        field={`fees[${index}].discriminator`}
                        label="discriminator"
                        // validate={required}
                        options={RUFeesDiscriminator}
                        translateOptions
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        field={`fees[${index}].value`}
                        label="value"
                        type="number"
                      />
                    </Col>
                  </Row>
                </>
              ) )}
            </EditCard>
          )}
          footer={(
            <CanAccess I="edit" a="accommodation">
              <FormActionsBtns cancelTo={paths.accommodation} />
            </CanAccess>
)}
        />
      ) : null}
    </>
  );
} );
