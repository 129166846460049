import api from 'config/Api/Api';

const baseEndpoint = '/plugins';

export default {
  getPlugins( params ) {
    return api.get( baseEndpoint, params );
  },

  installPlugin( id, pmId ) {
    return api.post( `${baseEndpoint}/${id}/${pmId}`, {} );
  },
};
