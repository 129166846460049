import React, { useContext, useMemo } from 'react';
import { HubContext } from '../context';

const HubProps = () => {
  const {
    selectedChannel, loadingSelectedChannel,
  } = useContext( HubContext );

  const metadata = useMemo( () => {
    if ( !selectedChannel || loadingSelectedChannel ) {
      return '-';
    }

    return Object.entries( selectedChannel?.metadata ?? {} ).reduce( ( str, [key, value] ) => `${str}${key}: ${value}\n`, '' );
  }, [selectedChannel, loadingSelectedChannel] );

  return (
    <div className="hub-props">
      <h3>Metadata</h3>
      <p>{metadata}</p>
    </div>
  );
};

export default HubProps;
