import { useState, useEffect } from 'react';
import _ from 'lodash';

import { handlePromise } from 'utils';

export default ( {
  initialState, promise, translationPromise, format, callback, conditional, reInit, deps = [],
  toggleErrorAlert,
} ) => {
  const [data, setData] = useState( initialState );
  const [dataLoading, setDataLoading] = useState();
  useEffect( () => {
    const getData = async () => {
      setDataLoading( true );
      const [errors, response, responseData] = await handlePromise( promise() );

      if ( !response.ok ) {
        setDataLoading( false );
        return toggleErrorAlert
          ? toggleErrorAlert( errors ) : [initialState, false];
      }

      if ( translationPromise ) {
        const [
          translationErrors,
          translationResponse,
          translationResponseData,
        ] = await handlePromise( translationPromise( responseData ) );

        if ( !translationResponse.ok ) {
          setDataLoading( false );
          return toggleErrorAlert( translationErrors );
        }

        if ( !_.isArray( translationResponseData ) ) {
          responseData.translations = translationResponseData;
        } else responseData.translations = {};
      }

      setDataLoading( false );
      const newData = format ? await format( responseData ) : responseData;
      setData( newData );
      if ( callback ) callback( ...deps, newData );
    };
    if ( conditional === undefined ) getData();
    else if ( conditional ) getData();
    else if ( reInit && !_.isEqual( data, initialState ) ) setData( initialState );
  }, [...deps, conditional] ); //eslint-disable-line

  return [data, dataLoading, setData];
};
