export default [
  {
    id: '53',
    name: 'WC',
  },
  {
    id: '81',
    name: 'Bathroom',
  },
  {
    id: '94',
    name: 'Kitchen in the living / dining room',
  },
  {
    id: '101',
    name: 'Kitchen',
  },
  {
    id: '249',
    name: 'Living room',
  },
  {
    id: '257',
    name: 'Bedroom',
  },
  {
    id: '372',
    name: 'Livingroom / Bedroom',
  },
  {
    id: '517',
    name: 'Bedroom/Living room with kitchen corner',
  },
  {
    id: '0',
    name: 'Other',
  },
];
