import {
  useState,
} from 'react';
import { useFetch, useFilters } from 'hooks';
import ChannelService from 'api/Hub/Channel';
import { CHANNEL_PER_PAGE } from '../constants';

const useChannels = ( toggleErrorAlert ) => {
  const [selectedChannelId, setSelectedChannelId] = useState( null );
  const [selectedChannel, loadingSelectedChannel] = useFetch( {
    promise: ( ) => ChannelService.getChannel( selectedChannelId || '' ),
    toggleErrorAlert,
    conditional: !!selectedChannelId,
    deps: [selectedChannelId],
    reInit: true,
  } );
  //  CHANNEL LIST
  const [channels, setChannels] = useState( {
    totalPages: 0,
    pagesLoaded: {},
    data: [],
  } );

  const [filterChannels, setFilterChannels] = useFilters( {
    page: 1,
    limit: CHANNEL_PER_PAGE,
    'orderBy[createdAt]': 'asc',
  } );
  const [, loadingChannels] = useFetch( {
    promise: ( ) => ChannelService.getChannels( filterChannels ),
    initialState: {
      data: [], total: 0,
    },
    toggleErrorAlert,
    deps: [filterChannels],
    reInit: true,
    callback: ( { page }, newChannelListData ) => {
      setChannels( ( oldChannelListData ) => {
        const totalPages = Math.ceil( newChannelListData.total / CHANNEL_PER_PAGE );

        const newChannels = { ...oldChannelListData, totalPages };

        newChannels.pagesLoaded[page || 1] = [...newChannelListData.data];

        newChannels.data = Object.entries( newChannels.pagesLoaded )
          .sort( ( a, b ) => a[0] - b[0] ).reduce( ( list, [, value] ) => {
            list.push( ...value );
            return list;
          }, [] );

        if ( !selectedChannelId ) {
          setSelectedChannelId( newChannels.data[0]?.id );
        }

        return newChannels;
      } );
    },
  } );

  return [
    selectedChannelId,
    setSelectedChannelId,
    selectedChannel,
    loadingSelectedChannel,
    channels,
    filterChannels,
    setFilterChannels,
    loadingChannels,
  ];
};

export default useChannels;
