import React from 'react';
import {
  TextField,
} from 'components';

const ChannelHeader = () => (
  <header className="hub-channel-list-header">
    <TextField
      value="Filter (TO DO)"
      // onChange={handleChange}
      name="filter"
      size="lg"
      placeholder="filterChats"
      formGroupClass="hub-channel-list-header-filter"
      appendIconClass="fa-search"
    />
  </header>
);
export default ChannelHeader;
