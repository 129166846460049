/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import { handlePromise } from 'utils';
import ruFeesService from 'api/RUFees';
import SettingsActions from 'store/reducers/Settings';
import RUFeesView from 'views/Property/RoomType/Accommodation/Edit/RUFees/Form';
import { connect } from 'react-redux';
import _get from 'lodash/get';
import RUFeeCategories from 'config/Constants/RUFeeCategories';

const RUFees = ( {
  data,
  entityId,
  paths,
  parentIds,
  toggleLoading,
  toggleInfoAlert,
  onReloadData,
} ) => {
  const [feeCategories, setFeeCategories] = useState(
    RUFeeCategories.map( ( item ) => ( { ...item, value: 0 } ) ),
  );

  const submitForm = useCallback(
    async ( formData ) => {
      toggleLoading( true );
      const formattedData = {
        fees: formData.fees
          .filter( ( item ) => item.enable )
          .map( ( { enable, ...rest } ) => ( {
            ...rest,
          } ) ),
      };

      const propertyId = parentIds.property;
      const roomTypeId = parentIds.roomType;

      const [errors, response] = await handlePromise(
        _get( data, 'entity.fees.id' )
          ? formattedData.fees.length > 0
            ? ruFeesService.updateRUFees(
              propertyId,
              roomTypeId,
              entityId,
              formattedData,
            )
            : ruFeesService.deleteRUFees( propertyId, roomTypeId, entityId )
          : ruFeesService.saveRUFees(
            propertyId,
            roomTypeId,
            entityId,
            formattedData,
          ),
      );

      if ( !response.ok ) {
        toggleLoading( false );
        return errors;
      }

      onReloadData();
      toggleLoading( false );
      return toggleInfoAlert( 'dataSaved' );
    },
    [toggleInfoAlert, toggleLoading, entityId, data, parentIds, onReloadData],
  );

  useEffect( () => {
    if ( data.entity && data.entity.fees ) {
      const _currentFees = data.entity.fees;
      const _feeCategories = RUFeeCategories.map( ( item ) => {
        const feeFound = _currentFees.fees.find(
          ( fee ) => fee.name === item.name,
        );

        if ( feeFound ) {
          return {
            ...feeFound,
            enable: true,
          };
        }
        return {
          ...item,
          value: 0,
        };
      } );
      setFeeCategories( _feeCategories );
    }
  }, [data.entity, data] );

  return (
    <RUFeesView
      data={{ fees: feeCategories }}
      paths={paths}
      onSubmit={submitForm}
    />
  );
};

const mapDispatchToProps = {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
};

export default connect( null, mapDispatchToProps )( RUFees );
