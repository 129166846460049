import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import TaskOverviewView from 'views/Task/EditDrawer/Overview';
import SettingsActions from 'store/reducers/Settings';
import TaskTypeService from 'api/TaskType';
// import ExtraExpenseService from 'api/ExtraExpenses';
import TaskService from 'api/Task';
import TaskImageService from 'api/TaskImage';
import TaskNoteService from 'api/TaskNote';
import { handlePromise } from 'utils';
import { useFetch } from 'hooks';
import { each } from 'utils/async';

const TaskOverview = ( {
  taskId,
  data,
  onReload,
  // customFields,
  // propertyManager,
  onFormatData,
  setData,
  toggleLoading,
  toggleErrorAlert,
  onUpdateField,
  toggleInfoAlert,
} ) => {
  const [currentExpenses, setCurrentExpenses] = useState( [] );

  const submitFieldForm = useCallback(
    async ( formData, field, dataKey, cb ) => {
      if ( _.get( formData, dataKey ) !== _.get( data, dataKey ) ) {
        toggleLoading( true );

        const formattedData = { extraCosts: [null] };
        // const customField = _.find( customFields, { name: field } );
        // if ( customField ) {
        //   const newData = {};
        //   newData[field] = formData[field];
        //   formattedData = formatCustomValuesToSend( newData, [customField] );
        // } else {
        //   formattedData[field] = _.get( formData, dataKey );
        // }
        formattedData[field] = _.get( formData, dataKey );

        // eslint-disable-next-line no-unused-vars
        const [errors, response] = await handlePromise(
          TaskService.updateTask( taskId, formattedData ),
        );
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleErrorAlert( 'apiError' );
        }

        const newData = _.cloneDeep( data );
        _.set( newData, field, formData[field] );
        setData( newData );
        if ( cb ) cb();
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
        if ( typeof onUpdateField === 'function' ) onUpdateField();
        onReload();
      }
    },
    [
      taskId,
      data,
      // customFields,
      setData,
      toggleErrorAlert,
      toggleLoading,
      onUpdateField,
      toggleInfoAlert,
      onReload,
    ],
  );

  const submitExtraCosts = useCallback(
    async ( extraCosts, cb ) => {
      toggleLoading( true );

      const _extraCosts = _.map(
        _.filter( extraCosts, ( item ) => !item.isExpense ),
        ( { totalCost, ...rest } ) => ( {
          ...rest,
          name: rest.description,
        } ),
      );

      const extraExpenses = _.map(
        currentExpenses,
        ( item ) => ( {
          name: item.original.name,
          description: item.original.description,
          cost: item.original.cost,
          count: item.original.count,
          isExpense: true,
        } ),
      );

      // eslint-disable-next-line no-unused-vars
      const [errors, response] = await handlePromise(
        TaskService.updateTask( taskId, {
          extraCosts: [..._extraCosts, ...extraExpenses],
        } ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( 'apiError' );
      }

      if ( cb ) cb();
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
      if ( typeof onUpdateField === 'function' ) onUpdateField();
      onReload();
    },
    [
      taskId,
      toggleErrorAlert,
      toggleLoading,
      onUpdateField,
      toggleInfoAlert,
      onReload,
      currentExpenses,
    ],
  );

  const assignTask = useCallback(
    async ( formData, team ) => {
      toggleLoading( true );

      // eslint-disable-next-line no-unused-vars
      const [errors, response, responseData] = await handlePromise(
        TaskService.assignTask( taskId, formData?.assignedTo, team ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( 'apiError' );
      }
      setData( onFormatData( responseData ) );
      toggleLoading( false );
      toggleInfoAlert( 'dataSaved' );
    },
    [
      taskId,
      setData,
      onFormatData,
      toggleErrorAlert,
      toggleLoading,
      toggleInfoAlert,
    ],
  );

  const saveImage = useCallback(
    async ( images ) => {
      toggleLoading( true );

      const newImages = _.cloneDeep( images );
      await each(
        newImages,
        async ( image, eCb ) => {
          if ( image.id ) return eCb();

          const [errors, response, responseData] = await handlePromise(
            TaskImageService.saveImage( taskId, { image: image.file } ),
          );
          if ( !response.ok ) return eCb( errors );
          // eslint-disable-next-line no-param-reassign
          image.id = responseData.id;
          eCb();
        },
        ( error ) => {
          toggleLoading( false );
          if ( error ) return toggleErrorAlert( error );
          setData( { ...data, images: newImages } );
        },
      );
      toggleInfoAlert( 'dataSaved' );
    },
    [taskId, data, setData, toggleErrorAlert, toggleLoading, toggleInfoAlert],
  );

  const deleteImage = useCallback(
    async ( id ) => {
      toggleLoading( true );
      const [imageErrors, imageResponse] = await handlePromise(
        TaskImageService.deleteImage( taskId, id ),
      );
      toggleLoading( false );
      if ( !imageResponse.ok ) return toggleErrorAlert( imageErrors );
      setData( {
        ...data,
        images: _.filter( data.images, ( item ) => item.id !== id ),
      } );
      toggleInfoAlert( 'dataSaved' );
    },
    [taskId, data, setData, toggleErrorAlert, toggleLoading, toggleInfoAlert],
  );

  const submitComment = useCallback(
    async ( formData, cb ) => {
      if ( formData.newComment ) {
        toggleLoading( true );

        // eslint-disable-next-line no-unused-vars
        const [errors, response, responseData] = await handlePromise(
          TaskNoteService.saveNote( taskId, { content: formData.newComment } ),
        );
        if ( !response.ok ) {
          toggleLoading( false );
          return toggleErrorAlert( 'apiError' );
        }

        const newData = _.cloneDeep( data );
        newData.notes.push( responseData );
        setData( newData );
        cb();
        toggleLoading( false );
        toggleInfoAlert( 'dataSaved' );
      }
    },
    [taskId, data, setData, toggleErrorAlert, toggleLoading, toggleInfoAlert],
  );

  const deleteComment = useCallback(
    async ( id ) => {
      toggleLoading( true );
      const [errors, response] = await handlePromise(
        TaskNoteService.deleteNote( taskId, id ),
      );
      toggleLoading( false );
      if ( !response.ok ) return toggleErrorAlert( errors );
      setData( {
        ...data,
        notes: _.filter( data.notes, ( item ) => item.id !== id ),
      } );
      toggleInfoAlert( 'dataSaved' );
    },
    [taskId, data, setData, toggleErrorAlert, toggleLoading, toggleInfoAlert],
  );

  const [taskTypes, loadingTaskTypes] = useFetch( {
    initialState: [],
    promise: TaskTypeService.getTaskTypes,
    format: ( dataToFormat ) => {
      const orignalData = _.get( dataToFormat, 'data', [] );
      const parentTaskTypes = _.filter( orignalData, ( { parent } ) => !parent );
      const dataFormated = _.map( parentTaskTypes, ( parent ) => ( {
        ...parent,
        options: _.filter(
          orignalData,
          ( taskType ) => _.get( taskType, 'parent.id' ) === parent.id,
        ),
      } ) );
      return dataFormated;
    },
    toggleErrorAlert,
  } );

  const submitExpenses = useCallback(
    async ( extraExpenses, extraCosts ) => {
      toggleLoading( true );
      const _extraExpenses = [
        ..._.map( extraExpenses, ( item ) => ( {
          name: item.original.name,
          description: item.original.description,
          cost: item.original.cost,
          isExpense: true,
        } ) ),
      ];

      const _extraCosts = [
        ..._.map( extraCosts, ( { totalCost, ...rest } ) => rest ),
      ];

      const [, response] = await handlePromise(
        TaskService.updateTask( taskId, {
          extraCosts: [..._extraCosts, ..._extraExpenses],
        } ),
      );
      if ( !response.ok ) {
        toggleLoading( false );
        return toggleErrorAlert( 'apiError' );
      }
      onReload();
      toggleLoading();
    },
    [toggleLoading, toggleErrorAlert, onReload, taskId],
  );

  // const submitNewExpense = useCallback(
  //   async ( dataToSend, extraExpenses, extraCosts ) => {
  //     toggleLoading( true );
  //     const _dataToSend = {
  //       ...dataToSend,
  //       propertyManager: propertyManager.id,
  //     };
  //     const { data: newExpense } = await ExtraExpenseService.saveExtraExpense(
  //       _dataToSend,
  //     );
  //     const _extraExpenses = [
  //       ..._.map( extraExpenses, ( item ) => item.value ),
  //       newExpense.id,
  //     ];
  //     const [, response] = await handlePromise(
  //       TaskService.updateTask( taskId, {
  //         extraExpenses: _extraExpenses,
  //         extraCosts,
  //       } ),
  //     );
  //     if ( !response.ok ) {
  //       toggleLoading( false );
  //       return toggleErrorAlert( 'apiError' );
  //     }
  //     onReload();
  //     toggleLoading();
  //     return newExpense;
  //   },
  //   [propertyManager.id, toggleLoading, toggleErrorAlert, onReload, taskId],
  // );
  useEffect( () => {
    if ( data && data.extraExpenses ) {
      setCurrentExpenses( data.extraExpenses );
    }
  }, [data] );

  return (
    <TaskOverviewView
      data={data}
      // customFields={customFields}
      onSubmit={submitFieldForm}
      onSubmitExtraCosts={submitExtraCosts}
      // onSubmitNewExpense={submitNewExpense}
      onSubmitExpenses={submitExpenses}
      onAssign={assignTask}
      onAddImage={saveImage}
      onDeleteImage={deleteImage}
      onSubmitComment={submitComment}
      onDeleteComment={deleteComment}
      taskTypes={taskTypes}
      isLoadingTaskTypes={loadingTaskTypes}
      toggleLoading={toggleLoading}
    />
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
};

export default connect( mapStateToProps, mapDispatchToProps )( TaskOverview );
