export default [
  {
    id: '2',
    name: 'Batería de cocina y utensilios de cocina',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '3',
    name: 'Vajilla y cubertería',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '4',
    name: 'Plancha y tabla de planchar',
    availableFor: [
      81,
      101,
      249,
      257,
      372,
    ],
  },
  {
    id: '5',
    name: 'Tendedero',
    availableFor: [
      81,
      101,
      249,
    ],
  },
  {
    id: '6',
    name: 'Secador de pelo',
    availableFor: [
      81,
    ],
  },
  {
    id: '7',
    name: 'Ropa de cama y toallas',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '8',
    name: 'Artículos de aseo',
    availableFor: [
      81,
    ],
  },
  {
    id: '9',
    name: 'Calefacción central',
    availableFor: [
      0,
    ],
  },
  {
    id: '11',
    name: 'Lavadora',
    availableFor: [
      53,
      81,
      94,
      101,
      257,
      517,
    ],
  },
  {
    id: '13',
    name: 'Lavavajillas',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '17',
    name: 'pava',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '19',
    name: 'Televisión por cable',
    availableFor: [
      249,
      257,
      372,
    ],
  },
  {
    id: '21',
    name: 'Despertador',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '22',
    name: 'Estéreo',
    availableFor: [
      372,
    ],
  },
  {
    id: '23',
    name: 'DVD',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '24',
    name: 'reproductor de CD',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '25',
    name: 'Reproductor de vídeo (VHS)',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '27',
    name: 'También hay un segundo baño separado.',
    availableFor: [
      0,
    ],
  },
  {
    id: '29',
    name: 'bidé',
    availableFor: [
      53,
      81,
    ],
  },
  {
    id: '32',
    name: 'armario',
    availableFor: [
      81,
      101,
      249,
      257,
      372,
    ],
  },
  {
    id: '33',
    name: 'armario de tocador',
    availableFor: [
      81,
    ],
  },
  {
    id: '35',
    name: 'Baño jacuzzi con ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '36',
    name: 'lavabo con espejo',
    availableFor: [
      0,
    ],
  },
  {
    id: '37',
    name: 'baño',
    availableFor: [
      53,
      81,
      257,
    ],
  },
  {
    id: '46',
    name: 'unidad de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '50',
    name: 'cuarto de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '52',
    name: 'Baño con ducha',
    availableFor: [
      81,
    ],
  },
  {
    id: '53',
    name: 'WC',
    availableFor: [
      0,
    ],
  },
  {
    id: '55',
    name: 'Cuna',
    availableFor: [
      372,
    ],
  },
  {
    id: '56',
    name: 'Opción: silla de bebé',
    availableFor: [
      0,
    ],
  },
  {
    id: '58',
    name: 'radiadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '61',
    name: 'cama doble',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '63',
    name: 'Opción: cuna y trona para bebé.',
    availableFor: [
      0,
    ],
  },
  {
    id: '66',
    name: 'armarios empotrados',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '69',
    name: 'armarios',
    availableFor: [
      0,
    ],
  },
  {
    id: '70',
    name: 'mesita de noche',
    availableFor: [
      257,
      372,
    ],
  },
  {
    id: '71',
    name: 'mesas de noche',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '72',
    name: 'Lámparas de lectura',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '73',
    name: 'escritorio',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '74',
    name: 'TELEVISOR',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '78',
    name: 'cómoda',
    availableFor: [
      53,
      81,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '79',
    name: 'Baño/WC',
    availableFor: [
      0,
    ],
  },
  {
    id: '81',
    name: 'Baño',
    availableFor: [
      81,
    ],
  },
  {
    id: '82',
    name: 'Baños',
    availableFor: [
      0,
    ],
  },
  {
    id: '87',
    name: 'Dormitorios',
    availableFor: [
      0,
    ],
  },
  {
    id: '89',
    name: 'Balcón',
    availableFor: [
      94,
      101,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '91',
    name: 'Pequeña terraza',
    availableFor: [
      0,
    ],
  },
  {
    id: '92',
    name: 'Conservatorio con mesa de comedor y sillas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '93',
    name: 'gradas',
    availableFor: [
      0,
    ],
  },
  {
    id: '94',
    name: 'Cocina en el salón/comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '96',
    name: 'Balcón pequeño',
    availableFor: [
      0,
    ],
  },
  {
    id: '97',
    name: 'Sala',
    availableFor: [
      0,
    ],
  },
  {
    id: '98',
    name: 'Corredor',
    availableFor: [
      0,
    ],
  },
  {
    id: '99',
    name: 'Salón',
    availableFor: [
      0,
    ],
  },
  {
    id: '100',
    name: 'Terraza',
    availableFor: [
      101,
      249,
      257,
    ],
  },
  {
    id: '101',
    name: 'Cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '102',
    name: 'Cocina moderna',
    availableFor: [
      0,
    ],
  },
  {
    id: '106',
    name: 'estanterías',
    availableFor: [
      0,
    ],
  },
  {
    id: '110',
    name: 'placa de cocina con horno',
    availableFor: [
      0,
    ],
  },
  {
    id: '114',
    name: 'placa de cocina',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '115',
    name: 'horno',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '118',
    name: 'estilo moderno',
    availableFor: [
      0,
    ],
  },
  {
    id: '119',
    name: 'estufa',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '123',
    name: 'Hervidor eléctrico',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '124',
    name: 'microonda',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '125',
    name: 'tostadora',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '127',
    name: 'tradicional',
    availableFor: [
      0,
    ],
  },
  {
    id: '128',
    name: 'platos',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '129',
    name: 'sartenes',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '130',
    name: 'Frigorífico/congelador',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '131',
    name: 'refrigerador',
    availableFor: [
      94,
      101,
      249,
      257,
      517,
    ],
  },
  {
    id: '133',
    name: 'Mini tabla de planchar',
    availableFor: [
      0,
    ],
  },
  {
    id: '134',
    name: 'lavadora con secadora',
    availableFor: [
      81,
      94,
      101,
      517,
    ],
  },
  {
    id: '135',
    name: 'cocina totalmente equipada',
    availableFor: [
      0,
    ],
  },
  {
    id: '137',
    name: 'lavadora secadora de ropa',
    availableFor: [
      0,
    ],
  },
  {
    id: '140',
    name: 'Cafetera',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '142',
    name: 'escurridor de platos',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '143',
    name: 'aspiradora',
    availableFor: [
      0,
    ],
  },
  {
    id: '144',
    name: 'puede',
    availableFor: [
      0,
    ],
  },
  {
    id: '146',
    name: 'encimera de gas/electrica',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '147',
    name: 'horno eléctrico',
    availableFor: [
      0,
    ],
  },
  {
    id: '150',
    name: 'barra de desayuno y taburetes',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '151',
    name: 'mesa y sillas de cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '152',
    name: 'congelador',
    availableFor: [
      101,
    ],
  },
  {
    id: '157',
    name: 'cocina pequeña',
    availableFor: [
      94,
      101,
      249,
      372,
      517,
    ],
  },
  {
    id: '161',
    name: 'Sillones',
    availableFor: [
      249,
      257,
      372,
    ],
  },
  {
    id: '163',
    name: 'mesa de centro',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '166',
    name: 'TV (sólo canales locales)',
    availableFor: [
      249,
      257,
    ],
  },
  {
    id: '167',
    name: 'televisión por satélite',
    availableFor: [
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '174',
    name: 'Conexión a Internet',
    availableFor: [
      372,
    ],
  },
  {
    id: '180',
    name: 'aire acondicionado',
    availableFor: [
      94,
      101,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '181',
    name: 'Aire acondicionado/calefacción',
    availableFor: [
      0,
    ],
  },
  {
    id: '182',
    name: 'sofá',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '186',
    name: 'unidad de aire acondicionado portátil',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '187',
    name: 'calefacción',
    availableFor: [
      249,
    ],
  },
  {
    id: '188',
    name: 'lámpara',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '189',
    name: 'mesa y sillas',
    availableFor: [
      94,
      101,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '192',
    name: 'sofá de cuero',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '193',
    name: 'Sillón y reposapiés',
    availableFor: [
      0,
    ],
  },
  {
    id: '197',
    name: 'estantes',
    availableFor: [
      94,
      101,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '198',
    name: 'radio',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '199',
    name: 'Computadora con acceso gratuito a Internet ASDL',
    availableFor: [
      0,
    ],
  },
  {
    id: '200',
    name: 'sofá cama doble',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '201',
    name: 'armario',
    availableFor: [
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '203',
    name: 'sofá doble',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '204',
    name: 'Cómodo salón en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '205',
    name: 'Mesa de ayuda',
    availableFor: [
      0,
    ],
  },
  {
    id: '208',
    name: 'Sillas altas',
    availableFor: [
      0,
    ],
  },
  {
    id: '209',
    name: 'Cama adicional',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '210',
    name: 'Colchón',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '215',
    name: 'Servicio de recogida en el aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '225',
    name: 'Servicio de mucama',
    availableFor: [
      0,
    ],
  },
  {
    id: '227',
    name: 'piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '231',
    name: 'Limpieza semanal gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '232',
    name: 'Tintorería y lavandería',
    availableFor: [
      81,
      101,
    ],
  },
  {
    id: '233',
    name: 'Limpieza en seco',
    availableFor: [
      0,
    ],
  },
  {
    id: '234',
    name: 'Lavadero',
    availableFor: [
      0,
    ],
  },
  {
    id: '235',
    name: 'Desayuno',
    availableFor: [
      0,
    ],
  },
  {
    id: '237',
    name: 'sofá cama',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '239',
    name: 'ducha',
    availableFor: [
      81,
    ],
  },
  {
    id: '245',
    name: 'lavabo',
    availableFor: [
      53,
      81,
    ],
  },
  {
    id: '248',
    name: 'espacio de almacenamiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '249',
    name: 'Sala de estar',
    availableFor: [
      0,
    ],
  },
  {
    id: '250',
    name: 'mesa de comedor',
    availableFor: [
      94,
      101,
      249,
      257,
    ],
  },
  {
    id: '252',
    name: 'baño',
    availableFor: [
      0,
    ],
  },
  {
    id: '253',
    name: 'jacuzzi',
    availableFor: [
      81,
      257,
    ],
  },
  {
    id: '257',
    name: 'Dormitorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '258',
    name: 'cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '261',
    name: 'Admirador',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '277',
    name: 'Muebles antiguos',
    availableFor: [
      0,
    ],
  },
  {
    id: '281',
    name: 'Posibilidad de acceso en silla de ruedas',
    availableFor: [
      81,
    ],
  },
  {
    id: '294',
    name: 'Hay un gimnasio en el edificio para uso de los huéspedes.',
    availableFor: [
      0,
    ],
  },
  {
    id: '295',
    name: 'Estacionamiento en la calle',
    availableFor: [
      0,
    ],
  },
  {
    id: '296',
    name: 'Estacionamiento subterráneo',
    availableFor: [
      0,
    ],
  },
  {
    id: '298',
    name: 'Encargado del edificio',
    availableFor: [
      0,
    ],
  },
  {
    id: '302',
    name: 'Estacionamiento vigilado',
    availableFor: [
      0,
    ],
  },
  {
    id: '308',
    name: 'Teléfono (sólo llamadas entrantes)',
    availableFor: [
      0,
    ],
  },
  {
    id: '309',
    name: 'Teléfono (llamadas entrantes y salientes)',
    availableFor: [
      0,
    ],
  },
  {
    id: '311',
    name: 'Tocador',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '312',
    name: 'Máquina de fax',
    availableFor: [
      0,
    ],
  },
  {
    id: '314',
    name: 'Internet (ordenador suministrado)',
    availableFor: [
      0,
    ],
  },
  {
    id: '315',
    name: 'bañera',
    availableFor: [
      81,
    ],
  },
  {
    id: '320',
    name: 'entrada separada',
    availableFor: [
      0,
    ],
  },
  {
    id: '321',
    name: 'baño separado',
    availableFor: [
      0,
    ],
  },
  {
    id: '322',
    name: 'una puerta a la terraza',
    availableFor: [
      0,
    ],
  },
  {
    id: '323',
    name: 'cama individual',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '324',
    name: 'cama tamaño king',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '325',
    name: 'reproductor de DVD',
    availableFor: [
      249,
    ],
  },
  {
    id: '326',
    name: 'Selección de CD',
    availableFor: [
      0,
    ],
  },
  {
    id: '327',
    name: 'Selección de DVD',
    availableFor: [
      0,
    ],
  },
  {
    id: '328',
    name: 'Calefacción por suelo radiante',
    availableFor: [
      81,
    ],
  },
  {
    id: '330',
    name: 'mapas de la ciudad',
    availableFor: [
      0,
    ],
  },
  {
    id: '331',
    name: 'refrigerador lleno',
    availableFor: [
      0,
    ],
  },
  {
    id: '332',
    name: 'folletos',
    availableFor: [
      0,
    ],
  },
  {
    id: '334',
    name: 'Comida y bebida GRATIS',
    availableFor: [
      0,
    ],
  },
  {
    id: '336',
    name: 'Frutas GRATIS',
    availableFor: [
      0,
    ],
  },
  {
    id: '337',
    name: 'Ramos de flores GRATIS',
    availableFor: [
      0,
    ],
  },
  {
    id: '338',
    name: 'Leña GRATIS para la chimenea',
    availableFor: [
      0,
    ],
  },
  {
    id: '339',
    name: 'Acceso gratuito a Internet',
    availableFor: [
      0,
    ],
  },
  {
    id: '341',
    name: 'sábanas finas de algodón egipcio',
    availableFor: [
      0,
    ],
  },
  {
    id: '342',
    name: 'Selección de comidas y bebidas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '344',
    name: 'albornoz',
    availableFor: [
      81,
    ],
  },
  {
    id: '345',
    name: 'Panel LCD',
    availableFor: [
      0,
    ],
  },
  {
    id: '346',
    name: 'jardín',
    availableFor: [
      0,
    ],
  },
  {
    id: '347',
    name: 'Amplia selección de comidas, bebidas, vinos, cafés y tés de lujo.',
    availableFor: [
      0,
    ],
  },
  {
    id: '348',
    name: 'Frigorífico completamente abastecido con todos los alimentos y bebidas frescos imaginables.',
    availableFor: [
      0,
    ],
  },
  {
    id: '349',
    name: 'Chimenea de leña',
    availableFor: [
      0,
    ],
  },
  {
    id: '351',
    name: 'toallas de baño grandes de tela de rizo',
    availableFor: [
      0,
    ],
  },
  {
    id: '352',
    name: 'Servicios "Molton Brown"',
    availableFor: [
      0,
    ],
  },
  {
    id: '353',
    name: 'plantas de orquídeas',
    availableFor: [
      0,
    ],
  },
  {
    id: '354',
    name: 'Línea telefónica internacional GRATUITA',
    availableFor: [
      0,
    ],
  },
  {
    id: '355',
    name: 'En estancias semanales: servicio de limpieza, incluido lavado y planchado personal.',
    availableFor: [
      0,
    ],
  },
  {
    id: '359',
    name: 'Piano',
    availableFor: [
      0,
    ],
  },
  {
    id: '360',
    name: 'Secadora de ropa',
    availableFor: [
      0,
    ],
  },
  {
    id: '361',
    name: 'Campana extractora',
    availableFor: [
      0,
    ],
  },
  {
    id: '362',
    name: 'Cocina, sala y jardín compartidos con otros huéspedes.',
    availableFor: [
      0,
    ],
  },
  {
    id: '363',
    name: 'sillón',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '364',
    name: 'Hogar',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '365',
    name: 'Sauna',
    availableFor: [
      81,
    ],
  },
  {
    id: '368',
    name: 'Internet inalámbrico gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '371',
    name: 'Cine en casa',
    availableFor: [
      0,
    ],
  },
  {
    id: '372',
    name: 'Sala de estar / dormitorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '373',
    name: 'Cocina común',
    availableFor: [
      0,
    ],
  },
  {
    id: '374',
    name: 'Alquiler de teléfonos móviles',
    availableFor: [
      0,
    ],
  },
  {
    id: '375',
    name: 'Servicios de fax y fotocopias',
    availableFor: [
      0,
    ],
  },
  {
    id: '376',
    name: 'Cafetera',
    availableFor: [
      0,
    ],
  },
  {
    id: '379',
    name: 'Televisión por cable digital',
    availableFor: [
      0,
    ],
  },
  {
    id: '380',
    name: 'Té y café de cortesía',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '382',
    name: 'Sillas laterales',
    availableFor: [
      372,
    ],
  },
  {
    id: '384',
    name: 'Literas (individual en la parte superior, doble en la parte inferior)',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '385',
    name: 'Cunas, Tronas, Colchones (bajo petición)',
    availableFor: [
      0,
    ],
  },
  {
    id: '388',
    name: 'Desván',
    availableFor: [
      0,
    ],
  },
  {
    id: '389',
    name: 'Televisor de plasma de pantalla plana',
    availableFor: [
      0,
    ],
  },
  {
    id: '390',
    name: 'Máquina de café expreso',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '391',
    name: 'chico caliente',
    availableFor: [
      0,
    ],
  },
  {
    id: '395',
    name: 'Toallas',
    availableFor: [
      81,
    ],
  },
  {
    id: '396',
    name: 'dormitorio con alcoba',
    availableFor: [
      0,
    ],
  },
  {
    id: '397',
    name: 'mesa auxiliar',
    availableFor: [
      0,
    ],
  },
  {
    id: '404',
    name: 'Club de salud',
    availableFor: [
      0,
    ],
  },
  {
    id: '408',
    name: 'Parrilla de barbacoa',
    availableFor: [
      0,
    ],
  },
  {
    id: '409',
    name: 'baño en suite',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '412',
    name: 'Vestidor',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '413',
    name: 'Máquina para hacer hielo',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '414',
    name: 'Lavabo doble',
    availableFor: [
      0,
    ],
  },
  {
    id: '415',
    name: 'piscina de spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '417',
    name: 'Llamadas telefónicas de larga distancia gratuitas',
    availableFor: [
      0,
    ],
  },
  {
    id: '418',
    name: 'Sistema de videojuegos',
    availableFor: [
      249,
    ],
  },
  {
    id: '419',
    name: 'Sistema de videojuegos con juegos',
    availableFor: [
      0,
    ],
  },
  {
    id: '420',
    name: 'Uso gratuito de radios bidireccionales',
    availableFor: [
      0,
    ],
  },
  {
    id: '421',
    name: 'Sala de juegos',
    availableFor: [
      0,
    ],
  },
  {
    id: '422',
    name: 'Artículos de tocador iniciales',
    availableFor: [
      0,
    ],
  },
  {
    id: '426',
    name: 'Teléfono (llamadas entrantes y salientes locales)',
    availableFor: [
      0,
    ],
  },
  {
    id: '428',
    name: 'Sillón que se convierte en cama individual',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '429',
    name: 'Alquiler de ordenadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '432',
    name: 'Sillón cama',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '433',
    name: 'Internet inalámbrico de alta velocidad gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '434',
    name: 'inodoro/bidé',
    availableFor: [
      0,
    ],
  },
  {
    id: '435',
    name: 'Estufa de gas',
    availableFor: [
      0,
    ],
  },
  {
    id: '436',
    name: 'licuadora',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '437',
    name: 'Placas calientes',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '438',
    name: 'Acceso a Internet de alta velocidad',
    availableFor: [
      0,
    ],
  },
  {
    id: '439',
    name: 'comedor',
    availableFor: [
      101,
      249,
    ],
  },
  {
    id: '440',
    name: 'Par de camas individuales',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '444',
    name: 'Litera',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '445',
    name: 'Televisor de pantalla grande',
    availableFor: [
      0,
    ],
  },
  {
    id: '446',
    name: 'Jabón/champú/acondicionador de cortesía',
    availableFor: [
      81,
    ],
  },
  {
    id: '447',
    name: 'Barra de toalla calentada',
    availableFor: [
      53,
      81,
    ],
  },
  {
    id: '448',
    name: 'Limpieza semanal gratuita',
    availableFor: [
      0,
    ],
  },
  {
    id: '449',
    name: 'río',
    availableFor: [
      0,
    ],
  },
  {
    id: '450',
    name: 'calle',
    availableFor: [
      0,
    ],
  },
  {
    id: '451',
    name: 'Patio',
    availableFor: [
      0,
    ],
  },
  {
    id: '452',
    name: 'Cuadrado',
    availableFor: [
      0,
    ],
  },
  {
    id: '453',
    name: 'Sala anexa',
    availableFor: [
      0,
    ],
  },
  {
    id: '454',
    name: 'suelo de mármol',
    availableFor: [
      81,
    ],
  },
  {
    id: '455',
    name: '2 camas individuales en el salón',
    availableFor: [
      0,
    ],
  },
  {
    id: '456',
    name: 'sillas',
    availableFor: [
      0,
    ],
  },
  {
    id: '457',
    name: 'conservatorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '458',
    name: 'Ducha de hidromasaje',
    availableFor: [
      81,
    ],
  },
  {
    id: '459',
    name: 'suelo de parquet',
    availableFor: [
      0,
    ],
  },
  {
    id: '460',
    name: 'Sofá seccional',
    availableFor: [
      0,
    ],
  },
  {
    id: '461',
    name: 'Centro de negocios',
    availableFor: [
      0,
    ],
  },
  {
    id: '462',
    name: 'Electrodomésticos de última generación',
    availableFor: [
      0,
    ],
  },
  {
    id: '463',
    name: 'Encimeras de granito',
    availableFor: [
      0,
    ],
  },
  {
    id: '464',
    name: 'Gabinetes a medida',
    availableFor: [
      0,
    ],
  },
  {
    id: '465',
    name: 'Pista de tenis',
    availableFor: [
      0,
    ],
  },
  {
    id: '466',
    name: 'Guardería',
    availableFor: [
      0,
    ],
  },
  {
    id: '467',
    name: 'Gimnasio en residencia',
    availableFor: [
      0,
    ],
  },
  {
    id: '468',
    name: 'Televisor de pantalla plana LCD',
    availableFor: [
      0,
    ],
  },
  {
    id: '472',
    name: 'Servicio de compras personales',
    availableFor: [
      0,
    ],
  },
  {
    id: '473',
    name: 'Él',
    availableFor: [
      0,
    ],
  },
  {
    id: '476',
    name: 'Horizonte',
    availableFor: [
      0,
    ],
  },
  {
    id: '478',
    name: 'Centro',
    availableFor: [
      0,
    ],
  },
  {
    id: '479',
    name: 'Campo de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '480',
    name: 'Televisor de pantalla ancha',
    availableFor: [
      0,
    ],
  },
  {
    id: '481',
    name: 'Piso de madera',
    availableFor: [
      0,
    ],
  },
  {
    id: '482',
    name: 'Rasuradora eléctrica',
    availableFor: [
      81,
    ],
  },
  {
    id: '483',
    name: 'Ventanas de madera',
    availableFor: [
      0,
    ],
  },
  {
    id: '484',
    name: 'Libros guía',
    availableFor: [
      0,
    ],
  },
  {
    id: '485',
    name: 'Cama tamaño queen',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '490',
    name: 'Ventilador(es) a pedido',
    availableFor: [
      0,
    ],
  },
  {
    id: '491',
    name: 'Plancha/tabla de planchar bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '497',
    name: 'Instalaciones de almacenamiento de equipaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '499',
    name: 'percha',
    availableFor: [
      0,
    ],
  },
  {
    id: '500',
    name: 'estante para libros',
    availableFor: [
      372,
    ],
  },
  {
    id: '501',
    name: 'cama de día',
    availableFor: [
      0,
    ],
  },
  {
    id: '503',
    name: 'espejo',
    availableFor: [
      53,
      81,
      257,
      372,
      517,
    ],
  },
  {
    id: '504',
    name: 'Estacionamiento privado',
    availableFor: [
      0,
    ],
  },
  {
    id: '505',
    name: 'Guardarropa',
    availableFor: [
      0,
    ],
  },
  {
    id: '506',
    name: 'armarios',
    availableFor: [
      94,
      101,
      517,
    ],
  },
  {
    id: '507',
    name: 'Mesa',
    availableFor: [
      94,
      249,
      372,
      517,
    ],
  },
  {
    id: '508',
    name: 'Silla',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '509',
    name: 'Lámpara para leer',
    availableFor: [
      372,
    ],
  },
  {
    id: '510',
    name: 'Lago',
    availableFor: [
      0,
    ],
  },
  {
    id: '511',
    name: 'Mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '512',
    name: 'Océano',
    availableFor: [
      0,
    ],
  },
  {
    id: '513',
    name: 'Canal',
    availableFor: [
      0,
    ],
  },
  {
    id: '514',
    name: 'Entresuelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '515',
    name: 'Cama plegable',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '516',
    name: 'Ducha en suite',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '517',
    name: 'Dormitorio/sala de estar con rincón de cocina.',
    availableFor: [
      0,
    ],
  },
  {
    id: '589',
    name: 'Ropa de cama',
    availableFor: [
      257,
      372,
      517,
    ],
  },
  {
    id: '590',
    name: 'Hierro',
    availableFor: [
      0,
    ],
  },
  {
    id: '591',
    name: 'Tabla de planchar',
    availableFor: [
      0,
    ],
  },
  {
    id: '592',
    name: 'Teléfono',
    availableFor: [
      0,
    ],
  },
  {
    id: '593',
    name: 'Computadora',
    availableFor: [
      249,
    ],
  },
  {
    id: '594',
    name: 'Vista del canal',
    availableFor: [
      0,
    ],
  },
  {
    id: '595',
    name: 'Se admiten mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '596',
    name: 'Cuna gratuita en el apartamento.',
    availableFor: [
      0,
    ],
  },
  {
    id: '597',
    name: 'Cuna gratuita bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '598',
    name: 'Conserje',
    availableFor: [
      0,
    ],
  },
  {
    id: '599',
    name: 'Lavadora secadora',
    availableFor: [
      0,
    ],
  },
  {
    id: '600',
    name: 'Secadora',
    availableFor: [
      81,
    ],
  },
  {
    id: '601',
    name: 'Seguro',
    availableFor: [
      0,
    ],
  },
  {
    id: '602',
    name: 'Guarda esquís',
    availableFor: [
      0,
    ],
  },
  {
    id: '603',
    name: 'Vista a la montaña',
    availableFor: [
      0,
    ],
  },
  {
    id: '604',
    name: 'Vista al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '605',
    name: 'Sala de fitness',
    availableFor: [
      0,
    ],
  },
  {
    id: '606',
    name: 'Spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '607',
    name: 'Baño de vapor',
    availableFor: [
      0,
    ],
  },
  {
    id: '608',
    name: 'Servicio de habitaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '609',
    name: 'Vista de pendiente',
    availableFor: [
      0,
    ],
  },
  {
    id: '611',
    name: 'Jacuzzi (privado)',
    availableFor: [
      0,
    ],
  },
  {
    id: '612',
    name: 'Jacuzzi (común)',
    availableFor: [
      0,
    ],
  },
  {
    id: '613',
    name: 'Lavandería (Privada)',
    availableFor: [
      0,
    ],
  },
  {
    id: '614',
    name: 'Lavandería (Común)',
    availableFor: [
      0,
    ],
  },
  {
    id: '615',
    name: 'Minibar',
    availableFor: [
      0,
    ],
  },
  {
    id: '616',
    name: 'Portero',
    availableFor: [
      0,
    ],
  },
  {
    id: '617',
    name: 'Sala de desayunos',
    availableFor: [
      0,
    ],
  },
  {
    id: '618',
    name: 'Sala de reuniones',
    availableFor: [
      0,
    ],
  },
  {
    id: '619',
    name: 'Restaurante',
    availableFor: [
      0,
    ],
  },
  {
    id: '620',
    name: 'Bar',
    availableFor: [
      249,
    ],
  },
  {
    id: '621',
    name: 'Salón de belleza',
    availableFor: [
      0,
    ],
  },
  {
    id: '622',
    name: 'Área para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '623',
    name: 'Esquí de ida y vuelta',
    availableFor: [
      0,
    ],
  },
  {
    id: '624',
    name: 'Cama nido',
    availableFor: [
      257,
    ],
  },
  {
    id: '625',
    name: 'Jardín (Privado)',
    availableFor: [
      0,
    ],
  },
  {
    id: '626',
    name: 'Jardín (común)',
    availableFor: [
      0,
    ],
  },
  {
    id: '627',
    name: 'Recepción',
    availableFor: [
      0,
    ],
  },
  {
    id: '628',
    name: 'Servicio de lavandería',
    availableFor: [
      0,
    ],
  },
  {
    id: '629',
    name: 'Habitaciones contiguas',
    availableFor: [
      257,
    ],
  },
  {
    id: '630',
    name: 'Adaptadores de toma de corriente',
    availableFor: [
      0,
    ],
  },
  {
    id: '631',
    name: 'Mostrador de la aerolínea',
    availableFor: [
      0,
    ],
  },
  {
    id: '632',
    name: 'Plan de alimentación - Americano',
    availableFor: [
      0,
    ],
  },
  {
    id: '633',
    name: 'Cajero automático',
    availableFor: [
      0,
    ],
  },
  {
    id: '634',
    name: 'Equipos audiovisuales',
    availableFor: [
      0,
    ],
  },
  {
    id: '635',
    name: 'Servicios de niñera/cuidado infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '636',
    name: 'peluquería',
    availableFor: [
      0,
    ],
  },
  {
    id: '637',
    name: 'En la bahía',
    availableFor: [
      0,
    ],
  },
  {
    id: '638',
    name: 'Vista de la bahía',
    availableFor: [
      0,
    ],
  },
  {
    id: '639',
    name: 'Plan de comidas - Alojamiento y desayuno',
    availableFor: [
      0,
    ],
  },
  {
    id: '640',
    name: 'Dispositivo de escucha para bebés',
    availableFor: [
      0,
    ],
  },
  {
    id: '641',
    name: 'Vista de la playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '642',
    name: 'Playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '643',
    name: 'Peluquería/Salón de belleza',
    availableFor: [
      0,
    ],
  },
  {
    id: '644',
    name: 'Porteadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '645',
    name: 'Alquiler de bicicletas',
    availableFor: [
      0,
    ],
  },
  {
    id: '646',
    name: 'Pizarra',
    availableFor: [
      0,
    ],
  },
  {
    id: '647',
    name: 'Mesas de billar',
    availableFor: [
      249,
    ],
  },
  {
    id: '648',
    name: 'Paseo en barco',
    availableFor: [
      0,
    ],
  },
  {
    id: '649',
    name: 'Boutiques',
    availableFor: [
      0,
    ],
  },
  {
    id: '650',
    name: 'Bolos',
    availableFor: [
      0,
    ],
  },
  {
    id: '651',
    name: 'Plan de comidas - Bermudas',
    availableFor: [
      0,
    ],
  },
  {
    id: '652',
    name: 'Ascensor Braille',
    availableFor: [
      0,
    ],
  },
  {
    id: '653',
    name: 'Desayuno buffet',
    availableFor: [
      0,
    ],
  },
  {
    id: '654',
    name: 'Teléfono del baño',
    availableFor: [
      0,
    ],
  },
  {
    id: '655',
    name: 'Cama con dosel',
    availableFor: [
      0,
    ],
  },
  {
    id: '656',
    name: 'Mostrador de alquiler de coches',
    availableFor: [
      0,
    ],
  },
  {
    id: '657',
    name: 'Casino',
    availableFor: [
      0,
    ],
  },
  {
    id: '658',
    name: 'Habitación del castillo',
    availableFor: [
      0,
    ],
  },
  {
    id: '659',
    name: 'Plan de alimentación - Caribe',
    availableFor: [
      0,
    ],
  },
  {
    id: '660',
    name: 'Reproductor de CD',
    availableFor: [
      0,
    ],
  },
  {
    id: '661',
    name: 'Ventilador de techo',
    availableFor: [
      249,
      257,
    ],
  },
  {
    id: '662',
    name: 'Vista de la ciudad',
    availableFor: [
      0,
    ],
  },
  {
    id: '663',
    name: 'Instalaciones para conferencias',
    availableFor: [
      0,
    ],
  },
  {
    id: '664',
    name: 'Sala de conferencias',
    availableFor: [
      0,
    ],
  },
  {
    id: '665',
    name: 'Desayuno continental',
    availableFor: [
      0,
    ],
  },
  {
    id: '666',
    name: 'Cafetería',
    availableFor: [
      0,
    ],
  },
  {
    id: '667',
    name: 'Cafetera en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '668',
    name: 'Computadora en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '669',
    name: 'Mostrador de conserjería',
    availableFor: [
      0,
    ],
  },
  {
    id: '670',
    name: 'Habitaciones comunicadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '671',
    name: 'Plan de alimentación continental',
    availableFor: [
      0,
    ],
  },
  {
    id: '672',
    name: 'Servicio de Copiado',
    availableFor: [
      0,
    ],
  },
  {
    id: '673',
    name: 'Teléfono inalámbrico',
    availableFor: [
      0,
    ],
  },
  {
    id: '674',
    name: 'Cunas disponibles',
    availableFor: [
      257,
      372,
    ],
  },
  {
    id: '675',
    name: 'Coche de cortesía',
    availableFor: [
      0,
    ],
  },
  {
    id: '676',
    name: 'Centro de la ciudad',
    availableFor: [
      0,
    ],
  },
  {
    id: '677',
    name: 'Cambio de divisas',
    availableFor: [
      0,
    ],
  },
  {
    id: '678',
    name: 'Puerto de datos disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '679',
    name: 'Recepción abierta las 24 horas',
    availableFor: [
      0,
    ],
  },
  {
    id: '680',
    name: 'Guía de restaurantes',
    availableFor: [
      0,
    ],
  },
  {
    id: '681',
    name: 'Cena',
    availableFor: [
      0,
    ],
  },
  {
    id: '682',
    name: 'Habitaciones/instalaciones para discapacitados',
    availableFor: [
      0,
    ],
  },
  {
    id: '683',
    name: 'Disco',
    availableFor: [
      0,
    ],
  },
  {
    id: '684',
    name: 'Médico de guardia',
    availableFor: [
      0,
    ],
  },
  {
    id: '685',
    name: 'Farmacia',
    availableFor: [
      0,
    ],
  },
  {
    id: '686',
    name: 'Campo de prácticas',
    availableFor: [
      0,
    ],
  },
  {
    id: '687',
    name: 'Escritorio con lámpara',
    availableFor: [
      0,
    ],
  },
  {
    id: '688',
    name: 'Cerraduras electrónicas para puertas',
    availableFor: [
      0,
    ],
  },
  {
    id: '689',
    name: 'Ascensor',
    availableFor: [
      0,
    ],
  },
  {
    id: '690',
    name: 'Servicio de correo electrónico',
    availableFor: [
      0,
    ],
  },
  {
    id: '691',
    name: 'Entretenimiento en vivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '692',
    name: 'Plan de alimentación - Europeo',
    availableFor: [
      0,
    ],
  },
  {
    id: '693',
    name: 'Check-in exprés',
    availableFor: [
      0,
    ],
  },
  {
    id: '694',
    name: 'Escritorio ejecutivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '695',
    name: 'Pago exprés',
    availableFor: [
      0,
    ],
  },
  {
    id: '696',
    name: 'Nivel ejecutivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '697',
    name: 'Plan de comidas - FAP/Pensión completa',
    availableFor: [
      0,
    ],
  },
  {
    id: '698',
    name: 'Desayuno inglés completo',
    availableFor: [
      0,
    ],
  },
  {
    id: '699',
    name: 'Habitaciones ejecutivas femeninas',
    availableFor: [
      0,
    ],
  },
  {
    id: '700',
    name: 'Pesca',
    availableFor: [
      0,
    ],
  },
  {
    id: '701',
    name: 'Florista',
    availableFor: [
      0,
    ],
  },
  {
    id: '702',
    name: 'Estacionamiento gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '703',
    name: 'Llamadas telefónicas locales gratuitas',
    availableFor: [
      0,
    ],
  },
  {
    id: '704',
    name: 'Transporte gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '705',
    name: 'Vista al jardín',
    availableFor: [
      0,
    ],
  },
  {
    id: '706',
    name: 'Tienda de regalos',
    availableFor: [
      0,
    ],
  },
  {
    id: '707',
    name: 'Alquiler de juegos',
    availableFor: [
      0,
    ],
  },
  {
    id: '708',
    name: 'Golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '709',
    name: 'Vista del campo de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '710',
    name: 'Montar a caballo',
    availableFor: [
      0,
    ],
  },
  {
    id: '711',
    name: 'Pista para correr',
    availableFor: [
      0,
    ],
  },
  {
    id: '712',
    name: 'Residencia canina',
    availableFor: [
      0,
    ],
  },
  {
    id: '713',
    name: 'Actividades para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '714',
    name: 'Vista del lago',
    availableFor: [
      0,
    ],
  },
  {
    id: '715',
    name: 'Lavandería para huéspedes',
    availableFor: [
      0,
    ],
  },
  {
    id: '716',
    name: 'Almuerzo',
    availableFor: [
      0,
    ],
  },
  {
    id: '717',
    name: 'Régimen de comidas - MAP/Media pensión',
    availableFor: [
      0,
    ],
  },
  {
    id: '718',
    name: 'Masaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '719',
    name: 'Golf miniatura',
    availableFor: [
      0,
    ],
  },
  {
    id: '720',
    name: 'Películas en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '721',
    name: 'Instalaciones para reuniones',
    availableFor: [
      0,
    ],
  },
  {
    id: '722',
    name: 'Sala de reuniones',
    availableFor: [
      0,
    ],
  },
  {
    id: '723',
    name: 'Plurilingüe',
    availableFor: [
      0,
    ],
  },
  {
    id: '724',
    name: 'Guardería para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '725',
    name: 'Habitaciones/instalaciones para no fumadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '726',
    name: 'Club nocturno',
    availableFor: [
      0,
    ],
  },
  {
    id: '727',
    name: 'Periódico gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '728',
    name: 'Puesto de periódicos',
    availableFor: [
      0,
    ],
  },
  {
    id: '729',
    name: 'Vista al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '730',
    name: 'Retroproyector',
    availableFor: [
      0,
    ],
  },
  {
    id: '731',
    name: 'Parasailing',
    availableFor: [
      0,
    ],
  },
  {
    id: '732',
    name: 'Vista del parque',
    availableFor: [
      0,
    ],
  },
  {
    id: '733',
    name: 'No se permiten mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '734',
    name: 'Servicio telefónico',
    availableFor: [
      0,
    ],
  },
  {
    id: '735',
    name: 'Área de picnic/Mesas',
    availableFor: [
      0,
    ],
  },
  {
    id: '736',
    name: 'Patio de juegos',
    availableFor: [
      0,
    ],
  },
  {
    id: '737',
    name: 'Piscina climatizada',
    availableFor: [
      0,
    ],
  },
  {
    id: '738',
    name: 'Piscina cubierta',
    availableFor: [
      0,
    ],
  },
  {
    id: '739',
    name: 'Piscina para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '740',
    name: 'Piscina al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '741',
    name: 'Bar junto a la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '742',
    name: 'Proyector',
    availableFor: [
      0,
    ],
  },
  {
    id: '743',
    name: 'Calabaza',
    availableFor: [
      0,
    ],
  },
  {
    id: '744',
    name: 'Vista del río',
    availableFor: [
      0,
    ],
  },
  {
    id: '745',
    name: 'Rampa de acceso a edificios',
    availableFor: [
      0,
    ],
  },
  {
    id: '746',
    name: 'Servicio de habitaciones las 24 horas',
    availableFor: [
      0,
    ],
  },
  {
    id: '747',
    name: 'Depósito de seguridad',
    availableFor: [
      0,
    ],
  },
  {
    id: '748',
    name: 'Navegación',
    availableFor: [
      0,
    ],
  },
  {
    id: '749',
    name: 'Submarinismo',
    availableFor: [
      0,
    ],
  },
  {
    id: '750',
    name: 'Servicio de secretariado',
    availableFor: [
      0,
    ],
  },
  {
    id: '751',
    name: 'Seguridad las 24 horas',
    availableFor: [
      0,
    ],
  },
  {
    id: '752',
    name: 'Centro comercial',
    availableFor: [
      0,
    ],
  },
  {
    id: '753',
    name: 'Servicio de transporte gratuito al aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '754',
    name: 'Tiro al plato',
    availableFor: [
      0,
    ],
  },
  {
    id: '755',
    name: 'Esquiar',
    availableFor: [
      0,
    ],
  },
  {
    id: '756',
    name: 'Esquí de fondo',
    availableFor: [
      0,
    ],
  },
  {
    id: '757',
    name: 'Snorkel',
    availableFor: [
      0,
    ],
  },
  {
    id: '758',
    name: 'Snowboarding',
    availableFor: [
      0,
    ],
  },
  {
    id: '759',
    name: 'Centro de fitness o spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '760',
    name: 'Baño de vapor',
    availableFor: [
      0,
    ],
  },
  {
    id: '761',
    name: 'Télex',
    availableFor: [
      0,
    ],
  },
  {
    id: '762',
    name: 'Tenis de interior',
    availableFor: [
      0,
    ],
  },
  {
    id: '763',
    name: 'Tenis',
    availableFor: [
      0,
    ],
  },
  {
    id: '764',
    name: 'Tenis al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '765',
    name: 'Mostrador de información turística',
    availableFor: [
      0,
    ],
  },
  {
    id: '766',
    name: 'Servicio de traducción',
    availableFor: [
      0,
    ],
  },
  {
    id: '767',
    name: 'Servicios de lavandería',
    availableFor: [
      0,
    ],
  },
  {
    id: '768',
    name: 'Máquinas expendedoras',
    availableFor: [
      0,
    ],
  },
  {
    id: '769',
    name: 'Salas/Servicios VIP',
    availableFor: [
      0,
    ],
  },
  {
    id: '770',
    name: 'Voleibol',
    availableFor: [
      0,
    ],
  },
  {
    id: '771',
    name: 'Servicio de despertador',
    availableFor: [
      0,
    ],
  },
  {
    id: '772',
    name: 'Servicios de Bodas',
    availableFor: [
      0,
    ],
  },
  {
    id: '773',
    name: 'Windsurf',
    availableFor: [
      0,
    ],
  },
  {
    id: '774',
    name: 'Esquí acuático',
    availableFor: [
      0,
    ],
  },
  {
    id: '775',
    name: 'Barras de apoyo en el baño',
    availableFor: [
      0,
    ],
  },
  {
    id: '776',
    name: 'Habitaciones para huéspedes con calefacción',
    availableFor: [
      0,
    ],
  },
  {
    id: '777',
    name: 'Módem en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '778',
    name: 'Cama plegable',
    availableFor: [
      249,
      257,
      372,
    ],
  },
  {
    id: '779',
    name: 'Camas plegables',
    availableFor: [
      257,
    ],
  },
  {
    id: '780',
    name: 'Albornoces',
    availableFor: [
      0,
    ],
  },
  {
    id: '781',
    name: 'Detectores de humo',
    availableFor: [
      0,
    ],
  },
  {
    id: '782',
    name: 'Solárium',
    availableFor: [
      0,
    ],
  },
  {
    id: '783',
    name: 'Rociadores en las habitaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '784',
    name: 'Escritorio de teatro',
    availableFor: [
      0,
    ],
  },
  {
    id: '785',
    name: 'Control de temperatura',
    availableFor: [
      0,
    ],
  },
  {
    id: '786',
    name: 'Prensa de pantalones',
    availableFor: [
      0,
    ],
  },
  {
    id: '788',
    name: 'Base para iPod',
    availableFor: [
      249,
    ],
  },
  {
    id: '789',
    name: 'Calefacción',
    availableFor: [
      0,
    ],
  },
  {
    id: '790',
    name: 'de alta fidelidad',
    availableFor: [
      0,
    ],
  },
  {
    id: '791',
    name: 'Tienda libre de impuestos',
    availableFor: [
      0,
    ],
  },
  {
    id: '792',
    name: 'Internet inalámbrico',
    availableFor: [
      0,
    ],
  },
  {
    id: '793',
    name: 'Aparcamiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '794',
    name: 'Estacionamiento al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '795',
    name: 'Servicio de valet parking',
    availableFor: [
      0,
    ],
  },
  {
    id: '796',
    name: 'Alfombrillas de oración',
    availableFor: [
      0,
    ],
  },
  {
    id: '797',
    name: 'Canchas de racquetball',
    availableFor: [
      0,
    ],
  },
  {
    id: '798',
    name: 'Refrigerador',
    availableFor: [
      0,
    ],
  },
  {
    id: '799',
    name: 'De fumar',
    availableFor: [
      0,
    ],
  },
  {
    id: '800',
    name: 'Chef proporcionado',
    availableFor: [
      0,
    ],
  },
  {
    id: '801',
    name: 'Vista del puerto deportivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '802',
    name: 'Se permite fumar',
    availableFor: [
      0,
    ],
  },
  {
    id: '803',
    name: 'Estacionamiento gratuito en la calle',
    availableFor: [
      0,
    ],
  },
  {
    id: '804',
    name: 'Estacionamiento de pago en la calle',
    availableFor: [
      0,
    ],
  },
  {
    id: '805',
    name: 'Aparcamiento gratuito con garaje.',
    availableFor: [
      0,
    ],
  },
  {
    id: '806',
    name: 'Aparcamiento de pago con garaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '807',
    name: 'Internet por cable gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '808',
    name: 'Internet por cable de pago',
    availableFor: [
      0,
    ],
  },
  {
    id: '809',
    name: 'Internet inalámbrico de pago',
    availableFor: [
      0,
    ],
  },
  {
    id: '810',
    name: 'Cuna de pago bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '811',
    name: 'Petanca',
    availableFor: [
      0,
    ],
  },
  {
    id: '812',
    name: 'Pedir permiso para fumar',
    availableFor: [
      0,
    ],
  },
  {
    id: '813',
    name: 'Solicitar mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '814',
    name: 'Solicitar accesibilidad',
    availableFor: [
      0,
    ],
  },
  {
    id: '815',
    name: 'Piscina comunitaria',
    availableFor: [
      0,
    ],
  },
  {
    id: '816',
    name: 'Piscina privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '817',
    name: 'Mesa de ping-pong',
    availableFor: [
      249,
    ],
  },
  {
    id: '818',
    name: 'Posibilidad de reservar desayuno',
    availableFor: [
      0,
    ],
  },
  {
    id: '819',
    name: 'Limpieza de casa opcional',
    availableFor: [
      0,
    ],
  },
  {
    id: '820',
    name: 'Deportes - natación',
    availableFor: [
      0,
    ],
  },
  {
    id: '821',
    name: 'Hospital local',
    availableFor: [
      0,
    ],
  },
  {
    id: '822',
    name: 'Tiendas de comestibles locales',
    availableFor: [
      0,
    ],
  },
  {
    id: '823',
    name: 'Frente al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '824',
    name: 'Cerca del océano',
    availableFor: [
      0,
    ],
  },
  {
    id: '825',
    name: 'Cocina compartida',
    availableFor: [
      0,
    ],
  },
  {
    id: '826',
    name: 'Piscina compartida',
    availableFor: [
      0,
    ],
  },
  {
    id: '827',
    name: 'cochera',
    availableFor: [
      0,
    ],
  },
  {
    id: '828',
    name: 'Apto para familias y niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '829',
    name: 'Espacio de trabajo para computadora portátil',
    availableFor: [
      0,
    ],
  },
  {
    id: '830',
    name: 'Exprimidor',
    availableFor: [
      101,
    ],
  },
  {
    id: '831',
    name: 'Cámara de seguridad en la entrada',
    availableFor: [
      0,
    ],
  },
  {
    id: '832',
    name: 'MP3',
    availableFor: [
      0,
    ],
  },
  {
    id: '833',
    name: 'Cuna de bebe',
    availableFor: [
      0,
    ],
  },
  {
    id: '834',
    name: 'Gimnasia',
    availableFor: [
      0,
    ],
  },
  {
    id: '835',
    name: 'Paleta',
    availableFor: [
      0,
    ],
  },
  {
    id: '836',
    name: 'Acceso en taxi',
    availableFor: [
      0,
    ],
  },
  {
    id: '837',
    name: 'Acceso a la azotea',
    availableFor: [
      0,
    ],
  },
  {
    id: '838',
    name: 'Silla alta para bebé',
    availableFor: [
      0,
    ],
  },
  {
    id: '839',
    name: 'Cuna de pago',
    availableFor: [
      0,
    ],
  },
  {
    id: '840',
    name: 'Silla de bebé bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '841',
    name: 'Trona bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '842',
    name: 'Mascotas pagadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '843',
    name: 'Se admiten mascotas bajo petición.',
    availableFor: [
      0,
    ],
  },
  {
    id: '844',
    name: 'Paseante',
    availableFor: [
      0,
    ],
  },
  {
    id: '845',
    name: 'Cubiertos para bebés',
    availableFor: [
      0,
    ],
  },
  {
    id: '846',
    name: 'Conexión a Internet bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '847',
    name: 'Televisión por cable bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '848',
    name: 'Limpieza en seco bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '849',
    name: 'Llamadas internacionales gratuitas',
    availableFor: [
      0,
    ],
  },
  {
    id: '850',
    name: 'Tubo de lámpara',
    availableFor: [
      0,
    ],
  },
  {
    id: '851',
    name: 'televisión 3d',
    availableFor: [
      0,
    ],
  },
  {
    id: '852',
    name: 'Televisión inteligente',
    availableFor: [
      249,
      257,
    ],
  },
  {
    id: '853',
    name: 'Coche gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '854',
    name: 'Bicicleta gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '855',
    name: 'Se acepta pago con tarjeta de crédito',
    availableFor: [
      0,
    ],
  },
  {
    id: '856',
    name: 'No hay fiestas',
    availableFor: [
      0,
    ],
  },
  {
    id: '857',
    name: 'No se admiten niños menores de 4 años.',
    availableFor: [
      0,
    ],
  },
  {
    id: '858',
    name: 'No se admiten niños menores de 12 años.',
    availableFor: [
      0,
    ],
  },
  {
    id: '859',
    name: 'Cualquier persona menor de 25 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '860',
    name: 'Cualquier persona menor de 30 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '861',
    name: 'Cualquier persona menor de 35 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '862',
    name: 'Cualquier persona menor de 18 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '863',
    name: 'No se admiten reservas con más de 30 días de antelación',
    availableFor: [
      0,
    ],
  },
  {
    id: '864',
    name: 'Grupos menores de 18 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '865',
    name: 'Grupos menores de 25 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '866',
    name: 'Grupos menores de 30 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '867',
    name: 'Grupos menores de 35 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '868',
    name: 'Solo familias',
    availableFor: [
      0,
    ],
  },
  {
    id: '869',
    name: 'Cualquier persona menor de 40 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '870',
    name: 'No se admiten niños menores de 6 años.',
    availableFor: [
      0,
    ],
  },
  {
    id: '871',
    name: 'Grupos menores de 50 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '872',
    name: 'Grupos del mismo sexo menores de 30 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '873',
    name: 'Grupos menores de 45 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '874',
    name: 'Grupos del mismo sexo menores de 35 años',
    availableFor: [
      0,
    ],
  },
  {
    id: '875',
    name: 'Llegadas el domingo',
    availableFor: [
      0,
    ],
  },
  {
    id: '876',
    name: 'Solo familias o parejas',
    availableFor: [
      0,
    ],
  },
  {
    id: '877',
    name: 'Trona de bebe paga',
    availableFor: [
      0,
    ],
  },
  {
    id: '878',
    name: 'Silla de bebe paga',
    availableFor: [
      0,
    ],
  },
  {
    id: '879',
    name: 'Adaptador USB Wifi',
    availableFor: [
      0,
    ],
  },
  {
    id: '880',
    name: 'Agua embotellada',
    availableFor: [
      0,
    ],
  },
  {
    id: '881',
    name: 'Ventilación controlada centralmente',
    availableFor: [
      0,
    ],
  },
  {
    id: '882',
    name: 'Adaptadores eléctricos disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '883',
    name: 'Habitaciones hipoalergénicas',
    availableFor: [
      0,
    ],
  },
  {
    id: '884',
    name: 'navegador de internet televisión',
    availableFor: [
      0,
    ],
  },
  {
    id: '885',
    name: 'Convertidores de potencia',
    availableFor: [
      0,
    ],
  },
  {
    id: '886',
    name: 'Kit de costura',
    availableFor: [
      0,
    ],
  },
  {
    id: '887',
    name: 'Impresora',
    availableFor: [
      0,
    ],
  },
  {
    id: '888',
    name: 'Zapatillas',
    availableFor: [
      0,
    ],
  },
  {
    id: '889',
    name: 'Sistema de sonido',
    availableFor: [
      249,
    ],
  },
  {
    id: '890',
    name: 'Ventanas insonorizadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '891',
    name: 'Escala de ponderación',
    availableFor: [
      0,
    ],
  },
  {
    id: '892',
    name: 'Correr por la casa',
    availableFor: [
      0,
    ],
  },
  {
    id: '893',
    name: 'Ventana\t',
    availableFor: [
      0,
    ],
  },
  {
    id: '894',
    name: 'Veranda',
    availableFor: [
      0,
    ],
  },
  {
    id: '895',
    name: 'Patio',
    availableFor: [
      0,
    ],
  },
  {
    id: '896',
    name: 'Áreas públicas con aire acondicionado',
    availableFor: [
      0,
    ],
  },
  {
    id: '897',
    name: 'Centro de deportes acuáticos',
    availableFor: [
      0,
    ],
  },
  {
    id: '898',
    name: 'Servicio de mensajería',
    availableFor: [
      0,
    ],
  },
  {
    id: '899',
    name: 'Guardería',
    availableFor: [
      0,
    ],
  },
  {
    id: '900',
    name: 'Servicio de limpieza',
    availableFor: [
      0,
    ],
  },
  {
    id: '901',
    name: 'Entretenimiento y recreación',
    availableFor: [
      0,
    ],
  },
  {
    id: '902',
    name: 'Buceo',
    availableFor: [
      0,
    ],
  },
  {
    id: '903',
    name: 'Peluquería',
    availableFor: [
      0,
    ],
  },
  {
    id: '904',
    name: 'Tiendas de hoteles',
    availableFor: [
      0,
    ],
  },
  {
    id: '905',
    name: 'Salto de isla en isla',
    availableFor: [
      0,
    ],
  },
  {
    id: '906',
    name: 'Moto acuática',
    availableFor: [
      0,
    ],
  },
  {
    id: '907',
    name: 'Los niños comen gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '908',
    name: 'Salida tardía disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '909',
    name: 'Servicio de limusina disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '910',
    name: 'Guardia de seguridad',
    availableFor: [
      0,
    ],
  },
  {
    id: '911',
    name: 'Limpiador de zapatos',
    availableFor: [
      0,
    ],
  },
  {
    id: '912',
    name: 'Maquina para lustrar zapatos',
    availableFor: [
      0,
    ],
  },
  {
    id: '913',
    name: 'Servicio de transporte',
    availableFor: [
      0,
    ],
  },
  {
    id: '914',
    name: 'Apto para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '915',
    name: 'Servicio de venta de entradas',
    availableFor: [
      0,
    ],
  },
  {
    id: '916',
    name: 'Servicio de cobertura',
    availableFor: [
      0,
    ],
  },
  {
    id: '917',
    name: 'Paraguas',
    availableFor: [
      0,
    ],
  },
  {
    id: '918',
    name: 'Amenidades de bienvenida',
    availableFor: [
      0,
    ],
  },
  {
    id: '919',
    name: 'Vista campestre o rural',
    availableFor: [
      0,
    ],
  },
  {
    id: '920',
    name: 'Vista hacia el interior',
    availableFor: [
      0,
    ],
  },
  {
    id: '921',
    name: 'Vista panorámica',
    availableFor: [
      0,
    ],
  },
  {
    id: '922',
    name: 'Vista de la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '923',
    name: 'Vista del pueblo',
    availableFor: [
      0,
    ],
  },
  {
    id: '924',
    name: 'Vista del valle',
    availableFor: [
      0,
    ],
  },
  {
    id: '925',
    name: 'Vista de la ciudad',
    availableFor: [
      0,
    ],
  },
  {
    id: '926',
    name: 'Vista rara',
    availableFor: [
      0,
    ],
  },
  {
    id: '927',
    name: 'Vista del lago',
    availableFor: [
      0,
    ],
  },
  {
    id: '928',
    name: 'Piscina exterior climatizada',
    availableFor: [
      0,
    ],
  },
  {
    id: '929',
    name: 'No se permiten bebés',
    availableFor: [
      0,
    ],
  },
  {
    id: '930',
    name: 'Apto para familias',
    availableFor: [
      0,
    ],
  },
  {
    id: '931',
    name: 'Se necesita coche',
    availableFor: [
      0,
    ],
  },
  {
    id: '932',
    name: 'Coche recomendado',
    availableFor: [
      0,
    ],
  },
  {
    id: '933',
    name: 'No es necesario el coche',
    availableFor: [
      0,
    ],
  },
  {
    id: '934',
    name: 'Romántico',
    availableFor: [
      0,
    ],
  },
  {
    id: '935',
    name: 'Lujo',
    availableFor: [
      0,
    ],
  },
  {
    id: '936',
    name: 'Presupuesto',
    availableFor: [
      0,
    ],
  },
  {
    id: '937',
    name: 'Vista del agua',
    availableFor: [
      0,
    ],
  },
  {
    id: '938',
    name: 'Silla de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '939',
    name: 'Cama francesa',
    availableFor: [
      94,
      249,
      257,
      372,
      517,
    ],
  },
  {
    id: '940',
    name: 'Papel higiénico',
    availableFor: [
      0,
    ],
  },
  {
    id: '941',
    name: 'Jabón',
    availableFor: [
      0,
    ],
  },
  {
    id: '942',
    name: 'No se permiten niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '943',
    name: 'Detector de monóxido de carbono',
    availableFor: [
      0,
    ],
  },
  {
    id: '944',
    name: 'Piscina privada climatizada',
    availableFor: [
      0,
    ],
  },
  {
    id: '945',
    name: 'Futbolín de mesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '946',
    name: 'tablero de dardos',
    availableFor: [
      0,
    ],
  },
  {
    id: '947',
    name: 'Mesa de hockey de aire',
    availableFor: [
      0,
    ],
  },
  {
    id: '948',
    name: 'Aro de baloncesto',
    availableFor: [
      0,
    ],
  },
  {
    id: '949',
    name: 'Piscina - Valla de seguridad',
    availableFor: [
      0,
    ],
  },
  {
    id: '950',
    name: 'Calentador de patio',
    availableFor: [
      0,
    ],
  },
  {
    id: '951',
    name: 'Tumbonas',
    availableFor: [
      0,
    ],
  },
  {
    id: '952',
    name: 'Iluminación exterior',
    availableFor: [
      0,
    ],
  },
  {
    id: '953',
    name: 'Apto para personas mayores',
    availableFor: [
      0,
    ],
  },
  {
    id: '954',
    name: 'Pacífico',
    availableFor: [
      0,
    ],
  },
  {
    id: '955',
    name: 'Extintor de incendios',
    availableFor: [
      0,
    ],
  },
  {
    id: '956',
    name: 'Ducha a ras de suelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '957',
    name: 'Cama Tribunk',
    availableFor: [
      0,
    ],
  },
  {
    id: '958',
    name: 'el minimarket es un sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '959',
    name: 'Sólo adultos',
    availableFor: [
      0,
    ],
  },
  {
    id: '960',
    name: 'Cambio de toallas bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '961',
    name: 'Mejora de habitación disponible según disponibilidad',
    availableFor: [
      0,
    ],
  },
  {
    id: '962',
    name: 'Mejora de habitación garantizada',
    availableFor: [
      0,
    ],
  },
  {
    id: '963',
    name: 'Mejora de habitación gratuita disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '964',
    name: 'Crédito de resort incluido',
    availableFor: [
      0,
    ],
  },
  {
    id: '965',
    name: 'obsequio de bienvenida a la llegada',
    availableFor: [
      0,
    ],
  },
  {
    id: '966',
    name: 'productos de limpieza ecológicos',
    availableFor: [
      0,
    ],
  },
  {
    id: '967',
    name: 'reciclaje de aguas grises',
    availableFor: [
      0,
    ],
  },
  {
    id: '968',
    name: 'energía renovable - geotermia',
    availableFor: [
      0,
    ],
  },
  {
    id: '969',
    name: 'energía renovable - solar',
    availableFor: [
      0,
    ],
  },
  {
    id: '970',
    name: 'energía renovable - eólica',
    availableFor: [
      0,
    ],
  },
  {
    id: '971',
    name: 'Elementos esenciales (toallas, almohadas, ropa de cama, jabón, papel higiénico)',
    availableFor: [
      0,
    ],
  },
  {
    id: '972',
    name: 'perchas',
    availableFor: [
      0,
    ],
  },
  {
    id: '973',
    name: 'agua caliente',
    availableFor: [
      0,
    ],
  },
  {
    id: '974',
    name: 'arandela',
    availableFor: [
      0,
    ],
  },
  {
    id: '976',
    name: 'libre de alergias',
    availableFor: [
      0,
    ],
  },
  {
    id: '977',
    name: 'toallas de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '978',
    name: 'productos de limpieza',
    availableFor: [
      0,
    ],
  },
  {
    id: '979',
    name: 'tapones para los oídos',
    availableFor: [
      0,
    ],
  },
  {
    id: '980',
    name: 'casilleros',
    availableFor: [
      0,
    ],
  },
  {
    id: '981',
    name: 'mosquitero',
    availableFor: [
      0,
    ],
  },
  {
    id: '984',
    name: 'cortina privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '985',
    name: 'Control del clima de la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '986',
    name: 'guía de restaurantes',
    availableFor: [
      0,
    ],
  },
  {
    id: '987',
    name: 'Sistema de calefacción/refrigeración autocontrolado',
    availableFor: [
      0,
    ],
  },
  {
    id: '988',
    name: 'armario separado',
    availableFor: [
      0,
    ],
  },
  {
    id: '989',
    name: 'sombrillas',
    availableFor: [
      0,
    ],
  },
  {
    id: '990',
    name: 'Botes de basura',
    availableFor: [
      0,
    ],
  },
  {
    id: '991',
    name: 'estación de carga de vehículos eléctricos',
    availableFor: [
      0,
    ],
  },
  {
    id: '992',
    name: 'servicio de planchado',
    availableFor: [
      0,
    ],
  },
  {
    id: '993',
    name: 'Cesta para mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '994',
    name: 'cuencos para mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '995',
    name: 'No se permiten plásticos de un solo uso',
    availableFor: [
      0,
    ],
  },
  {
    id: '996',
    name: 'No se permiten artículos de tocador de un solo uso',
    availableFor: [
      0,
    ],
  },
  {
    id: '997',
    name: 'Elegibilidad para obtener puntos de fidelidad del hotel',
    availableFor: [
      0,
    ],
  },
  {
    id: '998',
    name: 'frente a la playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '999',
    name: 'jardín o patio trasero',
    availableFor: [
      0,
    ],
  },
  {
    id: '1000',
    name: 'casa de un solo nivel',
    availableFor: [
      0,
    ],
  },
  {
    id: '1002',
    name: 'zona designada para fumadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '1003',
    name: 'separado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1004',
    name: 'Área de estar de Engawa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1005',
    name: 'pozo de fuego',
    availableFor: [
      0,
    ],
  },
  {
    id: '1006',
    name: 'Balcón amueblado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1007',
    name: 'lanai amueblado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1008',
    name: 'patio amueblado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1009',
    name: 'jardín japonés',
    availableFor: [
      0,
    ],
  },
  {
    id: '1010',
    name: 'pared de plantas vivas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1011',
    name: 'huerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1012',
    name: 'vista del golfo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1013',
    name: 'vista del puerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1014',
    name: 'vista de la colina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1015',
    name: 'vista a la laguna',
    availableFor: [
      0,
    ],
  },
  {
    id: '1016',
    name: 'terraza',
    availableFor: [
      0,
    ],
  },
  {
    id: '1017',
    name: 'vista de monumento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1018',
    name: 'puerto pequeño',
    availableFor: [
      0,
    ],
  },
  {
    id: '1019',
    name: 'vistas múltiples',
    availableFor: [
      0,
    ],
  },
  {
    id: '1020',
    name: 'Sin vista',
    availableFor: [
      0,
    ],
  },
  {
    id: '1021',
    name: 'baño al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1022',
    name: 'Zona de comedor al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1023',
    name: 'Chimenea al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1024',
    name: 'muebles de exterior',
    availableFor: [
      0,
    ],
  },
  {
    id: '1025',
    name: 'bote',
    availableFor: [
      0,
    ],
  },
  {
    id: '1026',
    name: 'Área de entretenimiento al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1027',
    name: 'Equipo para deportes acuáticos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1028',
    name: 'vista parcial del lago',
    availableFor: [
      0,
    ],
  },
  {
    id: '1029',
    name: 'vista parcial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1030',
    name: 'porche',
    availableFor: [
      0,
    ],
  },
  {
    id: '1031',
    name: 'Piso privado en bloque de pisos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1032',
    name: 'vista tranquila de la calle',
    availableFor: [
      0,
    ],
  },
  {
    id: '1033',
    name: 'vista del complejo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1034',
    name: 'casa adosada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1035',
    name: 'terraza solárium',
    availableFor: [
      0,
    ],
  },
  {
    id: '1036',
    name: 'vista del desierto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1037',
    name: 'vista parcial al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1038',
    name: 'vista parcial al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1039',
    name: 'vista del viñedo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1040',
    name: '1 safari por noche',
    availableFor: [
      0,
    ],
  },
  {
    id: '1041',
    name: '2 safaris por noche',
    availableFor: [
      0,
    ],
  },
  {
    id: '1042',
    name: 'bingo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1043',
    name: 'juegos de mesa/rompecabezas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1044',
    name: 'libros',
    availableFor: [
      0,
    ],
  },
  {
    id: '1045',
    name: 'crédito de casino',
    availableFor: [
      0,
    ],
  },
  {
    id: '1046',
    name: 'casino cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1047',
    name: 'conciertos o actuaciones en directo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1048',
    name: 'Clase de cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1049',
    name: 'Entradas para parques de Disney',
    availableFor: [
      0,
    ],
  },
  {
    id: '1050',
    name: 'personal de entretenimiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1051',
    name: 'entretenimiento nocturno',
    availableFor: [
      0,
    ],
  },
  {
    id: '1052',
    name: 'Clases de fitness',
    availableFor: [
      0,
    ],
  },
  {
    id: '1053',
    name: 'Instalaciones de fitness',
    availableFor: [
      0,
    ],
  },
  {
    id: '1054',
    name: 'Instalaciones de fitness cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1055',
    name: 'vestuarios de spa y fitness',
    availableFor: [
      0,
    ],
  },
  {
    id: '1056',
    name: 'baño de pies',
    availableFor: [
      0,
    ],
  },
  {
    id: '1057',
    name: 'pases gratuitos para parques acuáticos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1058',
    name: 'hora feliz',
    availableFor: [
      0,
    ],
  },
  {
    id: '1059',
    name: 'aguas termales cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1060',
    name: 'aguas termales en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1061',
    name: 'Baño público interior sin fuentes minerales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1062',
    name: 'piscina infinita',
    availableFor: [
      0,
    ],
  },
  {
    id: '1063',
    name: 'bañera de hidromasaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '1064',
    name: 'karaoke',
    availableFor: [
      0,
    ],
  },
  {
    id: '1065',
    name: 'juego de ocio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1066',
    name: 'biblioteca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1067',
    name: 'transmisión de eventos deportivos en vivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1068',
    name: 'silla de masaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '1069',
    name: 'juego de partidos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1070',
    name: 'aguas termales minerales en interiores',
    availableFor: [
      0,
    ],
  },
  {
    id: '1071',
    name: 'aguas termales minerales al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1072',
    name: 'noches de cine',
    availableFor: [
      0,
    ],
  },
  {
    id: '1073',
    name: 'Baño público al aire libre, sin fuentes minerales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1074',
    name: 'Entrenador personal disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1075',
    name: 'piscina de inmersión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1076',
    name: 'bar en la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1077',
    name: 'cubierta de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1078',
    name: 'toallas de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1079',
    name: 'piscina con vista',
    availableFor: [
      0,
    ],
  },
  {
    id: '1080',
    name: 'piscina sobre el suelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1081',
    name: 'pool cabanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1083',
    name: 'piscina en el suelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1084',
    name: 'tumbonas de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1085',
    name: 'sombrillas de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1086',
    name: 'cascada de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1087',
    name: 'zona de playa privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1088',
    name: 'Recorridos por los pubs',
    availableFor: [
      0,
    ],
  },
  {
    id: '1089',
    name: 'baño público',
    availableFor: [
      0,
    ],
  },
  {
    id: '1090',
    name: 'piscina en la azotea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1091',
    name: 'Materiales de arte',
    availableFor: [
      0,
    ],
  },
  {
    id: '1092',
    name: 'instrumentos musicales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1093',
    name: 'Aguas termales minerales privadas - interior',
    availableFor: [
      0,
    ],
  },
  {
    id: '1094',
    name: 'Aguas termales minerales privadas - al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1095',
    name: 'piscina de agua salada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1096',
    name: 'piscina de poca profundidad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1097',
    name: 'juego de tragamonedas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1098',
    name: 'comedia stand up',
    availableFor: [
      0,
    ],
  },
  {
    id: '1099',
    name: 'bar en la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1100',
    name: 'juguetes de piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1101',
    name: 'telescopio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1102',
    name: 'galerías de arte temporales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1103',
    name: 'Noches de cenas temáticas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1104',
    name: 'Tour o clase sobre la cultura local',
    availableFor: [
      0,
    ],
  },
  {
    id: '1105',
    name: 'trampolín',
    availableFor: [
      0,
    ],
  },
  {
    id: '1106',
    name: 'recorridos a pie',
    availableFor: [
      0,
    ],
  },
  {
    id: '1107',
    name: 'tobogán de agua',
    availableFor: [
      0,
    ],
  },
  {
    id: '1108',
    name: 'remolino',
    availableFor: [
      0,
    ],
  },
  {
    id: '1109',
    name: 'lagar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1110',
    name: 'Visitas privadas a bodegas en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1111',
    name: 'Visitas públicas a la bodega en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1112',
    name: 'Eventos de lanzamiento de bodegas en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1113',
    name: 'Sala de degustación de la bodega en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1114',
    name: 'Visitas a bodegas cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1115',
    name: 'Bodega y viñedo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1116',
    name: 'clases de yoga',
    availableFor: [
      0,
    ],
  },
  {
    id: '1117',
    name: 'crédito de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1118',
    name: 'compras',
    availableFor: [
      0,
    ],
  },
  {
    id: '1119',
    name: 'patinaje sobre hielo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1120',
    name: 'patinaje sobre hielo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1121',
    name: 'aerobic',
    availableFor: [
      0,
    ],
  },
  {
    id: '1122',
    name: 'Tiro con arco en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1123',
    name: 'parques temáticos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1124',
    name: 'equipo de bádminton',
    availableFor: [
      0,
    ],
  },
  {
    id: '1125',
    name: 'Cerca de playa privada con acceso directo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1126',
    name: 'Cerca de playa pública con acceso directo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1127',
    name: 'cabañas de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1128',
    name: 'voleibol de playa en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1129',
    name: 'Yoga en la playa en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1130',
    name: 'Excursiones en bicicleta',
    availableFor: [
      0,
    ],
  },
  {
    id: '1131',
    name: 'andar en bicicleta por la carretera cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1132',
    name: 'Observación de aves en las cercanías',
    availableFor: [
      0,
    ],
  },
  {
    id: '1133',
    name: 'Observación de aves en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1134',
    name: 'canoa en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1135',
    name: 'barco en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1136',
    name: 'barco inflable en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1137',
    name: 'como en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1138',
    name: 'barco a pedales en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1139',
    name: 'velero en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1140',
    name: 'Paseos en barco por las inmediaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1141',
    name: 'Excursiones en barco en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1142',
    name: 'Exploración de cuevas cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1143',
    name: 'Exploración de cuevas en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1144',
    name: 'ciclismo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1145',
    name: 'ecoturismo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1146',
    name: 'ecotours en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1147',
    name: 'clases de agricultura',
    availableFor: [
      0,
    ],
  },
  {
    id: '1148',
    name: 'mesa de limpieza de pescado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1149',
    name: 'safari cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1150',
    name: 'safari en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1151',
    name: 'Paseo de caza o de vida silvestre cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1152',
    name: 'Paseo de caza o de vida silvestre en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1153',
    name: 'Observación de juegos cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1154',
    name: 'Observación de juegos en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1155',
    name: 'carrito de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1156',
    name: 'carrito de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1157',
    name: 'Casa club de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1158',
    name: 'equipo de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1159',
    name: 'campo de golf sin campo de prácticas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1160',
    name: 'tienda de golf profesional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1161',
    name: 'Excursiones en helicóptero o avión en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1162',
    name: 'senderismo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1163',
    name: 'Senderismo y ciclismo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1164',
    name: 'Senderismo y ciclismo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1165',
    name: 'cazando cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1166',
    name: 'caza en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1167',
    name: 'Kayak cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1168',
    name: 'Kayak en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1169',
    name: 'río lento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1170',
    name: 'barco a motor cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1171',
    name: 'barco a motor en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1172',
    name: 'escalada de montaña cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1173',
    name: 'moto acuática cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1174',
    name: 'Motos acuáticas personales en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1175',
    name: 'rafting cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1176',
    name: 'Rafting en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1177',
    name: 'Formación de equipos en circuito de cuerdas en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1178',
    name: 'Remo o piragüismo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1179',
    name: 'safari cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1180',
    name: 'safari en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1181',
    name: 'buceo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1182',
    name: 'paracaidismo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1183',
    name: 'paracaidismo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1184',
    name: 'hacer snorkel cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1185',
    name: 'Surf o boogieboarding cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1186',
    name: 'Surf o boogieboarding en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1187',
    name: 'equipo de tenis',
    availableFor: [
      0,
    ],
  },
  {
    id: '1188',
    name: 'Clases de tenis en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1189',
    name: 'pasarela hacia el agua',
    availableFor: [
      0,
    ],
  },
  {
    id: '1191',
    name: 'parque acuático',
    availableFor: [
      0,
    ],
  },
  {
    id: '1192',
    name: 'Esquí acuático cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1193',
    name: 'Tubing de agua cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1194',
    name: 'Windsurf cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1195',
    name: 'Tirolina cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1196',
    name: 'Tirolina en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1197',
    name: 'Tipo de arena de playa: arena',
    availableFor: [
      0,
    ],
  },
  {
    id: '1198',
    name: 'Tipo de arena de playa: arena negra',
    availableFor: [
      0,
    ],
  },
  {
    id: '1199',
    name: 'Tipo de arena de la playa: arena blanca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1201',
    name: 'inodoro de altura accesible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1202',
    name: 'acceso al baño sin escalones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1203',
    name: 'Puerta de baño ancha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1204',
    name: 'dormitorio con acceso libre de escalones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1205',
    name: 'Puerta ancha del dormitorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1206',
    name: 'polipasto de techo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1207',
    name: 'Espacio común, paso libre, acceso',
    availableFor: [
      0,
    ],
  },
  {
    id: '1208',
    name: 'Espacio común puerta amplia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1209',
    name: 'plaza de aparcamiento para discapacitados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1210',
    name: 'bancada perfiladora eléctrica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1211',
    name: 'botiquín de primeros auxilios',
    availableFor: [
      0,
    ],
  },
  {
    id: '1212',
    name: 'cabezal de ducha de mano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1213',
    name: 'Inicio Paso Acceso libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1214',
    name: 'puerta ancha de casa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1215',
    name: 'cerradura en la puerta del dormitorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1216',
    name: 'polipasto móvil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1217',
    name: 'Camino a la entrada iluminado por la noche',
    availableFor: [
      0,
    ],
  },
  {
    id: '1218',
    name: 'medio polipasto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1219',
    name: 'entrada privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1220',
    name: 'ducha rodante',
    availableFor: [
      0,
    ],
  },
  {
    id: '1221',
    name: 'silla de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1222',
    name: 'bañera con banco de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1223',
    name: 'Amplio espacio libre hasta la cama',
    availableFor: [
      0,
    ],
  },
  {
    id: '1224',
    name: 'Amplio espacio libre para ducharse y bañarse.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1225',
    name: 'Amplio espacio libre en el pasillo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1226',
    name: 'baño accesible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1227',
    name: 'Estacionamiento accesible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1228',
    name: 'camino de viaje accesible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1229',
    name: 'baño adaptado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1230',
    name: 'guía auditiva',
    availableFor: [
      0,
    ],
  },
  {
    id: '1231',
    name: 'Cordón de emergencia para el baño',
    availableFor: [
      0,
    ],
  },
  {
    id: '1232',
    name: 'barandillas de cama',
    availableFor: [
      0,
    ],
  },
  {
    id: '1233',
    name: 'Cerraduras de gabinete',
    availableFor: [
      0,
    ],
  },
  {
    id: '1234',
    name: 'protectores de esquinas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1235',
    name: 'acceso con tarjeta llave electrónica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1237',
    name: 'Toda la propiedad en planta baja',
    availableFor: [
      0,
    ],
  },
  {
    id: '1238',
    name: 'Equipo para sordos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1239',
    name: 'caja de seguridad para computadora portátil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1240',
    name: 'lavabo de baño bajo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1241',
    name: 'contacto de emergencia médica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1242',
    name: 'acceso con llaves de metal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1243',
    name: 'contacto de emergencia de la policía',
    availableFor: [
      0,
    ],
  },
  {
    id: '1244',
    name: 'puerta de seguridad de la piscina de la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1245',
    name: 'Caja fuerte lo suficientemente grande para acomodar una computadora portátil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1246',
    name: 'sistema de seguridad de la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1248',
    name: 'Al piso superior solo se puede acceder por escaleras.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1249',
    name: 'Ayudas visuales: braille',
    availableFor: [
      0,
    ],
  },
  {
    id: '1250',
    name: 'Ayudas visuales: señales táctiles.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1251',
    name: 'protectores de ventanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1252',
    name: 'Seguridad: advertencia en el balcón',
    availableFor: [
      0,
    ],
  },
  {
    id: '1253',
    name: 'Seguridad: propiedad completamente vallada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1254',
    name: 'capilla o santuario',
    availableFor: [
      0,
    ],
  },
  {
    id: '1255',
    name: 'habitaciones familiares',
    availableFor: [
      0,
    ],
  },
  {
    id: '1256',
    name: 'Suite nupcial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1257',
    name: 'habitaciones insonorizadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1258',
    name: '2 baños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1259',
    name: 'Próximo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1260',
    name: 'baño privado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1261',
    name: 'baño compartido',
    availableFor: [
      0,
    ],
  },
  {
    id: '1262',
    name: 'cocina completa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1263',
    name: 'vestidor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1264',
    name: 'Habitación(es) comunicadas disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1265',
    name: 'escritorio grande',
    availableFor: [
      0,
    ],
  },
  {
    id: '1266',
    name: 'zona de estar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1267',
    name: 'Escritorio con toma de corriente',
    availableFor: [
      0,
    ],
  },
  {
    id: '1268',
    name: 'armarios en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1269',
    name: 'Silla provista de escritorio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1270',
    name: 'asientos del comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1271',
    name: 'múltiples armarios',
    availableFor: [
      0,
    ],
  },
  {
    id: '1272',
    name: 'Zona de estar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1273',
    name: 'piso alfombrado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1274',
    name: 'Área de comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1276',
    name: 'tendedero',
    availableFor: [
      0,
    ],
  },
  {
    id: '1277',
    name: 'Decoración de la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1278',
    name: 'Limpieza antes de pagar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1282',
    name: 'servicio de limpieza diario',
    availableFor: [
      0,
    ],
  },
  {
    id: '1283',
    name: 'Servicio de limpieza una vez por estancia.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1284',
    name: 'Servicio de limpieza solo los fines de semana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1285',
    name: 'Servicio de limpieza solo los días laborables',
    availableFor: [
      0,
    ],
  },
  {
    id: '1286',
    name: 'servicio de habitaciones limitado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1287',
    name: 'servicio de habitaciones genérico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1288',
    name: 'Buffet 2x1',
    availableFor: [
      0,
    ],
  },
  {
    id: '1289',
    name: 'Todas las comidas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1290',
    name: 'todo incluido',
    availableFor: [
      0,
    ],
  },
  {
    id: '1291',
    name: 'bar de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1292',
    name: 'Desayuno para 1',
    availableFor: [
      0,
    ],
  },
  {
    id: '1293',
    name: 'Desayuno para 2',
    availableFor: [
      0,
    ],
  },
  {
    id: '1294',
    name: 'Desayuno en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1295',
    name: 'galletas de chocolate',
    availableFor: [
      0,
    ],
  },
  {
    id: '1296',
    name: 'cafetera y tetera',
    availableFor: [
      0,
    ],
  },
  {
    id: '1297',
    name: 'Recepción con vino de cortesía',
    availableFor: [
      0,
    ],
  },
  {
    id: '1298',
    name: 'Desayuno continental para 2',
    availableFor: [
      0,
    ],
  },
  {
    id: '1299',
    name: 'cena en pareja',
    availableFor: [
      0,
    ],
  },
  {
    id: '1300',
    name: 'bebidas y entremeses',
    availableFor: [
      0,
    ],
  },
  {
    id: '1301',
    name: 'crédito de comida y bebida',
    availableFor: [
      0,
    ],
  },
  {
    id: '1302',
    name: 'Desayuno gratuito para 1 adulto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1303',
    name: 'cena gratis para 1 adulto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1304',
    name: 'bebida de bienvenida gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '1305',
    name: 'pensión completa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1306',
    name: 'tienda de comestibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1307',
    name: 'media pensión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1308',
    name: 'mini refrigerador',
    availableFor: [
      0,
    ],
  },
  {
    id: '1309',
    name: 'Cafetería en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1310',
    name: 'Almuerzos para llevar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1311',
    name: 'refrigerador con máquina de hielo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1312',
    name: 'Solo servicio de comidas en la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1313',
    name: 'bar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1314',
    name: 'Menús dietéticos especiales bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '1315',
    name: 'servicio de té',
    availableFor: [
      0,
    ],
  },
  {
    id: '1319',
    name: 'copas de vino',
    availableFor: [
      0,
    ],
  },
  {
    id: '1320',
    name: 'vino champán',
    availableFor: [
      0,
    ],
  },
  {
    id: '1321',
    name: 'Baño de bebé',
    availableFor: [
      0,
    ],
  },
  {
    id: '1322',
    name: 'mesa cambiadora',
    availableFor: [
      0,
    ],
  },
  {
    id: '1323',
    name: 'Libros y juguetes para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1324',
    name: 'vajilla infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1325',
    name: 'protectores de chimenea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1326',
    name: 'cubiertas de enchufes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1327',
    name: 'cortinas que oscurecen la habitación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1328',
    name: 'puertas de escalera',
    availableFor: [
      0,
    ],
  },
  {
    id: '1329',
    name: 'protectores de esquinas de mesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1330',
    name: 'puertas de seguridad para bebés',
    availableFor: [
      0,
    ],
  },
  {
    id: '1331',
    name: 'juegos de mesa rompecabezas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1332',
    name: 'Libro DVD Biblioteca de música para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1333',
    name: 'cadenas de televisión para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1334',
    name: 'juegos de niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1335',
    name: 'juguetes de entretenimiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1336',
    name: 'Área de juegos interior',
    availableFor: [
      0,
    ],
  },
  {
    id: '1337',
    name: 'Juguetes para bebés y niños pequeños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1338',
    name: 'comidas para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1339',
    name: 'Equipo de juegos al aire libre para niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1340',
    name: 'vajilla infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1341',
    name: 'cuidado de niños',
    availableFor: [
      0,
    ],
  },
  {
    id: '1342',
    name: 'pantuflas de tamaño infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1344',
    name: 'cama doble extra grande',
    availableFor: [
      0,
    ],
  },
  {
    id: '1345',
    name: 'Camas extra largas de 2 metros',
    availableFor: [
      0,
    ],
  },
  {
    id: '1346',
    name: 'Estera de futón',
    availableFor: [
      0,
    ],
  },
  {
    id: '1347',
    name: 'Gran doble',
    availableFor: [
      0,
    ],
  },
  {
    id: '1348',
    name: 'Edredón de plumas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1349',
    name: 'Cama hipoalergénica disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1350',
    name: 'cama de altura accesible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1352',
    name: 'Conceptos básicos de cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1353',
    name: 'Suministros de cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1354',
    name: 'cocina comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1355',
    name: 'batería de cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1356',
    name: 'Microondas compartido, no en la habitación.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1357',
    name: 'refrigerador microondas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1358',
    name: 'molinillo de café',
    availableFor: [
      0,
    ],
  },
  {
    id: '1359',
    name: 'plancha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1360',
    name: 'olla de langosta',
    availableFor: [
      0,
    ],
  },
  {
    id: '1361',
    name: 'mezclador',
    availableFor: [
      0,
    ],
  },
  {
    id: '1362',
    name: 'Especias',
    availableFor: [
      0,
    ],
  },
  {
    id: '1363',
    name: 'estufa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1364',
    name: 'máquina para hacer gofres',
    availableFor: [
      0,
    ],
  },
  {
    id: '1366',
    name: 'biblioteca de videos de entretenimiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1367',
    name: 'películas / vídeos gratis',
    availableFor: [
      0,
    ],
  },
  {
    id: '1368',
    name: 'Películas de pago por visión en la televisión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1369',
    name: 'lista de canales de películas disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1370',
    name: 'CNN disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1371',
    name: 'reproductor de bluray',
    availableFor: [
      0,
    ],
  },
  {
    id: '1372',
    name: 'canales de pago por visión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1373',
    name: 'películas de estreno',
    availableFor: [
      0,
    ],
  },
  {
    id: '1374',
    name: 'canales de tv premium',
    availableFor: [
      0,
    ],
  },
  {
    id: '1375',
    name: 'servicio de streaming como netflix',
    availableFor: [
      0,
    ],
  },
  {
    id: '1376',
    name: 'Vídeo a la carta',
    availableFor: [
      0,
    ],
  },
  {
    id: '1377',
    name: 'Incluye una película gratis en la habitación.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1378',
    name: 'computadora portátil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1379',
    name: 'Teléfono TDD Teléfono de texto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1380',
    name: 'teléfono multilinea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1381',
    name: 'Teléfono para personas con discapacidad auditiva',
    availableFor: [
      0,
    ],
  },
  {
    id: '1382',
    name: 'teléfono de dos líneas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1383',
    name: 'radio satelital',
    availableFor: [
      0,
    ],
  },
  {
    id: '1384',
    name: 'teléfonos móviles/celulares disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1385',
    name: 'iPad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1386',
    name: 'consola de juegos xbox 360',
    availableFor: [
      0,
    ],
  },
  {
    id: '1387',
    name: 'consola de juegos playstation 2',
    availableFor: [
      0,
    ],
  },
  {
    id: '1388',
    name: 'consola de juegos playstation 3',
    availableFor: [
      0,
    ],
  },
  {
    id: '1389',
    name: 'consola de juegos nintendo wii',
    availableFor: [
      0,
    ],
  },
  {
    id: '1390',
    name: 'consola de juegos xbox one',
    availableFor: [
      0,
    ],
  },
  {
    id: '1391',
    name: 'consola de juegos wii u',
    availableFor: [
      0,
    ],
  },
  {
    id: '1392',
    name: 'consola de juegos playstation 4',
    availableFor: [
      0,
    ],
  },
  {
    id: '1393',
    name: 'teléfono inteligente disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1394',
    name: 'cargador de vehículo eléctrico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1395',
    name: 'asistencia turística',
    availableFor: [
      0,
    ],
  },
  {
    id: '1396',
    name: 'sábanas italianas frette',
    availableFor: [
      0,
    ],
  },
  {
    id: '1397',
    name: 'colchón premium',
    availableFor: [
      0,
    ],
  },
  {
    id: '1398',
    name: 'acceso al salón de negocios',
    availableFor: [
      0,
    ],
  },
  {
    id: '1399',
    name: 'llamadas gratuitas disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1400',
    name: 'servicio de champán',
    availableFor: [
      0,
    ],
  },
  {
    id: '1401',
    name: 'tiendas de campaña de lujo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1402',
    name: 'Acceso gratuito al gimnasio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1403',
    name: 'Acceso gratuito al centro de negocios',
    availableFor: [
      0,
    ],
  },
  {
    id: '1404',
    name: 'Acceso a línea VIP a discotecas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1406',
    name: 'Ropa de baño',
    availableFor: [
      0,
    ],
  },
  {
    id: '1407',
    name: 'bañera con chorros de agua',
    availableFor: [
      0,
    ],
  },
  {
    id: '1408',
    name: 'solo bañera',
    availableFor: [
      0,
    ],
  },
  {
    id: '1409',
    name: 'bañera griega',
    availableFor: [
      0,
    ],
  },
  {
    id: '1410',
    name: 'solo ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1411',
    name: 'bañera y jacuzzi separados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1412',
    name: 'bañera de gran tamaño',
    availableFor: [
      0,
    ],
  },
  {
    id: '1413',
    name: 'bañera y ducha separadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1414',
    name: 'Área de baño separada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1415',
    name: 'baño de spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1417',
    name: 'baño de invitados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1418',
    name: 'baño adicional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1419',
    name: 'baño adicional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1420',
    name: 'cepillo de dientes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1421',
    name: 'gorro de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1422',
    name: 'baño compartido con lavabo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1424',
    name: 'Baño exterior parcialmente abierto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1425',
    name: 'Albornoces de tamaño infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1426',
    name: 'bidé electrónico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1427',
    name: 'artículos de tocador de diseño',
    availableFor: [
      0,
    ],
  },
  {
    id: '1428',
    name: 'puede sandalia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1429',
    name: 'bañera de hidromasaje privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1430',
    name: 'bañera de inmersión profunda',
    availableFor: [
      0,
    ],
  },
  {
    id: '1431',
    name: 'bañera de agua de manantial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1432',
    name: 'Bañera para bebé con ducha tipo lluvia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1433',
    name: 'toallas de papel',
    availableFor: [
      0,
    ],
  },
  {
    id: '1434',
    name: 'Secador de pelo bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '1435',
    name: 'detergente para ropa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1436',
    name: 'ropa de cama',
    availableFor: [
      0,
    ],
  },
  {
    id: '1437',
    name: 'almohadas y mantas adicionales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1438',
    name: 'mantas eléctricas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1439',
    name: 'pijama',
    availableFor: [
      0,
    ],
  },
  {
    id: '1440',
    name: 'yukata',
    availableFor: [
      0,
    ],
  },
  {
    id: '1441',
    name: 'enchufe cerca de la cama',
    availableFor: [
      0,
    ],
  },
  {
    id: '1442',
    name: 'almohadas de plumas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1443',
    name: 'almohadas sin plumas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1444',
    name: 'almohadas hipoalergénicas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1445',
    name: 'Menú de almohadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1446',
    name: 'Privilegios de telesquí opcionales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1447',
    name: 'snowboard en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1448',
    name: 'trineo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1449',
    name: 'trineo en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1450',
    name: 'tubing de nieve cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1451',
    name: 'Tubing en la nieve en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1452',
    name: 'Motos de nieve cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1453',
    name: 'Motos de nieve en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1454',
    name: 'raquetas de nieve cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1455',
    name: 'Raquetas de nieve en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1456',
    name: 'zona de esquí cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1457',
    name: 'telesillas cercanos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1458',
    name: 'pistas de esquí cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1459',
    name: 'Clases de esquí cercanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1460',
    name: 'Clases de esquí en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1461',
    name: 'privilegios de telesquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1462',
    name: 'pases de esquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1463',
    name: 'Alquiler de esquís cercanos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1464',
    name: 'Alquiler de esquís en el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1465',
    name: 'equipo para deportes de nieve',
    availableFor: [
      0,
    ],
  },
  {
    id: '1466',
    name: 'cuesta abajo cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1467',
    name: 'descenso en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1468',
    name: 'Escuela de esquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1469',
    name: 'Alquiler de material de esquí en el establecimiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1470',
    name: 'vendedor de pases de esquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1471',
    name: 'servicio de transporte para esquiadores',
    availableFor: [
      0,
    ],
  },
  {
    id: '1472',
    name: 'acceso a las pistas de esquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1473',
    name: 'Alquiler gratuito de forfait para el telesquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1475',
    name: 'servicio de transporte para esquiadores cercano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1476',
    name: 'Spa piscina comunitaria',
    availableFor: [
      0,
    ],
  },
  {
    id: '1477',
    name: 'bañera de hidromasaje spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1478',
    name: 'Spa con piscina privada',
    availableFor: [
      0,
    ],
  },
  {
    id: '1479',
    name: 'aromaterapia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1480',
    name: 'Ayurveda',
    availableFor: [
      0,
    ],
  },
  {
    id: '1481',
    name: 'exfoliantes corporales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1483',
    name: 'envolturas corporales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1485',
    name: 'hidroterapia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1486',
    name: 'Salas de spa para parejas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1487',
    name: 'Área de tratamiento al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1488',
    name: 'talasoterapia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1489',
    name: 'baño turco',
    availableFor: [
      0,
    ],
  },
  {
    id: '1490',
    name: 'envoltura de desintoxicación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1491',
    name: 'reflexología',
    availableFor: [
      0,
    ],
  },
  {
    id: '1492',
    name: 'aguas termales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1493',
    name: 'sauna en spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1494',
    name: 'baño de barro en el spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1495',
    name: 'bañera de hidromasaje en spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1496',
    name: 'baño de vapor en spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1497',
    name: 'manicura pedicura',
    availableFor: [
      0,
    ],
  },
  {
    id: '1498',
    name: 'Pilates en el sitio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1499',
    name: 'Masaje - se requiere reserva previa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1500',
    name: 'masaje de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1501',
    name: 'masaje de tejido profundo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1502',
    name: 'masaje con piedras calientes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1503',
    name: 'masaje prenatal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1504',
    name: 'masaje deportivo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1505',
    name: 'masaje sueco',
    availableFor: [
      0,
    ],
  },
  {
    id: '1506',
    name: 'masaje tailandés',
    availableFor: [
      0,
    ],
  },
  {
    id: '1507',
    name: 'Instalaciones de spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1508',
    name: 'Zona de relajación del salón spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1509',
    name: 'Paquetes de spa y bienestar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1510',
    name: 'masaje de espalda',
    availableFor: [
      0,
    ],
  },
  {
    id: '1511',
    name: 'masaje de cuello',
    availableFor: [
      0,
    ],
  },
  {
    id: '1512',
    name: 'Masaje de pies',
    availableFor: [
      0,
    ],
  },
  {
    id: '1513',
    name: 'masaje en pareja',
    availableFor: [
      0,
    ],
  },
  {
    id: '1514',
    name: 'masaje de cabeza',
    availableFor: [
      0,
    ],
  },
  {
    id: '1515',
    name: 'masaje de manos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1516',
    name: 'masaje de cuerpo completo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1517',
    name: 'peluquera esteticista',
    availableFor: [
      0,
    ],
  },
  {
    id: '1518',
    name: 'tratamientos faciales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1519',
    name: 'servicios de depilación',
    availableFor: [
      0,
    ],
  },
  {
    id: '1520',
    name: 'servicios de maquillaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '1521',
    name: 'tratamientos para el cabello',
    availableFor: [
      0,
    ],
  },
  {
    id: '1522',
    name: 'manicura',
    availableFor: [
      0,
    ],
  },
  {
    id: '1523',
    name: 'pedicure',
    availableFor: [
      0,
    ],
  },
  {
    id: '1524',
    name: 'corte de pelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1525',
    name: 'coloración del cabello',
    availableFor: [
      0,
    ],
  },
  {
    id: '1526',
    name: 'Peinado del cabello',
    availableFor: [
      0,
    ],
  },
  {
    id: '1527',
    name: 'tratamientos corporales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1528',
    name: 'exfoliante corporal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1529',
    name: 'Terapia de luz',
    availableFor: [
      0,
    ],
  },
  {
    id: '1530',
    name: 'crédito de spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1538',
    name: 'Alojamiento Otros servicios Coche disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1539',
    name: 'estación de carga para coches eléctricos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1540',
    name: 'Alquiler de scooter o ciclomotor cerca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1541',
    name: 'Alquiler de scooter o ciclomotor en el establecimiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1542',
    name: 'Recargo por servicio de transporte al aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1543',
    name: 'billetes de transporte público',
    availableFor: [
      0,
    ],
  },
  {
    id: '1544',
    name: 'check in y check out privados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1545',
    name: 'Traslado gratuito de ida y vuelta al aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1546',
    name: 'Estacionamiento con espacios limitados disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1547',
    name: 'Recorrido en segway por las inmediaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1548',
    name: 'Recorrido en segway por el lugar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1549',
    name: 'Traslado gratuito al aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1550',
    name: 'Servicio de traslado al aeropuerto (con suplemento)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1551',
    name: 'Recogida gratuita en el aeropuerto',
    availableFor: [
      0,
    ],
  },
  {
    id: '1552',
    name: 'Servicio de recogida en el aeropuerto (con suplemento)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1553',
    name: 'Traslado al aeropuerto ida y vuelta gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '1554',
    name: 'El tiempo de traslado al aeropuerto (ida y vuelta) tiene un coste adicional.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1555',
    name: 'tiempo de transporte al aeropuerto genérico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1556',
    name: 'El servicio de transporte al aeropuerto está disponible las 24 horas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1557',
    name: 'tiempo de transporte al aeropuerto sin transporte',
    availableFor: [
      0,
    ],
  },
  {
    id: '1558',
    name: 'servicio de transporte al aeropuerto - horario limitado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1559',
    name: 'Servicio de transporte al aeropuerto (horario disponible bajo petición)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1560',
    name: 'Tarifa de transporte al aeropuerto para adultos disponible durante los horarios programados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1561',
    name: 'Tarifa de transporte al aeropuerto. Los adultos deben comunicarse con el establecimiento antes de su llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1562',
    name: 'Tarifa de transporte al aeropuerto (importe por adulto)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1563',
    name: 'servicio de transporte gratuito por la zona',
    availableFor: [
      0,
    ],
  },
  {
    id: '1564',
    name: 'Recargo por distancia de transporte de área',
    availableFor: [
      0,
    ],
  },
  {
    id: '1565',
    name: 'distancia de transporte de área genérica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1566',
    name: 'transporte a la playa gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '1567',
    name: 'Recargo por servicio de transporte a la playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1568',
    name: 'servicio de transporte gratuito al centro comercial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1569',
    name: 'Recargo por el servicio de transporte al centro comercial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1570',
    name: 'servicio de transporte al casino gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '1571',
    name: 'Recargo por el servicio de transporte al casino',
    availableFor: [
      0,
    ],
  },
  {
    id: '1572',
    name: 'transporte gratuito al parque temático',
    availableFor: [
      0,
    ],
  },
  {
    id: '1573',
    name: 'Recargo por el servicio de transporte al parque temático',
    availableFor: [
      0,
    ],
  },
  {
    id: '1574',
    name: 'servicio de transporte gratuito a la terminal de cruceros',
    availableFor: [
      0,
    ],
  },
  {
    id: '1575',
    name: 'Recargo por el servicio de transporte a la terminal de cruceros',
    availableFor: [
      0,
    ],
  },
  {
    id: '1576',
    name: 'Recargo por el servicio de transporte a la terminal del ferry',
    availableFor: [
      0,
    ],
  },
  {
    id: '1577',
    name: 'Servicio de transporte a la terminal del ferry. Póngase en contacto con el establecimiento antes de su llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1578',
    name: 'servicio de transporte gratuito a la terminal del ferry',
    availableFor: [
      0,
    ],
  },
  {
    id: '1579',
    name: 'Recargo por servicio de recogida en la estación de tren',
    availableFor: [
      0,
    ],
  },
  {
    id: '1580',
    name: 'Servicio de recogida en la estación de tren. Póngase en contacto con el establecimiento antes de su llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1581',
    name: 'Servicio de recogida en la estación de tren gratuito',
    availableFor: [
      0,
    ],
  },
  {
    id: '1582',
    name: 'Recargo por tiempo de transporte en tren',
    availableFor: [
      0,
    ],
  },
  {
    id: '1583',
    name: 'El servicio de transporte en tren está disponible las 24 horas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1584',
    name: 'Tiempo de transporte en tren disponible durante los horarios programados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1585',
    name: 'Tiempo de transporte en tren disponible en horario limitado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1586',
    name: 'Horario de transporte en tren disponible bajo petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '1587',
    name: 'Debe comunicarse con la propiedad para obtener la recogida y devolución gratuita.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1588',
    name: 'Debe comunicarse con la propiedad para conocer el recargo por recogida y devolución.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1589',
    name: 'Debe comunicarse con la propiedad para recoger el vehículo a su llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1590',
    name: 'Debe comunicarse con la propiedad para la recogida 24 horas antes de la llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1592',
    name: 'Debe comunicarse con la propiedad para la recogida 48 horas antes de la llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1593',
    name: 'Debe comunicarse con la propiedad para la recogida 72 horas antes de la llegada.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1594',
    name: 'Restaurante, snack-bar o delicatessen',
    availableFor: [
      0,
    ],
  },
  {
    id: '1595',
    name: 'Menú infantil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1596',
    name: 'cocina afgana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1597',
    name: 'cocina africana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1598',
    name: 'cocina americana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1599',
    name: 'cocina argentina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1600',
    name: 'cocina asiática',
    availableFor: [
      0,
    ],
  },
  {
    id: '1601',
    name: 'cocina barbacoa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1602',
    name: 'cocina vasca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1603',
    name: 'cocina belga',
    availableFor: [
      0,
    ],
  },
  {
    id: '1604',
    name: 'cocina brasileña',
    availableFor: [
      0,
    ],
  },
  {
    id: '1605',
    name: 'cocina británica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1606',
    name: 'cocina birmana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1607',
    name: 'cocina cajún o criolla',
    availableFor: [
      0,
    ],
  },
  {
    id: '1608',
    name: 'cocina californiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1609',
    name: 'cocina camboyana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1610',
    name: 'cocina caribeña',
    availableFor: [
      0,
    ],
  },
  {
    id: '1611',
    name: 'cocina china',
    availableFor: [
      0,
    ],
  },
  {
    id: '1612',
    name: 'cocina cubana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1613',
    name: 'cocina dim sum',
    availableFor: [
      0,
    ],
  },
  {
    id: '1614',
    name: 'cocina de europa del este',
    availableFor: [
      0,
    ],
  },
  {
    id: '1615',
    name: 'cocina eritrea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1616',
    name: 'cocina etíope',
    availableFor: [
      0,
    ],
  },
  {
    id: '1617',
    name: 'cocina filipina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1618',
    name: 'cocina pescado y patatas fritas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1619',
    name: 'cocina fondue',
    availableFor: [
      0,
    ],
  },
  {
    id: '1620',
    name: 'cocina francesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1621',
    name: 'cocina fusión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1622',
    name: 'cocina alemana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1623',
    name: 'cocina griega',
    availableFor: [
      0,
    ],
  },
  {
    id: '1624',
    name: 'cocina halal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1625',
    name: 'cocina hawaiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1626',
    name: 'cocina del himalaya',
    availableFor: [
      0,
    ],
  },
  {
    id: '1627',
    name: 'cocina húngara',
    availableFor: [
      0,
    ],
  },
  {
    id: '1628',
    name: 'cocina indfivesiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1629',
    name: 'cocina india',
    availableFor: [
      0,
    ],
  },
  {
    id: '1630',
    name: 'cocina internacional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1631',
    name: 'cocina irlandesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1632',
    name: 'cocina italiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1633',
    name: 'cocina japonesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1634',
    name: 'cocina coreana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1635',
    name: 'cocina kosher',
    availableFor: [
      0,
    ],
  },
  {
    id: '1636',
    name: 'cocina laosiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1637',
    name: 'cocina viva o cruda',
    availableFor: [
      0,
    ],
  },
  {
    id: '1638',
    name: 'cocina local',
    availableFor: [
      0,
    ],
  },
  {
    id: '1639',
    name: 'cocina local e internacional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1640',
    name: 'cocina malaya',
    availableFor: [
      0,
    ],
  },
  {
    id: '1641',
    name: 'cocina mediterranea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1642',
    name: 'cocina mexicana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1643',
    name: 'cocina de oriente medio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1644',
    name: 'cocina moderna europea',
    availableFor: [
      0,
    ],
  },
  {
    id: '1645',
    name: 'cocina mongol',
    availableFor: [
      0,
    ],
  },
  {
    id: '1646',
    name: 'cocina marroquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1647',
    name: 'cocina pakistaní',
    availableFor: [
      0,
    ],
  },
  {
    id: '1648',
    name: 'cocina panasiática',
    availableFor: [
      0,
    ],
  },
  {
    id: '1649',
    name: 'cocina persa iraní',
    availableFor: [
      0,
    ],
  },
  {
    id: '1650',
    name: 'cocina peruana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1651',
    name: 'cocina pizza',
    availableFor: [
      0,
    ],
  },
  {
    id: '1652',
    name: 'cocina polaca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1653',
    name: 'cocina portuguesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1654',
    name: 'cocina regional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1655',
    name: 'cocina rusa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1656',
    name: 'cocina escandinava',
    availableFor: [
      0,
    ],
  },
  {
    id: '1657',
    name: 'cocina mariscos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1658',
    name: 'cocina singapurense',
    availableFor: [
      0,
    ],
  },
  {
    id: '1659',
    name: 'cocina comida del alma',
    availableFor: [
      0,
    ],
  },
  {
    id: '1660',
    name: 'cocina sureña',
    availableFor: [
      0,
    ],
  },
  {
    id: '1661',
    name: 'cocina española',
    availableFor: [
      0,
    ],
  },
  {
    id: '1662',
    name: 'cocina sushi',
    availableFor: [
      0,
    ],
  },
  {
    id: '1663',
    name: 'cocina taiwanesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1664',
    name: 'cocina tex mex',
    availableFor: [
      0,
    ],
  },
  {
    id: '1665',
    name: 'cocina tailandesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1666',
    name: 'cocina turca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1667',
    name: 'cocina ucraniana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1668',
    name: 'cocina vegana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1669',
    name: 'cocina vegetariana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1670',
    name: 'cocina vietnamita',
    availableFor: [
      0,
    ],
  },
  {
    id: '1671',
    name: 'tiene barra',
    availableFor: [
      0,
    ],
  },
  {
    id: '1672',
    name: 'Ubicación al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1673',
    name: 'Ubicación frente a la playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1674',
    name: 'Ubicación vista al jardín',
    availableFor: [
      0,
    ],
  },
  {
    id: '1675',
    name: 'Ubicación vista al campo de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1676',
    name: 'Ubicación vista al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1677',
    name: 'Ubicación vista a la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1678',
    name: 'Ubicación junto a la piscina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1680',
    name: 'desalmuerzo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1683',
    name: 'comida ligera',
    availableFor: [
      0,
    ],
  },
  {
    id: '1685',
    name: 'Se requieren reservaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1686',
    name: 'pequeño restaurante',
    availableFor: [
      0,
    ],
  },
  {
    id: '1687',
    name: 'cervecería',
    availableFor: [
      0,
    ],
  },
  {
    id: '1688',
    name: 'bufé',
    availableFor: [
      0,
    ],
  },
  {
    id: '1689',
    name: 'cafetería',
    availableFor: [
      0,
    ],
  },
  {
    id: '1691',
    name: 'Tiendas Delicatessen',
    availableFor: [
      0,
    ],
  },
  {
    id: '1692',
    name: 'comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1693',
    name: 'restaurante familiar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1694',
    name: 'Restaurante de lujo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1695',
    name: 'pub',
    availableFor: [
      0,
    ],
  },
  {
    id: '1697',
    name: 'mariscos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1698',
    name: 'restaurante de carnes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1699',
    name: 'barra de sushi',
    availableFor: [
      0,
    ],
  },
  {
    id: '1700',
    name: 'tapas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1701',
    name: 'tema',
    availableFor: [
      0,
    ],
  },
  {
    id: '1702',
    name: 'Tema de propiedad de deporte de aventura',
    availableFor: [
      0,
    ],
  },
  {
    id: '1703',
    name: 'tema de propiedad de playa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1704',
    name: 'tema de propiedad comercial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1705',
    name: 'tema de la propiedad del casino',
    availableFor: [
      0,
    ],
  },
  {
    id: '1706',
    name: 'tema de propiedad familiar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1707',
    name: 'tema de propiedad de golf',
    availableFor: [
      0,
    ],
  },
  {
    id: '1708',
    name: 'tema de propiedad de aguas termales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1709',
    name: 'tema de propiedad de esquí',
    availableFor: [
      0,
    ],
  },
  {
    id: '1710',
    name: 'tema de propiedad de spa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1711',
    name: 'tema de propiedad romántica',
    availableFor: [
      0,
    ],
  },
  {
    id: '1712',
    name: 'tema de propiedad de bodega',
    availableFor: [
      0,
    ],
  },
  {
    id: '1713',
    name: 'tema de propiedad de la ciudad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1714',
    name: 'tema de propiedad natural',
    availableFor: [
      0,
    ],
  },
  {
    id: '1715',
    name: 'tema de propiedad comercial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1716',
    name: 'diseño de propiedad barroco',
    availableFor: [
      0,
    ],
  },
  {
    id: '1717',
    name: 'Diseño de propiedades bellas artes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1718',
    name: 'diseño de propiedad belle époque',
    availableFor: [
      0,
    ],
  },
  {
    id: '1719',
    name: 'Diseño de propiedad colonial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1720',
    name: 'diseño de propiedad deco',
    availableFor: [
      0,
    ],
  },
  {
    id: '1721',
    name: 'diseño de propiedad eduardiano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1722',
    name: 'diseño de propiedad georgiana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1723',
    name: 'Diseño de propiedades mediterraneo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1724',
    name: 'Diseño de propiedad tradicional',
    availableFor: [
      0,
    ],
  },
  {
    id: '1725',
    name: 'Diseño de propiedad Tudor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1726',
    name: 'Diseño de propiedad toscano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1727',
    name: 'Diseño de propiedad victoriano',
    availableFor: [
      0,
    ],
  },
  {
    id: '1728',
    name: 'Se requiere reserva de estacionamiento',
    availableFor: [
      0,
    ],
  },
  {
    id: '1729',
    name: 'Cobertura de Internet en áreas públicas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1730',
    name: 'Cobertura de Internet en todas las habitaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1731',
    name: 'Cobertura de internet en algunas habitaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1732',
    name: 'Centro de negocios con cobertura de Internet',
    availableFor: [
      0,
    ],
  },
  {
    id: '1733',
    name: 'Se permiten fiestas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1734',
    name: 'Vista del patio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1735',
    name: 'No se permite fumar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1736',
    name: 'Apto para bebés',
    availableFor: [
      0,
    ],
  },
  {
    id: '1741',
    name: 'Se requiere identificación del huésped al momento del check-in',
    availableFor: [
      0,
    ],
  },
  {
    id: '1742',
    name: 'No se permiten invitados con tatuajes.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1743',
    name: 'Zona peatonal únicamente',
    availableFor: [
      0,
    ],
  },
  {
    id: '1744',
    name: 'Acceso por camino de tierra',
    availableFor: [
      0,
    ],
  },
  {
    id: '1745',
    name: 'Barrio residencial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1746',
    name: 'Zona muy concurrida',
    availableFor: [
      0,
    ],
  },
  {
    id: '1747',
    name: 'Se requiere identificación del huésped',
    availableFor: [
      0,
    ],
  },
  {
    id: '1748',
    name: 'No se permiten tatuajes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1749',
    name: 'Zona peatonal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1750',
    name: 'Acceso por carretera sin pavimentar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1751',
    name: 'Barrio residencial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1752',
    name: 'Zona concurrida',
    availableFor: [
      0,
    ],
  },
  {
    id: '1753',
    name: 'Prácticas de limpieza mejoradas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1754',
    name: 'Limpieza por empresas de limpieza profesionales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1755',
    name: 'Desinfectantes utilizados para la limpieza',
    availableFor: [
      0,
    ],
  },
  {
    id: '1756',
    name: 'Productos químicos de limpieza que son eficaces contra el coronavirus utilizados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1757',
    name: 'Distanciamiento social siguiendo las pautas de las autoridades locales',
    availableFor: [
      0,
    ],
  },
  {
    id: '1758',
    name: 'Proceso establecido para verificar la salud de los huéspedes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1759',
    name: 'Mascarillas faciales disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1760',
    name: 'Desinfectante de manos disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1761',
    name: 'Pantallas o barreras físicas colocadas entre el personal y los huéspedes en áreas apropiadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1762',
    name: 'Está disponible el check-in y el check-out sin contacto.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1763',
    name: 'Check-in sin contacto disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1764',
    name: 'Pago sin contacto disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1765',
    name: 'El personal sigue todos los protocolos de seguridad de las autoridades locales.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1766',
    name: 'Ropa de cama, toallas y ropa lavada de acuerdo con las pautas de las autoridades locales.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1767',
    name: 'Ropa de cama y toallas lavadas al menos a 60°C/140°F',
    availableFor: [
      0,
    ],
  },
  {
    id: '1768',
    name: 'Platos, cubiertos, vasos y demás vajilla higienizados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1769',
    name: 'Superficies de alto contacto desinfectadas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1770',
    name: 'Alojamiento para huéspedes sellado después de la limpieza.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1771',
    name: 'Se puede evitar la limpieza del alojamiento de huéspedes si se solicita.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1772',
    name: 'El personal usa equipo de protección personal.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1773',
    name: 'Controles de temperatura al personal',
    availableFor: [
      0,
    ],
  },
  {
    id: '1774',
    name: 'La propiedad está vacante durante al menos 24 horas entre reservas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1775',
    name: 'La propiedad está vacante durante al menos 48 horas entre reservas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1776',
    name: 'La propiedad está vacante durante al menos 72 horas entre reservas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1777',
    name: 'Se requiere documentación adicional relacionada con COVID-19',
    availableFor: [
      0,
    ],
  },
  {
    id: '1778',
    name: 'No se permiten invitados de ciertos países',
    availableFor: [
      0,
    ],
  },
  {
    id: '1779',
    name: 'Guantes disponibles para los huéspedes.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1780',
    name: 'Papelería compartida, menús impresos, bolígrafos, papel eliminado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1781',
    name: 'Botiquín de primeros auxilios disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1782',
    name: 'Pago sin efectivo disponible',
    availableFor: [
      0,
    ],
  },
  {
    id: '1783',
    name: 'Propiedad disponible solo para viajeros esenciales por COVID-19',
    availableFor: [
      0,
    ],
  },
  {
    id: '1784',
    name: 'Horas de servicio reducidas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1785',
    name: 'Spa y gimnasio no disponibles',
    availableFor: [
      0,
    ],
  },
  {
    id: '1786',
    name: 'Servicios en sitio reducidos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1787',
    name: 'Servicio de transporte suspendido',
    availableFor: [
      0,
    ],
  },
  {
    id: '1788',
    name: 'Distanciamiento social en áreas de comedor',
    availableFor: [
      0,
    ],
  },
  {
    id: '1789',
    name: 'Opciones de comedor disponibles en la habitación.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1790',
    name: 'Servicios de alimentación reducidos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1791',
    name: 'Alimentos envueltos individualmente para el desayuno.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1792',
    name: 'Comida envuelta individualmente para el almuerzo.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1793',
    name: 'Comida envuelta individualmente para la cena.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1794',
    name: 'Comida envuelta individualmente en el servicio de habitaciones.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1795',
    name: 'Aplicación móvil para servicio de habitaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1796',
    name: 'Se siguió el protocolo de desinfección de Singapur SG Clean',
    availableFor: [
      0,
    ],
  },
  {
    id: '1797',
    name: 'Se siguió el protocolo de desinfección Safe & Clean de Malasia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1798',
    name: 'Se sigue el protocolo de desinfección CESCO en Corea del Sur',
    availableFor: [
      0,
    ],
  },
  {
    id: '1799',
    name: 'Se siguió el protocolo de desinfección de la Administración de Seguridad y Salud de Tailandia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1801',
    name: 'Se siguió el protocolo de desinfección Portugal Clean & Safe',
    availableFor: [
      0,
    ],
  },
  {
    id: '1802',
    name: 'Se sigue el protocolo de higienización del Ministerio de Industria, Comercio y Turismo de España',
    availableFor: [
      0,
    ],
  },
  {
    id: '1803',
    name: 'Spain Confederación Española de Hoteles y Alojamientos Turísticos sanitization protocol followed',
    availableFor: [
      0,
    ],
  },
  {
    id: '1804',
    name: 'Protocolo nacional de Italia Federalberghi Accoglienza Sicura (Hospitalidad Segura) protocolo de desinfección seguido',
    availableFor: [
      0,
    ],
  },
  {
    id: '1805',
    name: 'Se siguió el protocolo de sanitización para Turistas Protegidos de Brasil',
    availableFor: [
      0,
    ],
  },
  {
    id: '1807',
    name: 'La propiedad sigue las prácticas de desinfección del Protocolo Sanitario (UNPLV - Francia)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1809',
    name: 'La propiedad sigue las prácticas de desinfección de SafeStay (AHLA - EE. UU.)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1810',
    name: 'La propiedad sigue las pautas para reabrir los alquileres vacacionales (DTV y DFV - Alemania)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1811',
    name: 'La propiedad sigue las prácticas de desinfección de la Asociación Europea de Casas de Vacaciones (EHHA - Europa)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1812',
    name: 'La propiedad sigue las prácticas de desinfección de Intertek Cristal (experto externo - Global)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1813',
    name: 'La propiedad sigue las prácticas de desinfección de Safe Travels (WTTC - Global)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1814',
    name: 'La propiedad sigue las prácticas de desinfección de la Asociación Croata de Turismo (HUT - Croacia)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1815',
    name: 'La propiedad sigue las prácticas de desinfección de SafeHome (VRMA y VRHP)',
    availableFor: [
      0,
    ],
  },
  {
    id: '1817',
    name: 'Check-in en persona',
    availableFor: [
      0,
    ],
  },
  {
    id: '1818',
    name: 'Registro de cajas de seguridad y cajas fuertes para llaves',
    availableFor: [
      0,
    ],
  },
  {
    id: '1819',
    name: 'Registro de entrada mediante teclado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1820',
    name: 'Los huéspedes deben comunicarse con nosotros para obtener instrucciones.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1821',
    name: 'Lavadora - espacio común',
    availableFor: [
      0,
    ],
  },
  {
    id: '1822',
    name: 'secadora - espacio común',
    availableFor: [
      0,
    ],
  },
  {
    id: '1823',
    name: 'Cuna de viaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '1825',
    name: 'futón doble',
    availableFor: [
      0,
    ],
  },
  {
    id: '1826',
    name: 'Airbnb: todos pueden hacer reservas instantáneas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1827',
    name: 'Airbnb: Los huéspedes con buenas reseñas pueden hacer reservas instantáneas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1828',
    name: 'Airbnb: Los huéspedes con identidad verificada pueden hacer reservas instantáneas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1829',
    name: 'Airbnb: Solo los huéspedes con buenas evaluaciones y una identidad verificada pueden hacer reservas instantáneas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1830',
    name: 'Escaleras',
    availableFor: [
      0,
    ],
  },
  {
    id: '1831',
    name: 'Ruido potencial',
    availableFor: [
      0,
    ],
  },
  {
    id: '1832',
    name: 'Se admiten mascotas en la propiedad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1833',
    name: 'Estacionamiento limitado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1834',
    name: 'Espacios compartidos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1835',
    name: 'Servicios limitados',
    availableFor: [
      0,
    ],
  },
  {
    id: '1836',
    name: 'Armas en la propiedad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1837',
    name: 'Animales peligrosos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1838',
    name: 'Acondicionador',
    availableFor: [
      0,
    ],
  },
  {
    id: '1839',
    name: 'Lluvia',
    availableFor: [
      0,
    ],
  },
  {
    id: '1840',
    name: 'Gel de ducha',
    availableFor: [
      0,
    ],
  },
  {
    id: '1841',
    name: 'cualquiera de los siguientes: armario, guardarropas, armario independiente',
    availableFor: [
      0,
    ],
  },
  {
    id: '1842',
    name: 'Equipo de ejercicio',
    availableFor: [
      0,
    ],
  },
  {
    id: '1843',
    name: 'Tocadiscos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1844',
    name: 'Bandeja para hornear',
    availableFor: [
      0,
    ],
  },
  {
    id: '1845',
    name: 'Máquina para hacer pan',
    availableFor: [
      0,
    ],
  },
  {
    id: '1846',
    name: 'Café',
    availableFor: [
      0,
    ],
  },
  {
    id: '1847',
    name: 'Máquina para hacer arroz',
    availableFor: [
      0,
    ],
  },
  {
    id: '1848',
    name: 'Compactador de basura',
    availableFor: [
      0,
    ],
  },
  {
    id: '1849',
    name: 'Lavandería cercana',
    availableFor: [
      0,
    ],
  },
  {
    id: '1850',
    name: 'Acceso al complejo turístico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1851',
    name: 'Amarre para embarcaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1852',
    name: 'Hamaca',
    availableFor: [
      0,
    ],
  },
  {
    id: '1853',
    name: 'Asientos al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1854',
    name: 'Cocina al aire libre',
    availableFor: [
      0,
    ],
  },
  {
    id: '1855',
    name: 'Estacionamiento de pago en las instalaciones',
    availableFor: [
      0,
    ],
  },
  {
    id: '1856',
    name: 'Estancias de larga duración',
    availableFor: [
      0,
    ],
  },
  {
    id: '1857',
    name: 'Baño compartido con otros huéspedes.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1858',
    name: 'Baño compartido con el anfitrión.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1859',
    name: 'Baño compartido con familia, amigos, compañeros de piso.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1860',
    name: 'Espacios comunes compartidos con otros huéspedes',
    availableFor: [
      0,
    ],
  },
  {
    id: '1861',
    name: 'Espacios comunes compartidos con el anfitrión',
    availableFor: [
      0,
    ],
  },
  {
    id: '1862',
    name: 'Espacios comunes compartidos con familiares, amigos, compañeros de piso.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1863',
    name: 'Cámaras de vigilancia en la propiedad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1864',
    name: 'Equipo de fitness',
    availableFor: [
      0,
    ],
  },
  {
    id: '1865',
    name: 'Colchón de aire',
    availableFor: [
      0,
    ],
  },
  {
    id: '1866',
    name: 'Colchón de suelo',
    availableFor: [
      0,
    ],
  },
  {
    id: '1867',
    name: 'Barbacoa de gas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1868',
    name: 'Carbón para barbacoa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1869',
    name: 'Ubicación frente al mar',
    availableFor: [
      0,
    ],
  },
  {
    id: '1870',
    name: 'Tarifa de chef',
    availableFor: [
      0,
    ],
  },
  {
    id: '1871',
    name: 'Chef a petición',
    availableFor: [
      0,
    ],
  },
  {
    id: '1873',
    name: 'Se permite un máximo de 1 mascota',
    availableFor: [
      0,
    ],
  },
  {
    id: '1874',
    name: 'Se permiten máximo 2 mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1875',
    name: 'Se permiten máximo 3 mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1876',
    name: 'Se permiten máximo 4 mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1877',
    name: 'Se permiten máximo 5 mascotas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1878',
    name: 'Tarea de pago: Devolver las llaves al finalizar la compra',
    availableFor: [
      0,
    ],
  },
  {
    id: '1879',
    name: 'Tarea de salida: apagar luces/electrodomésticos, etc. al salir',
    availableFor: [
      0,
    ],
  },
  {
    id: '1880',
    name: 'Tarea de pago: Retirar la basura antes de salir',
    availableFor: [
      0,
    ],
  },
  {
    id: '1881',
    name: 'Tarea de pago: cerrar las puertas al salir',
    availableFor: [
      0,
    ],
  },
  {
    id: '1882',
    name: 'Tarea de pago: Mantener las toallas en un lugar específico',
    availableFor: [
      0,
    ],
  },
  {
    id: '1883',
    name: 'Tarea de pago: Apagar las luces y el aire acondicionado',
    availableFor: [
      0,
    ],
  },
  {
    id: '1884',
    name: 'Tarea de verificación: Apagar ventiladores y dispositivos electrónicos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1885',
    name: 'Tarea de pago: apagar las luces y cerrar las ventanas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1886',
    name: 'Tarea de pago: cerrar ventanas y puertas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1887',
    name: 'Tarea de pago: cerrar con llave y devolver la llave',
    availableFor: [
      0,
    ],
  },
  {
    id: '1888',
    name: 'Tarea de pago: cerrar la puerta y el balcón y devolver la llave',
    availableFor: [
      0,
    ],
  },
  {
    id: '1889',
    name: 'Tarea de pago: Reúna toallas y ropa de cama en una bolsa de ropa.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1890',
    name: 'Tarea de salida: Dejar las toallas en el baño.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1891',
    name: 'Tarea de pago: Reunir toallas y sábanas.',
    availableFor: [
      0,
    ],
  },
  {
    id: '1892',
    name: 'Tarea de pago: lavar platos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1893',
    name: 'Tarea de pago: retirar alimentos del refrigerador',
    availableFor: [
      0,
    ],
  },
  {
    id: '1894',
    name: 'Tarea de pago: vaciar la basura y poner en marcha el lavavajillas',
    availableFor: [
      0,
    ],
  },
  {
    id: '1895',
    name: 'Tarea de pago: La propiedad sucia generará un cargo por limpieza',
    availableFor: [
      0,
    ],
  },
  {
    id: '1897',
    name: 'Tarea de pago: Desechar la basura en contenedores o basureros',
    availableFor: [
      0,
    ],
  },
  {
    id: '1898',
    name: 'Tarea de pago: Instrucciones de reciclaje',
    availableFor: [
      0,
    ],
  },
  {
    id: '1899',
    name: 'Tarea de pago: Conductos de basura en pisos específicos',
    availableFor: [
      0,
    ],
  },
  {
    id: '1900',
    name: 'Tarea de verificación: Contenedores de basura fuera de la propiedad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1901',
    name: 'Tarea de verificación: Métodos de eliminación en el manual de la casa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1902',
    name: 'Tarea de pago: dejar las llaves sobre la mesa',
    availableFor: [
      0,
    ],
  },
  {
    id: '1904',
    name: 'Tarea de pago: dejar las llaves en la caja de seguridad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1905',
    name: 'Tarea de pago: dejar las llaves en la caja fuerte',
    availableFor: [
      0,
    ],
  },
  {
    id: '1906',
    name: 'Tarea de pago: dejar las llaves en la cocina',
    availableFor: [
      0,
    ],
  },
  {
    id: '1907',
    name: 'Tarea de pago: Dejar las llaves con el personal o con el personal de seguridad',
    availableFor: [
      0,
    ],
  },
  {
    id: '1908',
    name: 'Solo animales de servicio',
    availableFor: [
      0,
    ],
  },
];
