import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import InfiniteScroll from 'react-infinite-scroller';
import { HubContext } from '../context';
import MessageItem from './message';
import MessageSender from './messageSender';
import MessageHeader from './message-header';
import { isSameDay } from '../utils';
import HubLoader from '../hub-loader';
import { MESSAGES_PER_PAGE } from '../constants';

const MessageList = () => {
  const {
    messages, loadingMessages,
    loadingSelectedChannel, filterMessages,
    setFilterMessages, selectedChannelId,
  } = useContext( HubContext );

  const listRef = useRef( null );

  useEffect( () => {
    if ( messages.data.length <= MESSAGES_PER_PAGE ) {
      listRef.current.scrollTop = listRef.current.scrollHeight;
    }
  }, [messages] );

  const [showList, setShowList] = useState( true );

  useEffect( () => {
    setShowList( false );
    const timer = setTimeout( () => {
      setShowList( true );
    }, 200 );
    return () => clearTimeout( timer );
  }, [selectedChannelId] );

  return (
    <div className="hub-message-list">
      <MessageHeader />
      <div className="hub-message-list-body" ref={listRef}>
        <HubLoader loading={loadingMessages || loadingSelectedChannel} key="loading" />
        {showList ? (
          <InfiniteScroll
            pageStart={1}
            initialLoad={false}
            loadMore={( page ) => {
              setFilterMessages( { page } );
            }}
            hasMore={messages.totalPages
            > filterMessages.page}
            useWindow={false}
            isReverse
          >
            {messages.data.map( ( messageData, index ) => {
              const messageDataPrevious = index > 0 ? messages.data[index - 1] : null;

              return (
                <div key={messageData.id}>
                  {!isSameDay( messageData, messageDataPrevious ) ? (
                    <div className="hub-message-list-body-date">{moment.utc( new Date( messageData.createdAt ) ).local().format( 'MMM DD, YYYY' )}</div>
                  ) : null}
                  <MessageItem
                    messageData={messageData}
                  />
                </div>
              );
            } )}
          </InfiniteScroll>
        ) : null}
      </div>
      <footer className="hub-message-list-footer">
        <MessageSender />
      </footer>
    </div>
  );
};

export default MessageList;
