import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';

import SettingsActions from 'store/reducers/Settings';
import NukiService from 'api/Nuki/Nuki';
import KeysService from 'api/Keys';
import OpenLockerModalView from './view';

const OpenLockerModal = React.memo( ( {
  data, doorType, lockerId, modalOpened, propertyManager,
  onCloseModal, toggleLoading, toggleInfoAlert, toggleErrorAlert,
} ) => {
  const [lockerStatus, setLockerStatus] = useState( [] );

  const openDoor = useCallback( () => {
    toggleLoading( true );
    if ( doorType === 'booking' ) {
      const dataToSend = {
        bookedAccommodation: data.id,
        smartLockerid: lockerId,
      };
      KeysService.openBookingLocker( dataToSend ).then( ( response ) => {
        toggleLoading( false );
        onCloseModal();
        if ( ( response.data || response.data['open door'] ) && response.ok ) {
          toggleInfoAlert( 'doorOpened' );
        } else {
          toggleErrorAlert( `Internal api error (${response.data.message})` );
        }
      } );
      return;
    }
    const dataToSend = {
      taskId: data.id,
      smartLockerid: lockerId,
    };
    KeysService.openTaskLocker( dataToSend ).then( ( response ) => {
      toggleLoading( false );
      onCloseModal();
      if ( ( response.data || response.data['open door'] ) && response.ok ) {
        toggleInfoAlert( 'doorOpened' );
      } else {
        toggleErrorAlert( 'Internal api error' );
      }
    } );
  }, [data, doorType, lockerId,
    onCloseModal, toggleLoading, toggleInfoAlert, toggleErrorAlert] );

  const lockDoor = useCallback( () => {
    const dataToSend = {
      propertyManager: propertyManager.id,
      booking: data.booking.id,
      bookedAccommodation: data.id,
      locker: doorType,
      lockerId,
      action: 2,
    };

    toggleLoading( true );
    NukiService.openDoor( dataToSend ).then( ( response ) => {
      toggleLoading( false );
      onCloseModal();

      if ( response.data && response.data.status === 'ko' ) return toggleErrorAlert( 'errorOpenDoor' );
      if ( !response.ok ) return toggleErrorAlert( response.errors );
      setLockerStatus( 1 );
      toggleInfoAlert( 'doorLocked' );
    } );
  }, [data, doorType, lockerId, propertyManager,
    onCloseModal, toggleLoading, toggleInfoAlert, toggleErrorAlert] );

  // const getLockerStatus = useCallback( () => {
  //   NukiService.getStatus( lockerId, propertyManager ).then( ( response ) => {
  //     setLockerStatus( response.data.status );
  //   } );
  // }, [propertyManager, lockerId] );

  // useEffect( () => {
  //   if ( lockerId ) getLockerStatus( lockerId );
  // }, [getLockerStatus, lockerId] );
  return (
    <OpenLockerModalView
      type={doorType}
      open={modalOpened}
      lockerStatus={lockerStatus}
      onConfirm={openDoor}
      onOpenDoor={openDoor}
      onLockDoor={lockDoor}
      onClose={onCloseModal}
    />
  );
} );

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = ( {
  toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  toggleLoading: SettingsActions.toggleLoading,
} );

export default connect( mapStateToProps, mapDispatchToProps )( OpenLockerModal );
