import {
  useState, useCallback, useMemo,
} from 'react';
import { useFetch, useFilters } from 'hooks';
import MessageService from 'api/Hub/Message';
import { handlePromise } from 'utils';
import { MESSAGES_PER_PAGE } from '../constants';

const useMessages = (
  participantId,
  selectedChannelId,
  selectedChannel,
  selectedSourceId,
  toggleErrorAlert,
) => {
  //  MESSAGE LIST
  const [messages, setMessages] = useState( {
    totalPages: 0,
    pagesLoaded: {},
    data: [],
  } );
  const [filterMessages, setFilterMessages] = useFilters( {
    page: 1,
    limit: MESSAGES_PER_PAGE,
    'orderBy[createdAt]': 'asc',
  } );
  const [reloadMessagesFlag, setReloadMessagesFlag] = useState( 0 );
  const [, loadingMessages] = useFetch( {
    initialState: { data: [], total: 0, elementsPerPage: 20 },
    promise: () => {
      const filterChannelSelected = selectedChannelId ? { 'filters[channelId]': selectedChannelId ?? '' } : {};
      const filterSourceSelected = selectedSourceId ? { 'filters[sourceId]': selectedSourceId ?? '' } : {};
      return MessageService.getMessages( {
        ...filterMessages,
        ...filterChannelSelected,
        ...filterSourceSelected,
      } );
    },
    toggleErrorAlert,
    conditional: !!selectedChannelId,
    deps: [selectedChannelId, selectedSourceId, reloadMessagesFlag, filterMessages],
    reInit: true,
    callback: ( sc, ss, r, { page }, newMessageListData ) => {
      setMessages( ( oldMessageListData ) => {
        const totalPages = Math.ceil( newMessageListData.total / MESSAGES_PER_PAGE );

        const newMessages = { ...oldMessageListData, totalPages };

        newMessages.pagesLoaded[page || 1] = [...newMessageListData.data];

        newMessages.data = Object.entries( newMessages.pagesLoaded )
          .sort( ( a, b ) => a[0] - b[0] ).reduce( ( list, [, value] ) => {
            list.push( ...value );
            return list;
          }, [] );

        return newMessages;
      } );
    },
  } );
  //  MESSAGE SENDER
  const [loadingMessage, setLoadingMessage] = useState( false );
  const submitMessage = useCallback(
    async ( content ) => {
      setLoadingMessage( true );
      const [error, response] = await handlePromise( MessageService.createMessage( {
        participantId,
        channelId: selectedChannelId,
        content,
        sourceId: '9e4e67be-a271-45a0-8501-84290a101b33',
      } ) );
      if ( !response.ok ) {
        toggleErrorAlert( error );
      } else {
        setReloadMessagesFlag( new Date().valueOf() );
      }
      setLoadingMessage( false );
    },
    [toggleErrorAlert, participantId, selectedChannelId],
  );
  const enabledSubmitMessage = useMemo( () => {
    if ( !selectedChannel ) {
      return false;
    }
    return selectedChannel.participants
      .filter( ( item ) => item.id === participantId ).length > 0;
  }, [selectedChannel, participantId] );

  const resetMessages = useCallback(
    ( ) => {
      setMessages( {
        totalPages: 0,
        pagesLoaded: {},
        data: [],
      } );
      setFilterMessages( { page: 1 } );
    },
    [setFilterMessages],
  );

  return [
    messages,
    resetMessages,
    loadingMessages,
    filterMessages,
    setFilterMessages,
    enabledSubmitMessage,
    submitMessage,
    loadingMessage,
  ];
};

export default useMessages;
