import { useState, useCallback } from 'react';

const useFilters = ( initialFilters ) => {
  const [filters, setFilterProps] = useState( initialFilters ?? { page: 1, limit: 10 } );

  const setFilter = useCallback( ( newFilterProps ) => {
    setFilterProps( ( oldFilters ) => Object.entries( {
      ...oldFilters,
      ...newFilterProps,
    } ).reduce( ( obj, [key, value] ) => {
      if ( typeof value === 'undefined' ) {
        return obj;
      }
      // eslint-disable-next-line no-param-reassign
      obj[key] = value;
      return obj;
    }, {} ) );
  }, [] );

  return [filters, setFilter];
};

export default useFilters;
