import Resource from 'containers/Resource';
import ChangePassword from 'containers/ChangePassword';
import UserProfile from 'containers/UserProfile';
import Chat from 'containers/SupportOld/Chat';
import Property from 'containers/Property';
import Accommodation from 'containers/Accommodation';
import Booking from 'containers/Booking';
import Team from 'containers/Team';
import Task from 'containers/Task';
import Shift from 'containers/Shift';
import TaskType from 'containers/TaskType';
import Role from 'containers/Role';
import Amenity from 'containers/Amenity';
import Map from 'containers/Map';
import Dashboard from 'containers/Dashboard';
import Marketplace from 'containers/PropertyManager/Edit/Marketplace';
import Account from 'containers/PropertyManager/Edit/General';
import Finance from 'containers/PropertyManager/Edit/Finance';
import City from 'containers/PropertyManager/Edit/City/Dashboard';
import DashboardCalendar from 'views/DashboardCalendar';
// import CustomFieldsRoutes from './sections/customFields';

export default {
  headerLeft: [
    {
      name: 'operations',
      path: 'tasks',
      layout: '/operations/',
      icon: 'app-icon-operation',
      state: 'operations',
      ACLs: ['task:manage', 'task:lists', 'shift:manage', 'shift:lists'],
      views: [
        {
          name: 'tasks',
          icon: 'app-icon-task',
          state: 'operationsTasks',
          ACLs: ['task:manage', 'task:lists'],
          views: [
            {
              name: 'dashboardCalendar',
              path: 'dashboardCalendar',
              layout: '/operations/',
              component: DashboardCalendar,
              ACLs: ['task:manage', 'task:lists'],
            },
            {
              name: 'taskList',
              path: 'tasks',
              layout: '/operations/',
              component: Task,
              ACLs: ['task:manage', 'task:lists'],
            },
            {
              name: 'periodicTasks',
              path: 'periodic-tasks',
              layout: '/operations/',
              component: Dashboard,
              ACLs: ['task:manage', 'task:lists'],
            },
          ],
        },
        {
          name: 'accessRequest',
          path: 'access-request',
          layout: '/operations/',
          icon: 'app-icon-request',
          component: Dashboard,
        },
        {
          name: 'geolocation',
          path: 'geolocation',
          icon: 'app-icon-marker',
          component: Map,
          layout: '/operations/',
          ACLs: ['shift:manage', 'shift:lists'],
        },
      ],
    },
    {
      name: 'bookings',
      path: 'list',
      layout: '/bookings/',
      icon: 'app-icon-bookings',
      state: 'bookings',
      ACLs: ['booking:manage', 'booking:lists'],
      views: [
        {
          name: 'bookings',
          path: 'list',
          layout: '/bookings/',
          icon: 'app-icon-calendar',
          component: Booking,
          ACLs: ['booking:manage', 'booking:lists'],
        },
        {
          name: 'reports',
          icon: 'app-icon-dashboards',
          state: 'bookingsReports',
          views: [
            {
              name: 'occupancy',
              path: 'occupancy',
              layout: '/bookings/',
              component: Dashboard,
            },
            {
              name: 'pending',
              path: 'pending',
              layout: '/bookings/',
              component: Dashboard,
            },
          ],
        },
      ],
    },
    {
      name: 'pms',
      path: 'properties',
      layout: '/pms/',
      icon: 'app-icon-pms',
      state: 'pms',
      ACLs: [
        'property:manage',
        'property:lists',
        'shift:manage',
        'shift:lists',
      ],
      views: [
        {
          name: 'properties',
          path: 'properties',
          layout: '/pms/',
          icon: 'app-icon-properties',
          component: Property,
          ACLs: ['property:manage', 'property:lists'],
        },
        {
          name: 'accommodations',
          path: 'accommodations',
          layout: '/pms/',
          icon: 'app-icon-accommodation',
          component: Accommodation,
          ACLs: ['accommodation:manage', 'accommodation:lists'],
        },
        {
          name: 'areas',
          path: 'areas',
          layout: '/pms/',
          icon: 'app-icon-areas',
          component: Dashboard,
        },
      ],
    },
    {
      name: 'resources',
      layout: '/resources/',
      path: 'resources',
      icon: 'app-icon-resources',
      state: 'resources',
      ACLs: [
        'resource:manage',
        'resource:lists',
        'team:manage',
        'team:lists',
        'shift:manage',
        'shift:lists',
      ],
      views: [
        {
          name: 'resources',
          path: 'resources',
          layout: '/resources/',
          icon: 'app-icon-resource text-sm',
          component: Resource,
          ACLs: ['resource:manage', 'resource:lists'],
        },
        {
          name: 'teams',
          path: 'teams',
          layout: '/resources/',
          icon: 'app-icon-team text-sm',
          component: Team,
          ACLs: ['team:manage', 'team:lists'],
        },
        {
          name: 'shifts',
          path: 'shifts',
          layout: '/resources/',
          icon: 'app-icon-shift',
          component: Shift,
          ACLs: ['shift:manage', 'shift:lists'],
        },
        {
          name: 'providers',
          path: 'providers',
          layout: '/resources/',
          icon: 'app-icon-providers',
          component: Dashboard,
        },
      ],
    },
    {
      name: 'reports',
      path: 'expenses-internal',
      layout: '/reports/',
      icon: 'app-icon-analysis',
      state: 'reports',
      views: [
        {
          name: 'expenses',
          icon: 'app-icon-expenses',
          state: 'reportsExpenses',
          views: [
            {
              name: 'internal',
              path: 'expenses-internal',
              layout: '/reports/',
              component: Dashboard,
            },
            {
              name: 'forOwners',
              path: 'expenses-owners',
              layout: '/reports/',
              component: Dashboard,
            },
          ],
        },
        {
          name: 'profitsBreakdown',
          icon: 'app-icon-profit-breakdown',
          state: 'reportsProfits',
          views: [
            {
              name: 'internal',
              path: 'profits-internal',
              layout: '/reports/',
              component: Dashboard,
            },
            {
              name: 'forOwners',
              path: 'profits-owners',
              layout: '/reports/',
              component: Dashboard,
            },
          ],
        },
        {
          name: 'appStatistics',
          path: 'app-statistics',
          layout: '/reports/',
          icon: 'app-icon-phone-app',
          component: Dashboard,
        },
      ],
    },
  ],
  headerRight: [
    {
      path: 'support',
      layout: '/',
      exact: true,
      icon: 'app-icon-support',
      component: Chat,
      ACLs: ['channel:manage', 'channel:lists'],
    },
    {
      path: '',
      forNotifications: true,
    },
    {
      path: 'roles',
      layout: '/settings/',
      icon: 'app-icon-settings',
      state: 'settings',
      ACLs: [
        'amenity:manage',
        'amenity:lists',
        'task_type:manage',
        'task_type:lists',
        'roles:manage',
        'roles:lists',
        'property_manager:manage',
        'property_manager:view',
        'settings_currency:view',
        'settings_city:lists',
      ],
      views: [
        {
          name: 'roles',
          path: 'roles',
          layout: '/settings/',
          icon: 'app-icon-roles',
          component: Role,
          ACLs: ['roles:manage', 'roles:lists'],
        },
        {
          name: 'tasks',
          icon: 'app-icon-task',
          state: 'settingsTasks',
          ACLs: ['task_type:manage', 'task_type:lists'],
          views: [
            {
              name: 'categories',
              path: 'categories',
              component: TaskType,
              layout: '/settings/task/',
              ACLs: ['task_type:manage', 'task_type:lists'],
            },
            {
              name: 'autoasignationRules',
              path: 'autoasignation-rules',
              component: Dashboard,
              layout: '/settings/task/',
            },
            {
              name: 'autogenerationRules',
              path: 'autogeneration-rules',
              component: Dashboard,
              layout: '/settings/task/',
            },
          ],
        },
        // {
        //   name: 'customFields',
        //   icon: 'app-icon-custom-fields',
        //   state: 'settingCustomFields',
        //   ACLs: [
        //     'settings_custom_fields:manage',
        //     'settings_custom_fields:lists',
        //   ],
        //   views: CustomFieldsRoutes,
        // },
        {
          name: 'amenities',
          path: 'amenities',
          layout: '/settings/',
          icon: 'app-icon-appliance',
          component: Amenity,
          ACLs: ['amenity:manage', 'amenity:lists'],
        },
        {
          name: 'expensesConcepts',
          path: 'expenses-concepts',
          layout: '/settings/',
          icon: 'app-icon-expenses',
          component: Dashboard,
        },
        {
          name: 'account',
          path: 'account',
          layout: '/settings/',
          icon: 'app-icon-settings',
          component: Account,
          ACLs: ['property_manager:manage', 'property_manager:view'],
        },
        {
          name: 'marketplace',
          path: 'marketplace',
          layout: '/settings/',
          icon: 'app-icon-puzzle',
          component: Marketplace,
          ACLs: ['plugin:manage', 'plugin:lists'],
        },
        {
          name: 'finance',
          path: '/finance',
          layout: '/settings/',
          icon: 'app-icon-euro',
          component: Finance,
          ACLs: ['settings_currency:view'],
        },
        {
          name: 'cities',
          path: '/cities',
          layout: '/settings/',
          icon: 'app-icon-list',
          component: City,
          ACLs: ['settings_city:lists'],
        },
        {
          name: 'communications',
          icon: 'app-icon-email-template',
          state: 'settingsCommunications',
          views: [
            {
              name: 'templates',
              path: 'templates',
              layout: '/settings/communications/',
              component: Dashboard,
            },
            {
              name: 'predefinedNotifications',
              path: 'predefined-notifications',
              component: Dashboard,
              layout: '/settings/communications/',
            },
            {
              name: 'emailSettings',
              path: 'email-settings',
              component: Dashboard,
              layout: '/settings/communications/',
            },
          ],
        },
      ],
    },
  ],
  userMenu: [
    {
      name: 'myProfile',
      path: 'profile',
      icon: 'ni ni-single-02',
      component: UserProfile,
      layout: '/',
    },
    {
      name: 'changePassword',
      path: 'change-password',
      icon: 'fa fa-key',
      component: ChangePassword,
      layout: '/',
    },
  ],
};
