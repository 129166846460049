import React, {
  createContext, useState, useCallback,
} from 'react';
import { connect } from 'react-redux';
import SettingsActions from 'store/reducers/Settings';
import _ from 'lodash';
//
import useSources from './useSources';
import useChannels from './useChannels';
import useMessages from './useMessages';

export const HubContext = createContext( {} );

const HubContextProvider = ( { toggleErrorAlert, /* propertyManager, */ children } ) => {
  //  PARTICIPANT: HARDCODED NOW
  const [participantId] = useState( '0125585e-1598-4680-b765-5ae5ac6ca79c' );

  //  SOURCES
  const [
    sources,
    loadingSources,
    selectedSourceId,
    setSelectedSourceId,
  ] = useSources( participantId, toggleErrorAlert );

  //  CHANNELS
  const [
    selectedChannelId,
    setSelectedChannelId,
    selectedChannel,
    loadingSelectedChannel,
    channels,
    filterChannels,
    setFilterChannels,
    loadingChannels,
  ] = useChannels( toggleErrorAlert );

  //  MESSAGES
  const [
    messages,
    resetMessages,
    loadingMessages,
    filterMessages,
    setFilterMessages,
    enabledSubmitMessage,
    submitMessage,
    loadingMessage,
  ] = useMessages(
    participantId,
    selectedChannelId,
    selectedChannel,
    selectedSourceId,
    toggleErrorAlert,
  );

  //  SELECT CHANNEL
  const selectChannel = useCallback(
    ( channelId ) => {
      resetMessages();
      setSelectedSourceId( null );
      setSelectedChannelId( channelId );
    },
    [resetMessages, setSelectedSourceId, setSelectedChannelId],
  );

  //  SELECT SOURCE
  const selectSource = useCallback(
    ( sourceId ) => {
      resetMessages();
      setSelectedSourceId( sourceId );
    },
    [resetMessages, setSelectedSourceId],
  );

  return (
    <HubContext.Provider
      value={{
        // PARTICIPANT
        participantId,

        // SOURCES
        sources,
        loadingSources,
        selectedSourceId,
        setSelectedSourceId,

        // SELECTED CHANNEL
        selectedChannelId,
        selectedChannel,
        loadingSelectedChannel,

        // CHANNELS
        channels,
        loadingChannels,
        filterChannels,
        setFilterChannels,

        // MESSAGES
        messages,
        loadingMessages,
        filterMessages,
        setFilterMessages,

        // MESSAGE SENDER
        submitMessage,
        loadingMessage,
        enabledSubmitMessage,

        // SELECT CHANNELS
        selectChannel,

        // SELECT SOURCE
        selectSource,
      }}
    >
      {children}
    </HubContext.Provider>
  );
};

const mapStateToProps = ( { user } ) => ( {
  propertyManager: _.get( user, 'user.propertyManager', {} ),
} );

const mapDispatchToProps = {
  // toggleInfoAlert: SettingsActions.toggleInfoAlert,
  toggleErrorAlert: SettingsActions.toggleErrorAlert,
  // toggleLoading: SettingsActions.toggleLoading,
};

export default connect( mapStateToProps, mapDispatchToProps )( HubContextProvider );
